import React from 'react';
import styled from 'styled-components/macro';

const TitleComponent = React.memo(({ id, name, color, ...props }) => {
    return (
        <MainWrapper color={color}>
            {name}
        </MainWrapper>
    );
});

export default TitleComponent;

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;
