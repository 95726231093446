import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { toggleSpinner } from './reducers/generalSetup';

export const getRequest = async (args) => {
    const { url, token, actionName, dispatch, additionalParams, showSpinner = true } = args;
    try {
        const headers = { 'x-token': token };
        showSpinner && dispatch(toggleSpinner(true));
        const response = await axios.get(url, { headers });
        showSpinner && dispatch(toggleSpinner(false));
        if (actionName) {
            dispatch(actionName({ data: await response.data, additionalParams }));
        } else {
            // console.log('>>>>>>', await response);
        }
    } catch (error) {
        console.log('>>>>>>', error);
        dispatch(toggleSpinner(false));
    }
};

export const postRequest = async (args) => {
    const { url, token, actionName, formData, dispatch, additionalParams = null, responseType = 'json', showSpinner = true } = args;
    let result = null;
    try {
        const headers = { 'x-token': token };
        showSpinner && dispatch(toggleSpinner(true));
        const response = await axios({ method: 'POST', url, responseType, data: formData, headers });
        showSpinner && dispatch(toggleSpinner(false));

        if (actionName) {
            if (responseType === 'blob') {
                const blobUrl = URL.createObjectURL(response.data);
                dispatch(actionName({ blobUrl, additionalParams, formData }));
            } else {
                dispatch(actionName({ data: response.data, additionalParams }));
            }
        } else {
            // console.log('>>>>>>', await response);
        }
    } catch (error) {
        // console.log('>>>>>>', error);
        dispatch(toggleSpinner(false));
        result = error;
    }
    return result;
};

export const postFetchRequest = async (args) => {
    const { url, token, actionName, formData, dispatch, additionalParams = null, responseType = 'json', showSpinner = true } = args;
    let result = null;
    try {
        const headers = { 'x-token': token, 'Content-Type': 'application/json' };
        showSpinner && dispatch(toggleSpinner(true));
        const response = await fetch(url, { method: 'POST', body: JSON.stringify(formData), headers });
        showSpinner && dispatch(toggleSpinner(false));
        const data = await response.json();

        if (actionName) {
            if (responseType === 'blob') {
                const blobUrl = URL.createObjectURL(response.data);
                dispatch(actionName({ blobUrl, additionalParams, formData }));
            } else {
                // dispatch(actionName({ data: response.data, additionalParams }));
            }
        } else {
            // console.log('>>>>>>', await response);
        }
    } catch (error) {
        console.log('>>>>>>', error);
        dispatch(toggleSpinner(false));
        result = error;
    }
    return result;
};

export const doubleRequest = async (args) => {
    const { url, url2, token, actionName, data, dispatch, additionalParams = null, responseType = 'json' } = args;
    try {
        const headers = { 'x-token': token };
        dispatch(toggleSpinner(true));
        await axios({ method: 'POST', url, responseType, data, headers })
            .then((response) => response.data)
            .then((data) => {
                dispatch(toggleSpinner(false));
                // console.log(JSON.stringify(data));
                postRequest({ url: url2, token, actionName, formData: data, dispatch, additionalParams, responseType: 'blob' });
            });
    } catch (error) {
        dispatch(toggleSpinner(false));
        // console.log('>>>>>>', error);
    }
};
