import React, { useState } from 'react';
import styled from 'styled-components/macro';

const BigCameraImageModal = React.memo(({ modalData, ...props }) => {
    return (
        <MainWrapper>
            <img src={modalData.options.selectedCamera.image_profile.corrected_image} alt={'big cam img'}></img>
        </MainWrapper>
    );
});

export default BigCameraImageModal;

const MainWrapper = styled.div`
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
        object-fit: contain;
    }
`;
