import React, { useState } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { useConstCallback } from '@uifabric/react-hooks';
import cloneDeep from 'lodash/cloneDeep';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const ShowCorrectedRawImageModal = React.memo(({ modalData, ...props }) => {
    const { t } = useTranslation();
    const [choice, set_choice] = useState('A');

    const options = [
        { key: 'A', text: t('Correct distortion') },
        { key: 'B', text: t("Don't correct distortion") },
    ];
    const onOKClick = (camera) => () => {
        const { blobUrl } = modalData.options.correctedRawImage;
        let nextStep = '';
        if (choice === 'A') {
            nextStep = 'dist_lines';
        } else if (choice === 'B') {
            nextStep = 'vert_lines';
        }

        props._onOKClick({ camera, blobUrl, nextStep });
    };

    const _onChange = (ev, option) => {
        set_choice(() => option.key);
    };

    return (
        <MainWrapper>
            <InputsContainer>
                <StChoiceGroup defaultSelectedKey="A" options={options} onChange={_onChange} required={true} />
            </InputsContainer>
            <ImgWrapper>
                <img src={modalData.options.correctedRawImage.blobUrl} alt="" />
            </ImgWrapper>

            <Footer>
                <PrimaryButton
                    text={t('Correct the image')}
                    disabled={false}
                    onClick={onOKClick(modalData.options.correctedRawImage.camera)}
                />
            </Footer>
        </MainWrapper>
    );
});

export default ShowCorrectedRawImageModal;

const InputsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const ImgWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content:center;
    align-items: center;
    /* background-color: red; */
    img {
        max-width: 100%;
        height: auto;
    }
`;

const MainWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
        object-fit: contain;
    }
`;

const Footer = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const StTextField = styled(TextField)`
    min-width: 300px;
`;
const StChoiceGroup = styled(ChoiceGroup)`
    .ms-ChoiceFieldGroup-flexContainer {
        display: flex;
        align-items: center;
        label {
            display: flex;
            align-items: center;
            margin-right: 10px;
        }
    }
`;
