import React, { useState, useMemo, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { CalibrationContext } from '../calibration';

const TitleComponent = React.memo(({ id, name, color, ...props }) => {
    const { _onGroupNameClick } = useContext(CalibrationContext);

    const onTitleClick = (groupId) => (e) => {
        _onGroupNameClick(groupId);
    };
    return (
        <MainWrapper onClick={onTitleClick(id)} color={color}>
            {name}
        </MainWrapper>
    );
});

export default TitleComponent;

const MainWrapper = styled.div`
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
`;
