import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

const Stages = React.memo(({ camera, ...props }) => {
    const onStageClick = (stage, id) => () => {
        props.onStageClick(stage, id);
    };

    const iconClass = mergeStyles({
        fontSize: 16,
        height: 20,
        width: 20,
        margin: '0 5px',
    });

    const stagesList = useMemo(() => {
        return [1, 0, 2, 0, 3, 0, 4].map((item, i) => {
            let ringColor = '#ccc';
            let allowClick = false;
            let opacity = 0.3;
            if (item <= camera.calibration_stage) {
                ringColor = '#008000';
                allowClick = true;
                opacity = 1;
            } else if (item === camera.calibration_stage + 1) {
                ringColor = '#FFD700';
                allowClick = true;
                opacity = 1;
            }
            if (item !== 0) {
                return (
                    <Stage
                        key={`${camera.id}-++${item}>>${i}`}
                        ringColor={ringColor}
                        allowClick={allowClick}
                        opacity={opacity}
                        onClick={onStageClick(item, camera.id)}
                    >
                        {item}
                    </Stage>
                );
            } else {
                return <FontIcon iconName="ChevronRight" className={iconClass} key={`${camera.id}-++${item}>>${i}`} />;
            }
        });
    }, [camera]);

    return <StagesMainWrapper>{stagesList}</StagesMainWrapper>;
});

export default Stages;

const StagesMainWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Stage = styled.div`
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: ${(p) => `3px solid ${p.ringColor}`};
    pointer-events: ${(p) => (p.allowClick ? 'auto' : 'none')};
    opacity: ${(p) => p.opacity};
    margin-right: 5px;
`;
