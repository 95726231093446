import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { Slider } from 'office-ui-fabric-react/lib/Slider';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DatePicker } from 'office-ui-fabric-react/lib/DatePicker';

import styled, { keyframes } from 'styled-components/macro';

import { VideosContext } from '../videos';

const hourOptions: IDropdownOption[] = [
    { key: '0', text: '0:00' },
    { key: '1', text: '1:00' },
    { key: '2', text: '2:00' },
    { key: '3', text: '3:00' },
    { key: '4', text: '4:00' },
    { key: '5', text: '5:00' },
    { key: '6', text: '6:00' },
    { key: '7', text: '7:00' },
    { key: '8', text: '8:00' },
    { key: '9', text: '9:00' },
    { key: '10', text: '10:00' },
    { key: '11', text: '11:00' },
    { key: '12', text: '12:00' },
    { key: '13', text: '13:00' },
    { key: '14', text: '14:00' },
    { key: '15', text: '15:00' },
    { key: '16', text: '16:00' },
    { key: '17', text: '17:00' },
    { key: '18', text: '18:00' },
    { key: '19', text: '19:00' },
    { key: '20', text: '20:00' },
    { key: '21', text: '21:00' },
    { key: '22', text: '22:00' },
    { key: '23', text: '23:00' },
];

const stepOptions = [
    { key: '0.1', text: '0.1' },
    { key: '0.5', text: '0.5' },
    { key: '1.0', text: '1.0' },
    { key: '2.0', text: '2.0' },
];

const ControlPanel = ({ ...props }) => {
    const {
        player,
        step,
        selectedDate,
        selectedHour,
        selectedMinute,
        sliceDuration,

        changePlayedTrans,
        togglePlayTrans,
        onHourChangeTrans,
        onMinuteChangeTrans,
        onStepChangeTrans,
        stopPlayerTrans,
        changeDateTrans,
    } = useContext(VideosContext);

    const minuteOptions = [];

    for (let i = 0; i < 60; i = i + 10) {
        minuteOptions.push({ key: i, text: i });
    }

    const [count, setCount] = useState(0);

    const interval = 0.1;

    useEffect(() => {
        if (player.playing) {
            const secondsLeft = setInterval(() => {
                setCount((c) => c + interval);
            }, interval * 1000);
            return () => clearInterval(secondsLeft);
        } else {
            setCount(() => 0);
        }
    }, [player.playing]);

    useEffect(() => {
        const currentPlayedSeconds = player.playedSeconds + interval;
        changePlayedTrans(currentPlayedSeconds);
    }, [count]);

    const sliderOnChange = (value) => {
        stopPlayerTrans();
        changePlayedTrans(value * step);
    };

    const togglePlay = () => {
        togglePlayTrans();
    };

    const onSelectDate = (date) => {
        changeDateTrans(date);
    };

    const value = Number(player.playedSeconds.toFixed(1)) / step;

    const iconName = player.playing ? 'CirclePauseSolid' : 'MSNVideosSolid';

    const onHourChange = (event, item) => {
        onHourChangeTrans(item.key);
    };

    const onMinuteChange = (event, item) => {
        onMinuteChangeTrans(item.key);
    };

    const onStepChange = (event, item) => {
        onStepChangeTrans(item.key);
    };

    return (
        <MainWrapper>
            <Wrapper>
                <DatePicker label="Date" allowTextInput ariaLabel="Select a date" value={selectedDate} onSelectDate={onSelectDate} />
            </Wrapper>
            <Wrapper>
                <Dropdown placeholder="Hour" label="Hour" options={hourOptions} onChange={onHourChange} selectedKey={selectedHour} />
            </Wrapper>

            <Wrapper>
                <Dropdown
                    placeholder="Minute"
                    label="Minute"
                    options={minuteOptions}
                    disabled={selectedHour === null}
                    onChange={onMinuteChange}
                    selectedKey={selectedMinute}
                />
            </Wrapper>
            <Wrapper>
                <Dropdown placeholder="Step" label="Step" options={stepOptions} onChange={onStepChange} selectedKey={step.toFixed(1)} />
            </Wrapper>

            <SliderWrapper>
                <Slider
                    disabled={selectedMinute === null}
                    onChange={sliderOnChange}
                    label="Second"
                    value={value}
                    min={0}
                    max={sliceDuration / step}
                    step={1}
                    defaultValue={0}
                    showValue={false}
                    snapToStep
                />
            </SliderWrapper>

            <ValueBox>{(value * step).toFixed(1)}</ValueBox>
            <StyledFontIcon iconName={iconName} onClick={togglePlay} />
        </MainWrapper>
    );
};

export default ControlPanel;

function blinkingEffect() {
    return keyframes`
      50% {
        opacity: 0;
      }
    `;
}

const MainWrapper = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    padding: 10px;
    display: flex;
    align-items: center;
`;

const ValueBox = styled.div`
    width: 50px;
    height: 34px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
`;

const SliderWrapper = styled.div`
    flex-grow: 1;
`;

const Wrapper = styled.div`
    display: flex;
    /* align-items: center; */
    margin-right: 10px;
`;

const StyledFontIcon = styled(FontIcon)`
    cursor: pointer;
    font-size: 30px;
    /* animation: ${blinkingEffect} 0.5s ease-in-out infinite;
    :hover {
        animation: ${blinkingEffect} 2s ease-in-out infinite;
    } */
`;
