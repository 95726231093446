import React, { useState, useMemo, useRef, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import turf from 'turf';
import * as THREE from 'three';
import { useSelector, useDispatch } from 'react-redux';

import { Layer, Text, Group, Shape } from 'react-konva';

import { getArrowTriangle } from './tools/getArrowTriangle';

const Layer3D = ({ transform3DToImage, scale, layer3dId, plan, visible, ...props }) => {
    const dispatch = useDispatch();
    const { objectsById, selectedObject, visibleObjects } = useSelector((state) => state.PassWaysLayerReducer);
    const { t } = useTranslation();

    const geometry = useMemo(() => {
        return Object.keys(objectsById)
            .filter((key) => visibleObjects[key])
            .map((key) => {
                const obj = objectsById[key];
                const { passLine, id, passMargin } = obj;
                const passLinePoints = passLine.coordinates.map((point) => {
                    return [point[0] / plan.scale, (plan.height - point[1]) / plan.scale];
                });

                const trianglePolygons = [];

                passLinePoints.forEach((point, i, arr) => {
                    if (i === 0) return;
                    const trianglePolygon = getArrowTriangle(point, i, arr, 1, passMargin, scale.stageScale);
                    trianglePolygons.push(trianglePolygon);
                });

                var line = turf.lineString(passLinePoints, { color: 'red' });
                var buffered = turf.buffer(line, (passMargin / 2) * 111);

                const passLine3DPoints = buffered.geometry.coordinates[0].map((item) => {
                    return [item[0], item[1], 0];
                });

                const imagePolygon = transform3DToImage('polygon', [...passLine3DPoints]);

                const arrowsImagePolygons = trianglePolygons.map((item) => {
                    const item3d = item.map((p) => [p[0], p[1], 0]);
                    return transform3DToImage('polygon', [...item3d]);
                });

                const imageArrows = arrowsImagePolygons.map((item, i) => {
                    return item?.length !== 0 ? (
                        <Shape
                            id={`${i}-${id}-arrow`}
                            key={`${i}-${id}-arrow`}
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(...item[0]);
                                item.forEach((coord, i) => {
                                    if (i > 0) {
                                        context.lineTo(...coord);
                                    }
                                });
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            fill={'red'}
                            opacity={0.6}
                        />
                    ) : null;
                });

                const shape =
                    imagePolygon?.length !== 0 ? (
                        <Shape
                            id={id}
                            key={id}
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(...imagePolygon[0]);
                                imagePolygon.forEach((coord, i) => {
                                    if (i > 0) {
                                        context.lineTo(...coord);
                                    }
                                });
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            fill={'red'}
                            opacity={0.4}
                        />
                    ) : null;

                return (
                    <Group key={`group)<<)${key}`}>
                        {shape}
                        {imageArrows}
                    </Group>
                );
            });
    }, [objectsById, scale, visibleObjects, transform3DToImage]);

    return visible ? <Layer key={`PassWaysLayer3D>>${layer3dId}`}>{geometry}</Layer> : null;
};

export default Layer3D;
