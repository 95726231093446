import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import isEqual from 'lodash/isEqual';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedCameras: [
        '1033',
        // '1034',
        //  '1035'
    ],
    availableVideoFilesByCameraId: {},
    availableTracksFilesByCameraId: {},
    sliceDuration: 600,
    selectedDate: new Date(),
    selectedHour: null,
    selectedMinute: null,
    step: 0.5,
    player: {
        playedSeconds: 0,
        playing: false,
    },
};

export const videosReducer = createSlice({
    name: 'videosReducer',
    initialState,

    reducers: {
        getAvailableVideofiles: (state, action) => {
            const { availableVideoFilesByCameraId } = cloneDeep(state);
            const {
                data,
                additionalParams: { cameraId },
            } = action.payload;
            const byTime = {};
            data.forEach((item) => {
                byTime[item.datetime] = item;
            });
            availableVideoFilesByCameraId[cameraId] = byTime;
            state.availableVideoFilesByCameraId = availableVideoFilesByCameraId;
        },

        getAvailableTracksfiles: (state, action) => {
            const { availableTracksFilesByCameraId } = cloneDeep(state);
            const {
                data,
                additionalParams: { cameraId },
            } = action.payload;
            const byTime = {};
            data.forEach((item) => {
                byTime[item.datetime] = item;
            });
            availableTracksFilesByCameraId[cameraId] = byTime;
            state.availableTracksFilesByCameraId = availableTracksFilesByCameraId;
        },

        getVideos: (state, action) => {
            const { availableVideoFilesByCameraId } = cloneDeep(state);
            const {
                data,
                additionalParams: { cameraId },
            } = action.payload;
            const newFiles = { ...availableVideoFilesByCameraId[cameraId], [data.datetime]: data };
            const newAvailableVideoFilesByCameraId = { ...availableVideoFilesByCameraId, [cameraId]: newFiles };
            state.availableVideoFilesByCameraId = newAvailableVideoFilesByCameraId;
        },

        getTracks: (state, action) => {
            const { availableTracksFilesByCameraId } = cloneDeep(state);
            const {
                data,
                additionalParams: { cameraId, dateTime },
            } = action.payload;
            const newFiles = { ...availableTracksFilesByCameraId[cameraId], [dateTime]: data };
            const newAvailableTracksByCameraId = { ...availableTracksFilesByCameraId, [cameraId]: newFiles };
            state.availableTracksFilesByCameraId = newAvailableTracksByCameraId;
        },

        changeCurrentProgress: (state, action) => {
            const player = { ...cloneDeep(state.player), ...action.payload };
            state.player = player;
        },

        stopPlayer: (state, action) => {
            const player = cloneDeep(state.player);
            player.playing = false;
            state.player = player;
        },

        changeDate: (state, action) => {
            state.selectedDate = action.payload;
            state.selectedHour = null;
            state.selectedMinute = null;
            state.player = {
                playedSeconds: 0,
                playing: false,
            };
        },

        changeHour: (state, action) => {
            state.selectedHour = action.payload;
            state.selectedMinute = null;
            state.player = {
                playedSeconds: 0,
                playing: false,
            };
        },

        changeMinute: (state, action) => {
            state.selectedMinute = action.payload;
            state.player = {
                playedSeconds: 0,
                playing: false,
            };
        },

        changeStep: (state, action) => {
            state.step = parseFloat(action.payload);
        },

        togglePlay: (state, action) => {
            const player = cloneDeep(state.player);
            player.playing = !player.playing;
            state.player = player;
        },

        changePlayed: (state, action) => {
            const player = cloneDeep(state.player);
            player.playedSeconds = action.payload;
            state.player = player;
        },
    },
});

// export const manageChangedCamerasIds = () => (dispatch, getState) => {
//     const { videosReducer, camerasReducer } = getState();
//     const { changedCamerasIds } = cloneDeep(videosReducer);
//     const { camerasById, camerasById3D } = camerasReducer;
//     let newChangedCamerasIds = [];

//     Object.keys(camerasById).forEach((key) => {
//         const { id } = camerasById[key];
//         if (!isEqual(camerasById[id], camerasById3D[id])) {
//             newChangedCamerasIds.push(id);
//         }
//     });
//     dispatch(setChangedCamerasIds(newChangedCamerasIds));
// };

export const {
    changeCurrentProgress,
    stopPlayer,
    togglePlay,
    changePlayed,
    changeDate,
    changeHour,
    changeStep,
    changeMinute,
    getVideos,
    getTracks,
    getAvailableVideofiles,
    getAvailableTracksfiles,
} = videosReducer.actions;

export default videosReducer.reducer;
