import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { filterListOfObjects } from './core/filterListOfObjects';

/**
 * Компонента инпута с возможностью фильтрации
 */

const FilterInput = ({ inputArray, updateFilteredArray, matchField }) => {
    const { t } = useTranslation();
    const [filterValue, setFilterValue] = useState('');

    useEffect(() => {
        const filteredArray = filterListOfObjects(inputArray, matchField, filterValue);
        updateFilteredArray(filteredArray);
    }, [filterValue, inputArray]);

    const onChange = (event, newValue) => {
        setFilterValue(newValue || '');
    };

    return <TextField placeholder={t('Filter')} value={filterValue} onChange={onChange} width={'300px'} />;
};

export default FilterInput;
