import cloneDeep from 'lodash/cloneDeep';
import { createSlice } from '@reduxjs/toolkit';

export const plansReducer = createSlice({
    name: 'plansReducer',

    initialState: {
        activeGroup: 'calibration_cut',
        plans: [],
        planToEdit: null,
        tool: {
            id: null,
        },
        controlDictionary: {
            anchors_edit: {
                name: 'Anchors edit',
                icon: { iconName: 'RadioBtnOn', size: '16px' },
                canDraw: false,
            },
            calibration_cut: {
                name: 'Calibration cut',
                icon: { iconName: 'PenWorkspace', size: '20px' },
                canDraw: true,
                elements_limit: 1,
                element: 'line',
                color: 'red',
                visible: true,
            },
            zoom_in: {
                name: 'Zoom in',
                icon: { iconName: 'ZoomIn', size: '20px' },
                canDraw: false,
            },
            zoom_out: {
                name: 'Zoom out',
                icon: { iconName: 'ZoomOut', size: '20px' },
                canDraw: false,
            },
        },
    },

    reducers: {
        getPlans: (state, action) => {
            state.plans = action.payload.data;
        },

        resetPlanToEdit: (state, action) => {
            state.planToEdit = null;
        },

        onAddNewPlanModalClick: (state, action) => {
            state.planToEdit = action.payload.planData;
        },

        changePlanTextFields: (state, action) => {
            const { field, newValue } = action.payload;
            const planToEdit = cloneDeep(state.planToEdit);
            if (field !== 'length_m') {
                planToEdit[field] = newValue;
            } else {
                const IsNumeric = (num) => /^\d*\.{0,1}\d*$/.test(num);
                if (IsNumeric(newValue)) {
                    planToEdit.measured_segment = { ...planToEdit.measured_segment, length_m: newValue };
                }
            }
            state.planToEdit = planToEdit;
        },

        onCreateNewElement: (state, action) => {
            const { newCoords } = action.payload;
            const planToEdit = cloneDeep(state.planToEdit);
            planToEdit.measured_segment = { ...planToEdit.measured_segment, coords: newCoords };
            state.planToEdit = planToEdit;
        },

        setPlanToEdit: (state, action) => {
            const { id } = action.payload;
            if (id) {
                const plans = cloneDeep(state.plans);
                const planToEdit = plans.filter((plan) => plan.id === id)[0];
                state.planToEdit = planToEdit;
            } else {
                state.planToEdit = null;
            }
        },

        onUpdateGeometry: (state, action) => {
            console.log(action.payload);
            const { pointIndex, newCoords } = action.payload;
            const planToEdit = cloneDeep(state.planToEdit);

            const coords = planToEdit.measured_segment.coords.map((point, i) => {
                if (i !== pointIndex) {
                    return point;
                } else {
                    return newCoords;
                }
            });

            planToEdit.measured_segment = { ...planToEdit.measured_segment, coords };

            state.planToEdit = planToEdit;
        },

        toggleDraw: (state, action) => {
            state.tool = { ...state.tool, id: action.payload };
        },
    },
});

export const {
    getPlans,
    onAddNewPlanModalClick,
    toggleDraw,
    changePlanTextFields,
    resetPlanToEdit,
    setPlanToEdit,
    onUpdateGeometry,
    onCreateNewElement,
} = plansReducer.actions;

export default plansReducer.reducer;
