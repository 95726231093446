import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import AccordionSection from './components/accordion_section';

const Accordion = React.memo(({ children, allowMultipleOpen = true, ...props }) => {
    const [openSections, set_openSections] = useState({});

    useEffect(() => {
        if (Object.keys(openSections).length !== 0 && children.length > 1) return;
        const open_sections = {};
        children.forEach((child) => {
            try {
                if (child.props.open) {
                    open_sections[child.props.label] = true;
                }
                set_openSections(open_sections);
            } catch (error) {}
        });

        // try {
        //     const openSections = {};
        //     openSections[children[0].props.label] = true;
        //     set_openSections(openSections);
        // } catch (error) {
        //     console.warn('ACCORDION');
        // }
    }, [children]);

    const onSectionClick = (label) => {
        const isOpen = !!openSections[label];
        if (allowMultipleOpen === true) {
            set_openSections({ ...openSections, [label]: !isOpen });
        } else {
            set_openSections({ [label]: !isOpen });
        }
    };
    return (
        <AccordionWrapper>
            {children.map((child, i) => {
                // if (child.props.selected && !openSections[child.props.label]) {
                //     onSectionClick(child.props.label);
                // }
                return (
                    <AccordionSection
                        title_сomponent={child.props.title_сomponent}
                        key={`${child.props.label}-${i}`}
                        isOpen={!!openSections[child.props.label]}
                        label={child.props.label}
                        color={child.props.color}
                        eyeVisible={child.props.eyeVisible}
                        selected={child.props.selected}
                        id={child.props.id}
                        data={child.props.data}
                        show={child.props.show}
                        additionalControls={child.props.additionalControls}
                        onSectionClick={onSectionClick}
                        showArrows={props.showArrows}
                        onSectionEyeClick={props.onSectionEyeClick}
                        showSectionEye={child.props.showSectionEye}
                        onClipboardClick={props.onClipboardClick}
                        showClipBoard={props.showClipBoard}
                        disabled={child.props.disabled}
                    >
                        {child}
                    </AccordionSection>
                );
            })}
        </AccordionWrapper>
    );
});

export default Accordion;

const AccordionWrapper = styled.div`
    /* height: 100%; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* flex-shrink: 0;
    flex-grow: 3; */
    /* background-color: yellow; */
`;
