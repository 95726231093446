import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNewObject, selectObject, toggleObjectVisible, showModal } from './PassWaysLayerReducer';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Eye from '../../../../eye/eye';

const ObjectsList = ({ plan, isLayerSelected, ...props }) => {
    const dispatch = useDispatch();
    const { objectsById = {}, selectedObject, visibleObjects } = useSelector((state) => state.PassWaysLayerReducer);

    const onSelectObjectClick = (args) => () => {
        dispatch(selectObject({ ...args }));
    };

    const onEyeClick = (id) => {
        dispatch(toggleObjectVisible({ id }));
    };

    const { t } = useTranslation();

    const layerObjects = Object.keys(objectsById).map((key) => {
        const obj = objectsById[key];
        const { id, marker } = obj;
        const bgcolor = id === selectedObject ? '#ccc' : '#eee';
        return (
            <ObjectWrapper bgcolor={bgcolor} key={key}>
                <Eye eyeVisible={visibleObjects[id]} id={id} onEyeClick={onEyeClick} />
                <Main disabled={!isLayerSelected} onClick={onSelectObjectClick({ id })}>
                    {marker}
                </Main>
            </ObjectWrapper>
        );
    });

    return <MainWrapper>{[...layerObjects]}</MainWrapper>;
};

export default ObjectsList;

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const Main = styled.div`
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    opacity: ${(p) => (p.disabled ? '0.4' : '1')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
`;
const ObjectWrapper = styled.div`
    margin-left: 6px;
    /* width: 100%; */
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background-color: ${(p) => p.bgcolor};
    padding: 0 10px;
`;
