import * as THREE from 'three';

export const getArrowEndPoint = (point, i, arr, scale, passMargin, stageScale) => {
    const center = [(point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2];
    const normalV = new THREE.Vector3(0, 0, 1);
    const arrowV = new THREE.Vector3(point[0], point[1], 0)
        .sub(new THREE.Vector3(...center, 0))
        .normalize()
        .multiplyScalar(10 / Math.pow(stageScale, 0.5))
        // .multiplyScalar(Math.max(scale * passMargin, scale * 0.4))
        .applyAxisAngle(normalV, -Math.PI / 2)
        .add(new THREE.Vector3(...center, 0));

    return [arrowV.x, arrowV.y];
};
