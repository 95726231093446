import React, { useState, useMemo, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { PerspectiveContext } from '../perspective';

const TitleComponent = React.memo(({ id = undefined, name, color, ...props }) => {
    const { _onCameraMarkerClick, _selectPlanLayer, selectedList } = useContext(PerspectiveContext);

    const onTitleClick = (id) => (e) => {
        const selectedIds = selectedList.map((item) => item.id);
        if (!id || selectedIds.includes(id)) return;
        _selectPlanLayer(id);
    };
    return (
        <MainWrapper onClick={onTitleClick(id)} color={color}>
            {name}
        </MainWrapper>
    );
});

export default TitleComponent;

const MainWrapper = styled.div`
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
`;
