import React from 'react';
import styled from 'styled-components/macro';
import eyeIcon from './icons/eye.svg';

const Eye = React.memo(({ eyeVisible, id, ...props }) => {
    const onEyeClick = (id) => (e) => {
        e.stopPropagation();
        props.onEyeClick(id);
    };

    return (
        <Wrapper>
            <EyeIcon eyeVisible={eyeVisible} onClick={onEyeClick(id)} />
        </Wrapper>
    );
});

export default Eye;

const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const EyeIcon = styled.div`
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    background: ${(p) => (p.eyeVisible ? `url(${eyeIcon}) no-repeat center` : 'none')};
    background-size: 90%;
    border: ${(p) => (p.eyeVisible ? 'none' : '1px solid rgba(0, 0, 0, 0.3)')};
    margin-right: 5px;
`;
