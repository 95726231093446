import React, { useContext } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { useTranslation } from 'react-i18next';
import { PlansContext } from '../plans';
import ControlPanelFooter from './control_panel_footer';
import MainCP from '../../wrappers/main_cp';

const ControlPanel = React.memo(() => {
    const { t } = useTranslation();
    const context = useContext(PlansContext);
    const { planToEdit, _changePlanTextFields } = context;

    const onTextFieldChange = (field) => (event, newValue) => {
        _changePlanTextFields({ field, newValue: event.target.type === 'number' ? Number(newValue) : newValue });
    };

    const stackTokens = {
        childrenGap: 5,
        // padding: 10,
    };

    return (
        <MainCP>
            <Stack tokens={stackTokens}>
                <Stack.Item>
                    <TextField value={planToEdit.name} label={t('Plan name')} onChange={onTextFieldChange('name')} autoFocus />
                </Stack.Item>
                <Stack.Item>
                    <TextField type={'number'} value={planToEdit.floor} label={t('Floor')} onChange={onTextFieldChange('floor')} />
                </Stack.Item>
                <Stack.Item>
                    <TextField
                        value={planToEdit.measured_segment.length_m}
                        label={t('Calibration cut in meters')}
                        onChange={onTextFieldChange('length_m')}
                    />
                </Stack.Item>
            </Stack>
            <ControlPanelFooter planToEdit={planToEdit} />
        </MainCP>
    );
});

export default ControlPanel;
