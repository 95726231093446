import React, { useMemo, useState } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { useConstCallback } from '@uifabric/react-hooks';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const RenameAnchorPointModal = React.memo(({ modalData, ...props }) => {
    const { t } = useTranslation();
    const [value, set_value] = useState(modalData.options.text);

    const onChange = useConstCallback((event, newValue) => {
        set_value(newValue || '');
    });

    const onOKClick = (options) => () => {
        props._onOKClick(options);
    };

    const disabled = useMemo(() => {
        let result = false;
        const allNames = modalData.options.allAnchorPoints[0].elements.map((item) => item.name);
        if (allNames.includes(value)) {
            result = true;
        }
        return result;
    }, [modalData, value]);

    return (
        <MainWrapper>
            <TextField value={value} onChange={onChange} autoFocus />
            <Footer>
                <PrimaryButton
                    text={t('Rename')}
                    disabled={disabled}
                    onClick={onOKClick({ pointId: modalData.options.pointId, newValue: value })}
                />
            </Footer>
        </MainWrapper>
    );
});

export default RenameAnchorPointModal;

const MainWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Footer = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;
