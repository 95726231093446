import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import styled from 'styled-components/macro';
import { theme } from '../../../../constants/theme';

const { green, red } = theme.palette;

export const ListWrapper = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    // margin: '0 25px',
});

export const classNames = mergeStyleSets({
    red: [{ color: red }, iconClass],
    green: [{ color: green }, iconClass],
});
