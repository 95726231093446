const host = {
    adminService: 'https://admin.focustech.xyz',
    cameraService: 'https://camera-service.focustech.xyz',
    mapService: 'https://map-service.focustech.xyz',
};

/**
 * Объект с изначальными адресами для ручек.
 */
export const urls = {
    AUTH_URL: `${host.adminService}/sso/ajax-token`,
    LOCATIONS_URL: `${host.cameraService}/plans/locations`,
    CAMERAS_URL: `${host.cameraService}/cameras/camera_installations`,
    PLANS_URL: `${host.cameraService}/plans`,
    CALIBRATION_URL: `${host.cameraService}/calibration`,
    GET_OR_CREATE_URL: `${host.cameraService}/plans/get_or_create_ms_location/`,
    AVAILABLE_VIDEOFILES_URL: `${host.cameraService}/client_api/available_video_files/`,
    ACCESS_VIDEOFILES_URL: `${host.cameraService}/client_api/access_video_file/`,
    AVAILABLE_TRACKSFILES_URL: `${host.cameraService}/client_api/available_raw_tracks_files/`,
    TRACKS_URL: `${host.cameraService}/client_api/get_raw_tracks/`,

    MAP_SERVICE_LAYERS_URL: `${host.mapService}/api/layers/`,
};
