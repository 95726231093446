import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { CalibrationContext } from '../calibration';
import Accordion from '../../accordion';
import TitleComponent from './title_component';
import Layers from './layers';
import LayersControls from './layers_controls';
import CP_Footer from './control_panel_footer';
import MainCP from '../../wrappers/main_cp';

const ControlPanel = React.memo(() => {
    const { _onSectionEyeClick, controlDictionary, calibration_geometry, activeGroup } = useContext(CalibrationContext);

    const groupsList = Object.keys(controlDictionary)
        .filter((item) => controlDictionary[item].canDraw)
        .map((key) => {
            const { name, color } = controlDictionary[key];
            const selected = activeGroup === key;
            const layers = calibration_geometry[key];
            const eyeVisible = controlDictionary[key].visible;

            return (
                <Div
                    eyeVisible={eyeVisible}
                    color={color}
                    label={key}
                    key={key}
                    open={true}
                    selected={selected}
                    title_сomponent={<TitleComponent name={name} color={color} id={key} />}
                >
                    <Layers layers={layers} groupId={key} />
                </Div>
            );
        });

    return (
        <MainCP>
            <Box>
                <Accordion onSectionEyeClick={_onSectionEyeClick} showArrows={true}>{groupsList}</Accordion>
                <LayersControls />
            </Box>
            <CP_Footer />
        </MainCP>
    );
});

export default ControlPanel;

const Box = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
`;
const Div = styled.div``;
