import { Text, Label, Tag } from 'react-konva';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { scaleTransformFoo } from '../core/scaleTransformFoo';

const ObjectLabel = ({ coords, text, name, stageScale = 1, ...props }) => {
    const { t } = useTranslation();
    const markerText = text !== undefined ? `${t('id')}: ${text}` : '';
    const nameText = name ? `\n${t('Name')}: ${name}` : '';
    const foo = (num) => scaleTransformFoo(num, stageScale);
    return (
        <Label x={coords.x} y={coords.y} opacity={0.75}>
            <Tag
                fill={'black'}
                pointerDirection={'down'}
                pointerWidth={foo(10)}
                pointerHeight={foo(10)}
                lineJoin={'round'}
                shadowColor={'black'}
                shadowBlur={1}
                shadowOffsetX={1}
                shadowOffsetY={1}
                shadowOpacity={0.1}
            />
            <Text text={markerText + nameText} fontSize={foo(14)} lineHeight={1.2} padding={foo(5)} fill={'white'} />
        </Label>
    );
};

export default ObjectLabel;
