import styled from 'styled-components/macro';

export const LocationsWrapper = styled.section`
    height: 100%;
    overflow: hidden;
    background-color: ${(p) => p.bgcolor};
    color: ${(p) => p.color};
    padding: 10px;
`;

export const stackTokens = {
    childrenGap: 10,
    // padding: 10,
};
