import React, { useState, useMemo, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { TooltipHost, ITooltipHostStyles, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { GlobalContext } from '../../../App';
import { PlansContext } from '../plans';

const ToolsPanel = React.memo(() => {
    const { t } = useTranslation();
    const { dist_lines, controlDictionary, rawImage, theme, tool, _toggleDraw, activeGroup } = useContext(PlansContext);



    const onClick = (id) => (e) => {
        if (id === tool.id) {
            _toggleDraw(null);
        } else {
            _toggleDraw(id);
        }
    };

    const toolsButtons = useMemo(() => {
        const result = Object.keys(controlDictionary)
            .filter((key) => !controlDictionary[key].canDraw)
            .map((key) => {
                let disabled = false;
                const bgColor = key === tool.id && !disabled ? '#ccc !important' : 'transparent !important';
                const tooltip = controlDictionary[key].name;
                return (
                    <TooltipHost content={tooltip} key={key} directionalHint={DirectionalHint.rightCenter}>
                        <StIconButton
                            iconProps={controlDictionary[key].icon}
                            disabled={disabled}
                            bgcolor={bgColor}
                            onClick={onClick(key)}
                            size={controlDictionary[key].icon.size}
                        />
                    </TooltipHost>
                );
            });

        let disabled = false;
        if (!activeGroup || !controlDictionary[activeGroup]) {
            disabled = true;
        }
        const bgColor = activeGroup === tool.id && !disabled ? '#ccc !important' : 'transparent !important';
        const tooltip = disabled ? t('Please select group') : controlDictionary[activeGroup].name;

        result.splice(
            1,
            0,
            <TooltipHost content={tooltip} key={activeGroup} directionalHint={DirectionalHint.rightCenter}>
                <StIconButton
                    iconProps={controlDictionary[activeGroup].icon}
                    disabled={disabled}
                    bgcolor={bgColor}
                    onClick={onClick(activeGroup)}
                    size={controlDictionary[activeGroup].icon.size}
                />
            </TooltipHost>
        );

        return result;
    }, [tool, activeGroup]);

    return (
        <MainWrapper>
            {toolsButtons}
        </MainWrapper>
    );
});

export default ToolsPanel;

const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 5px;
    display: grid;
    grid-gap: 5px;
    grid-template-rows: repeat(10, 40px);
    background-color: rgb(240, 242, 245);
`;

const StIconButton = styled(IconButton)`
    background-color: ${(p) => p.bgcolor};
    :hover {
        background-color: #ccc !important;
    }
    i {
        font-size: ${(p) => p.size};
    }
`;
