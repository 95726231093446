import React from 'react';
import styled from 'styled-components/macro';

const MainWrapper = React.memo(({ children }) => {
    return <Container>{children} </Container>;
});

export default MainWrapper;

const Container = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px;
    background-color: rgb(236, 236, 236);
`;
