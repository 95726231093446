import React, { useState, useMemo, useRef, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { point, vector, circle, line, ray, segment, arc, polygon, matrix } from '@flatten-js/core';
import Konva from 'konva';
import useImage from 'use-image';
import { Stage, Layer, Star, Text, Image, Line, Rect, Shape, Circle, Group } from 'react-konva';

import { PerspectiveContext } from '../perspective';
import { GlobalContext } from '../../../App';

import { drawPolygon, drawLine } from '../../../tools/draw_tools';
import { Transform3dToImage } from '../../../tools/matrix_transform';

import styled from 'styled-components/macro';

const Final3DPolygon = React.memo(({ camera, imgUrl, myLayout, splitSizes }) => {
    const matrix = useMemo(() => {
        return camera.perspective_profile.full_perspective_geometry.transform_matrices['3d_to_image'];
    }, [camera.perspective_profile.full_perspective_geometry.transform_matrices]);
    const imageSize = { height: camera.image_profile.height, width: camera.image_profile.width };

    const { layers } = useContext(GlobalContext);
    const { t } = useTranslation();
    const stageRef = useRef(null);
    const mainWrapperRef = useRef(null);

    const [stageSize, set_stageSize] = useState({ width: window.innerWidth - 346, height: window.innerHeight * 0.8 });

    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const { plans, activePlanId, changedCamerasIds, visiblePlanLayers } = useContext(PerspectiveContext);

    const [image] = useImage(imgUrl);

    useEffect(() => {
        checkSize();
        window.addEventListener('resize', checkSize);
        return () => {
            window.removeEventListener('resize', checkSize);
        };
    }, []);

    useEffect(() => {
        checkSize();
    }, [myLayout, splitSizes]);

    const transform3DToImage = useCallback(
        (type, data) => {
            return new Transform3dToImage(type, imageSize, matrix, data).getImageGeometry();
        },
        [imageSize, matrix]
    );

    const checkSize = () => {
        calculateScale();
        const { offsetWidth: width, offsetHeight: height } = mainWrapperRef.current;
        set_stageSize({ width, height });
    };

    const calculateScale = () => {
        const stageScale = mainWrapperRef.current.clientWidth / camera.image_profile.width;
        set_scale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    };

    const getTopLvlPoint = (point, height) => {
        return [point[0], point[1], height];
    };

    const getBoxWalls = (floorPolygon, height = 2.0) => {
        const result = floorPolygon.map((point, i, arr) => {
            if (i === 0) {
                return [point, arr[arr.length - 1], getTopLvlPoint(arr[arr.length - 1], height), getTopLvlPoint(point, height)];
            } else {
                return [point, arr[i - 1], getTopLvlPoint(arr[i - 1], height), getTopLvlPoint(point, height)];
            }
        });
        return result;
    };

    const [plan] = plans.filter((item) => item.id === activePlanId);

    const makeFullGeometry = (camera) => {
        const result = [];
        const bottomLevel = camera.perspective_profile.full_perspective_geometry.areas_3d[0].elements[0].coords;
        const bottomPolygon = transform3DToImage('polygon', bottomLevel);

        const getGrid = (plan) => {
            if (!plan) return null;
            const grid = [];
            for (let i = 0; i <= plan.width / plan.scale; i = i + 1) {
                grid.push([point(i, 0), point(i, plan.height / plan.scale)]);
            }
            for (let i = 0; i <= plan.height / plan.scale; i = i + 1) {
                grid.push([point(0, i), point(plan.width / plan.scale, i)]);
            }
            return grid;
        };

        const poly = polygon(bottomLevel.map((item) => point(item[0], item[1])));

        const intersections = [];
        getGrid(plan).forEach((item) => {
            const l = line(item[0], item[1]);
            let ip = poly.intersect(l);
            if (ip.length) {
                intersections.push([
                    [ip[0].x, ip[0].y, 0],
                    [ip[1].x, ip[1].y, 0],
                ]);
            }
        });

        const boxWalls = getBoxWalls(bottomLevel, 2.0).map((wall, i) => {
            return drawPolygon({
                polygon: transform3DToImage('polygon', wall),
                color: 'rgba(0, 255, 0, 0.15)',
                key: `wall>>${i}>>${camera.id}`,
                strokeColor: 'rgb(0, 255, 0)',
                dash: [5, 3],
                strokeWidth: 1 / scale.stageScale,
            });
        });

        const floorGrid = intersections.map((line, i) => {
            const imgLine = transform3DToImage('line', line);
            const points = [imgLine[0][0], imgLine[0][1], imgLine[1][0], imgLine[1][1]];

            return drawLine({
                points,
                color: 'rgba(0, 255, 0, 0.15)',
                key: `line>>${i}>>${camera.id}`,
                strokeColor: 'rgba(0, 135, 0, 0.9)',
                strokeWidth: 1 / scale.stageScale,
            });
        });

        const group = (
            <Group key={`group++${camera.id}`} id={`group++${camera.id}`}>
                {drawPolygon({
                    polygon: bottomPolygon,
                    color: 'rgba(0, 255, 0, 0.15)',
                    strokeWidth: 2 / scale.stageScale,
                })}
                {floorGrid}
                {boxWalls}
            </Group>
        );

        result.push(group);

        return result;
    };

    const cameraJson = useMemo(() => {
        return JSON.stringify(camera.perspective_profile.full_perspective_geometry);
    }, [camera]);

    const fullGeometry = useMemo(() => {
        return makeFullGeometry(camera);
    }, [cameraJson, scale]);

    const lighter = () => {
        return <Rect height={camera.image_profile.height} width={camera.image_profile.width} fill={'#fff'} opacity={0.7} />;
    };
    const externalLayers = Object.keys(layers.draw3d).map((key) => {

        const visible = visiblePlanLayers.includes(key);
        return layers.draw3d[key]({ transform3DToImage, layer3dId: camera.id, scale, plan, visible });
    });

    return (
        <MainWrapper ref={mainWrapperRef}>
            <Stage
                ref={stageRef}
                width={stageSize.width}
                height={stageSize.height}
                draggable={false}
                scaleX={scale.stageScale}
                scaleY={scale.stageScale}
                x={scale.stageX}
                y={scale.stageY}
            >
                <Layer id={'imageLayer'}>{image ? <Image image={image} /> : null}</Layer>
                {externalLayers}
                <Layer id={'fullGeometry'}>{fullGeometry}</Layer>
                {changedCamerasIds.includes(camera.id) ? (
                    <Layer id={'lighterLayer'}>
                        <Group>{lighter()}</Group>
                    </Layer>
                ) : null}
            </Stage>
        </MainWrapper>
    );
});

export default Final3DPolygon;

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
`;
