import React, { useContext } from 'react';
import { TooltipHost, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { useTranslation } from 'react-i18next';
import { CalibrationContext } from '../calibration';
import { GlobalContext } from '../../../App';
import FooterCP from '../../wrappers/footer_cp';

const ControlPanelFooter = React.memo(() => {
    const { t } = useTranslation();

    const { currentCamera, calibration_geometry } = useContext(CalibrationContext);
    const { _savePerspectiveProfile } = useContext(GlobalContext);

    const onSaveChangesClick = (id) => () => {
        _savePerspectiveProfile({ id, calibration_geometry, entity: currentCamera?.perspective_profile?.entity || 'PerspectiveProfile' });
    };

    return (
        <FooterCP>
            <PrimaryButton
                text={t('Save changes')}
                onClick={onSaveChangesClick(currentCamera?.perspective_profile?.id || null)}
                allowDisabledFocus
                disabled={false}
            />
        </FooterCP>
    );
});

export default ControlPanelFooter;
