import React, { useState, useEffect } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { useConstCallback } from '@uifabric/react-hooks';
import cloneDeep from 'lodash/cloneDeep';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const ShowRawImageModal = React.memo(({ modalData, ...props }) => {
    const { t } = useTranslation();
    const [choice, set_choice] = useState('A');
    const [correction_custom_height, set_correction_custom_height] = useState(600);

    useEffect(() => {
        if (modalData?.options?.camera.image_profile.correction_pyr_level === -1) {
            set_choice(() => 'B');
        } else if (
            modalData?.options?.camera.image_profile.correction_pyr_level === 0 &&
            modalData?.options?.camera.image_profile.correction_custom_height
        ) {
            set_choice(() => 'C');
        }
    }, [modalData?.options?.camera]);

    const options = [
        { key: 'A', text: t("Don't resample") },
        { key: 'B', text: t('Pyramid resample (-1)') },
        { key: 'C', text: t('Pyramid resample (-2)') },
        { key: 'D', text: t('Set custom height') },
    ];
    const onOKClick = (camera) => () => {
        const image_profile = cloneDeep(camera.image_profile);
        if (choice === 'A') {
            image_profile.correction_pyr_level = 0;
            image_profile.correction_custom_height = null;
        } else if (choice === 'B') {
            image_profile.correction_pyr_level = -1;
            image_profile.correction_custom_height = null;
        } else if (choice === 'C') {
            image_profile.correction_pyr_level = -2;
            image_profile.correction_custom_height = null;
        } else if (choice === 'D') {
            image_profile.correction_pyr_level = 0;
            image_profile.correction_custom_height = Number(correction_custom_height);
        }

        image_profile.correction_dist_coeffs = [0, 0, 0, 0];
        image_profile.correction_roll_angle = 0.0;

        props._onOKClick({ ...camera, image_profile });
    };

    const _onChange = (ev, option) => {
        set_choice(() => option.key);
    };

    const onChange = useConstCallback((event, newValue) => {
        const IsMatch = (str) => /^$|^[\d]+$/.test(str);
        if (IsMatch(newValue)) {
            set_correction_custom_height(newValue || '');
        }
    });

    return (
        <MainWrapper>
            {modalData.options.key ? null : (
                <InputsContainer>
                    <StChoiceGroup selectedKey={choice} options={options} onChange={_onChange} required={true} />
                    <TextField value={correction_custom_height} onChange={onChange} disabled={choice !== 'D'} autoFocus />
                </InputsContainer>
            )}
            <ImgWrapper>
                <img
                    alt={''}
                    src={
                        modalData.options.key
                            ? modalData.options.camera.image_profile[modalData.options.key]
                            : modalData.options.camera.image_profile.source_image
                    }
                />
            </ImgWrapper>
            <Footer>
                {modalData.options.key ? null : (
                    <PrimaryButton text={t('Correct the image')} disabled={false} onClick={onOKClick(modalData.options.camera)} />
                )}
            </Footer>
        </MainWrapper>
    );
});

export default ShowRawImageModal;

const InputsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;
const ImgWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content:center;
    align-items: center;
    /* background-color: red; */
    img {
        max-width: 100%;
        height: auto;
    }
`;

const MainWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
        object-fit: contain;
    }
`;

const Footer = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const StTextField = styled(TextField)`
    min-width: 300px;
`;
const StChoiceGroup = styled(ChoiceGroup)`
    .ms-ChoiceFieldGroup-flexContainer {
        display: flex;
        align-items: center;
        label {
            display: flex;
            align-items: center;
            margin-right: 10px;
        }
    }
`;
