import React, { useState, useMemo, useRef, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import styled from 'styled-components/macro';

import { VideosContext } from '../videos';
import Vector from './vector';

import './react_player_style.css';

const VideoBox = ({ video, tracks, mainWrapperSize, index, ...props }) => {
    const playerWrapperRef = useRef();
    const playerRef = useRef();
    const { changeCurrentProgressTrans, player, sliceDuration, selectedStartTime, changePlayedTrans } = useContext(VideosContext);

    const { t } = useTranslation();
    const stageWrapperRef = useRef(null);
    const mainWrapperRef = useRef(null);

    const [playerSize, setPlayerSize] = useState({ width: 800, height: 600 });

    const currentVideoTime = useMemo(() => {
        return player.playedSeconds + (selectedStartTime.ts - video.params.startTimeStamp) / 1000;
    }, [player.playedSeconds, selectedStartTime.ts, video.params.startTimeStamp]);

    useEffect(() => {
        playerWrapperRef?.current &&
            setPlayerSize({
                width: playerWrapperRef.current.clientWidth,
                height: playerWrapperRef.current.clientHeight,
            });
    }, [mainWrapperSize, playerWrapperRef?.current?.clientWidth]);

    useEffect(() => {
        playerRef?.current && player?.playedSeconds && playerRef.current.seekTo(currentVideoTime);
    }, [player?.playedSeconds, playerRef?.current]);

    useEffect(() => {
        if (video.params.startTimeStamp < selectedStartTime.ts) {
            setTimeout(() => {
                try {
                    playerRef.current.seekTo(currentVideoTime);
                } catch (error) {}
            }, 10);
        }
    }, [selectedStartTime.ts, video.params.startTimeStamp]);

    const onProgress = (progress) => {
        if (index !== 0 || !player.playing) return;
        // changePlayedTrans(progress.playedSeconds);
    };

    const ratio = (video.params.size.height / video.params.size.width) * 100;

    const isVideoInRange = useMemo(() => {
        return (
            video.params.startTimeStamp <= selectedStartTime.ts + player.playedSeconds * 1000 &&
            currentVideoTime <= video.params.duration &&
            player.playedSeconds <= sliceDuration
        );
    }, [currentVideoTime, player.playedSeconds, selectedStartTime.ts, sliceDuration, video.params.duration, video.params.startTimeStamp]);

    return useMemo(() => {
        return (
            <MainWrapper ref={mainWrapperRef}>
                <StageWrapper ref={stageWrapperRef}>
                    {video && (
                        <PlayerWrapper ratio={ratio} ref={playerWrapperRef}>
                            {isVideoInRange ? (
                                <StyledReactPlayer
                                    ref={playerRef}
                                    url={video.url}
                                    width="100%"
                                    height="100%"
                                    playing={false}
                                    progressInterval={100}
                                    onProgress={onProgress}
                                />
                            ) : (
                                <NoData>No data</NoData>
                            )}
                        </PlayerWrapper>
                    )}
                    {isVideoInRange ? (
                        <Vector video={video} tracks={tracks} playerSize={playerSize} currentVideoTime={currentVideoTime} />
                    ) : null}
                </StageWrapper>
            </MainWrapper>
        );
    }, [currentVideoTime, isVideoInRange, player.playing, playerSize, ratio, video]);
};

export default VideoBox;

const PlayerWrapper = styled.div`
    width: 100%;
    position: relative;
    padding-top: ${(p) => p.ratio + '%'};
    /* padding-top: 56.25%; */
    /* height: ${(p) => p.height + 'px'}; */
`;

const NoData = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 20px;
    color: whitesmoke;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
`;

const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const StageWrapper = styled.div`
    position: relative;
    /* background-color: rgb(231, 234, 237); */
    width: 100%;
    overflow: hidden;
`;

const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
`;
