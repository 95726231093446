import React, { useMemo } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const DeleteLayerModal = React.memo(({ modalData, ...props }) => {
    const { t } = useTranslation();

    const onOKClick = (options) => () => {
        props._onOKClick(options);
    };

    return (
        <MainWrapper>
            <p>{`Delete layer ${modalData.options.layerName}`}</p>
            <Footer>
                <PrimaryButton text="Delete" onClick={onOKClick(modalData.options)} />
            </Footer>
        </MainWrapper>
    );
});

export default DeleteLayerModal;

const MainWrapper = styled.div`
    display: grid;
    grid-template-rows: auto 50px;
`;
const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;
