import React, { useState, useMemo, useContext, useEffect } from 'react';
import { PerspectiveContext } from '../perspective';

import CameraItem from './camera_item';

const CamerasList = ({ ...props }) => {
    const { camerasById, changedCamerasIds } = useContext(PerspectiveContext);
    const list = Object.keys(camerasById)
        .map((key) => camerasById[key])
        .filter((item) => {
            return item.perspective_profile.basic_perspective_geometry;
        })
        .sort((a, b) => b.id - a.id)
        .map((item, i) => {
            const { id, marker } = item;
            return <CameraItem item={item} changedCamerasIds={changedCamerasIds} key={marker} />;
        });

    return list;
};

export default CamerasList;
