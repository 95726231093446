import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal, deleteObject } from './MeasuredCoordObjectsLayerReducer';

import DeleteObjectModal from './modals/delete_object_modal';

const Modal = ({ show, ...props }) => {
    const dispatch = useDispatch();
    const { modal } = useSelector((state) => state.MeasuredCoordObjectsLayerReducer);

    useEffect(() => {
        if (!show) {
            dispatch(closeModal());
        }
    }, [show]);

    const _deleteObject = (args) => {
        dispatch(deleteObject(args));
        dispatch(closeModal());
    };

    const modalContent = useMemo(() => {
        switch (modal?.modalType) {
            case 'delete_object':
                return <DeleteObjectModal options={modal?.options} _onOKClick={_deleteObject} />;

            default:
                return null;
        }
    }, [modal]);

    if (modal === null) {
        return null;
    }

    return <div>{modalContent}</div>;
};

export default Modal;
