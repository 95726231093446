import React, { useState, useMemo, useRef, useEffect, useContext, useCallback } from 'react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { drawPolygon, drawCircle, drawLine } from '../../../../../tools/draw_tools';

import { Stage, Layer, Star, Text, Image, Line, Rect, Shape, Circle, Group } from 'react-konva';
import { cloneDeep } from 'lodash';

import { setObjectsById, selectObject, dragMoveObject } from './MeasuredCoordObjectsLayerReducer';

const MeasuredCoordObjectsLayer = ({
    objects,
    plan,
    scale,
    tool,
    isLayerSelected,
    visible,
    setStageDraggable,
    handleObjectsChange,
    setCursor,
    ...props
}) => {
    const [canCircleDrag, set_canCircleDrag] = useState(null);
    const { objectsById, selectedObject, visibleObjects } = useSelector((state) => state.MeasuredCoordObjectsLayerReducer);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const LAYER_TYPE = 'measuredCoordObjectsLayer';

    useEffect(() => {
        if (objects?.byId) {
            dispatch(setObjectsById({ objects: objects.byId }));
        }
    }, [objects?.src]);

    useEffect(() => {
        if (objectsById) {
            delayedHandleObjectsChange(objectsById);
        }
    }, [objectsById]);

    const delayedHandleObjectsChange = useCallback(
        _.debounce((objectsById) => {
            handleObjectsChange({ objectsById, layerType: LAYER_TYPE });
        }, 200),
        []
    );

    useEffect(() => {
        if (!isLayerSelected) {
            dispatch(selectObject({}));
        }
    }, [isLayerSelected]);

    const _onCircleClick = (args) => (e) => {
        if (!isLayerSelected) return;
        dispatch(selectObject({ ...args }));
    };

    const getCoords = (e) => {
        const stage = e.target.getStage();
        const p = stage.getPointerPosition();
        const x = stage.attrs.x ? p.x - stage.attrs.x : p.x;
        const y = stage.attrs.y ? p.y - stage.attrs.y : p.y;
        const scaleKoeff = scale.stageScale * plan.scale;
        return { x: x / scaleKoeff, y: y / scaleKoeff };
    };

    const _onCircleDragMove = (args) => (e) => {
        const { id, key, object } = args;
        if (selectedObject !== id) return;
        e.evt.preventDefault();
        const newCoords = getCoords(e);
        const newObjects = cloneDeep(objectsById);

        if (key === 'central-circle') {
            newObjects[id] = {
                ...object,
                x: newCoords.x,
                y: plan.height / plan.scale - newCoords.y,
            };
            dispatch(dragMoveObject({ newObjects }));
        } else {
        }
    };

    const _onCircleMouseOver = (args) => (e) => {
        const { id, key, object } = args;
        if (selectedObject !== id) return;
        e.evt.preventDefault();

        if (key === 'central-circle') {
            setCursor('move');
        } else {
        }
    };

    const _onCircleMouseOut = (args) => (e) => {
        const { id, key, object } = args;
        if (selectedObject !== id) return;
        e.evt.preventDefault();

        if (key === 'central-circle') {
            setCursor('auto');
        } else {
        }
    };

    const _onMouseMove = (e) => {
        e.evt.preventDefault();
        const newCoords = getCoords(e);
        const newObjects = cloneDeep(objectsById);
        // console.log('+++', selectedObject, canCircleDrag?.id);
        if (canCircleDrag === null || selectedObject !== canCircleDrag.id) return;
        const { id, key, object } = canCircleDrag;
        const delta = Math.sqrt(Math.pow(newCoords.x - object.x, 2) + Math.pow(plan.height / plan.scale - newCoords.y - object.y, 2));

        if (tool.id === 'scale') {
            if (key === 'x-circle') {
                newObjects[id] = {
                    ...object,
                    dx: delta,
                };
            } else if (key === 'y-circle') {
                newObjects[id] = {
                    ...object,
                    dy: delta,
                };
            }
        } else if (tool.id === 'rotate') {
            const azimuth = getAzimuth(newCoords.x, plan.height / plan.scale - newCoords.y, object.x, object.y);

            if (key === 'x-circle') {
                newObjects[id] = {
                    ...object,
                    azimuth: azimuth,
                };
            } else if (key === 'y-circle') {
                console.log('CCC');
                newObjects[id] = {
                    ...object,
                    azimuth: azimuth - Math.PI / 2,
                };
            }
        }
        dispatch(dragMoveObject({ newObjects }));
    };

    const getAzimuth = (x1, y1, x2, y2) => {
        return Math.atan2(y1 - y2, x1 - x2);
    };

    const _onMouseDown = (args) => (e) => {
        const { id, key, object } = args;
        set_canCircleDrag({ id, key, object });
        setStageDraggable(false);
    };

    const _onMouseUp = (e) => {
        set_canCircleDrag(() => null);
        setStageDraggable(true);
    };

    const selectedObj = _.chain(Object.keys(objectsById))
        .filter((id) => id === selectedObject)
        .value();

    const deselectedObjs = _.chain(Object.keys(objectsById))
        .filter((id) => id !== selectedObject)
        .value();

    const calibrationObjects = (list) => {
        if (!list || list.length === 0) return null;

        return _.chain(list)
            .filter((id) => visibleObjects[id])
            .map((id) => {
                const object = objectsById[id];
                const { x, y, azimuth, dx, dy, dz, color } = object;
                const centerX = x * plan.scale;
                const centerY = plan.height - y * plan.scale;

                const xX = Math.cos(azimuth) * dx * plan.scale + centerX;
                const xY = centerY - Math.sin(azimuth) * dx * plan.scale;

                const yX = Math.cos(azimuth + Math.PI / 2) * dy * plan.scale + centerX;
                const yY = centerY - Math.sin(azimuth + Math.PI / 2) * dy * plan.scale;

                const selected = selectedObject === id;
                const opacity = selected ? 1 : 0.5;

                const xLine = drawLine({
                    key: `y-line${id}`,
                    points: [centerX, centerY, xX, xY],
                    strokeColor: color,
                    strokeWidth: 2 / scale.stageScale,
                    opacity,
                    onMouseUp: _onMouseUp,
                });

                const yLine = drawLine({
                    key: `x-line${id}`,
                    points: [centerX, centerY, yX, yY],
                    strokeColor: color,
                    strokeWidth: 2 / scale.stageScale,
                    opacity,
                    onMouseUp: _onMouseUp,
                });

                const centralCircle = drawCircle({
                    x: centerX,
                    y: centerY,
                    key: `central-circle${id}`,
                    draggable: selected,
                    radius: 8 / scale.stageScale,
                    opacity,
                    color: 'white',
                    strokeColor: color,
                    strokeWidth: 4 / scale.stageScale,
                    onClick: _onCircleClick({ id, key: 'central-circle', object }),
                    onDragMove: _onCircleDragMove({ id, key: 'central-circle', object }),
                    onMouseOver: _onCircleMouseOver({ id, key: 'central-circle', object }),
                    onMouseOut: _onCircleMouseOut({ id, key: 'central-circle', object }),
                });

                const xCircle = drawCircle({
                    x: xX,
                    y: xY,
                    key: `x-circle${id}`,
                    draggable: false,
                    radius: 8 / scale.stageScale,
                    opacity,
                    color: 'white',
                    strokeColor: color,
                    strokeWidth: 4 / scale.stageScale,
                    onClick: _onCircleClick({ id, key: 'x-circle', object }),
                    onMouseDown: _onMouseDown({ id, key: 'x-circle', object }),
                    onMouseUp: _onMouseUp,
                    onMouseMove: _onMouseMove,
                });

                const yCircle = drawCircle({
                    x: yX,
                    y: yY,
                    key: `y-circle${id}`,
                    draggable: false,
                    radius: 8 / scale.stageScale,
                    opacity,
                    color: 'white',
                    strokeColor: color,
                    strokeWidth: 4 / scale.stageScale,
                    onClick: _onCircleClick({ id, key: 'y-circle', object }),
                    onMouseDown: _onMouseDown({ id, key: 'y-circle', object }),
                    onMouseUp: _onMouseUp,
                    onMouseMove: _onMouseMove,
                });

                return (
                    <Group key={id}>
                        {xLine}
                        {yLine}
                        {xCircle}
                        <Text
                            text="x"
                            opacity={opacity}
                            fill={color}
                            x={xX + 15 / scale.stageScale}
                            y={xY - 15 / scale.stageScale}
                            fontSize={15 / scale.stageScale}
                        />
                        {yCircle}
                        <Text
                            text="y"
                            opacity={opacity}
                            fill={color}
                            x={yX + 15 / scale.stageScale}
                            y={yY - 15 / scale.stageScale}
                            fontSize={15 / scale.stageScale}
                        />
                        {centralCircle}
                    </Group>
                );
            })
            .value();
    };

    const helperRect = () => {
        if (!plan) return null;
        return (
            <Layer key={`${LAYER_TYPE}-helper rect`}>
                <Rect
                    onMouseMove={_onMouseMove}
                    onMouseUp={_onMouseUp}
                    height={plan.height}
                    width={plan.width}
                    fill={'transparent'}
                    opacity={0}
                />
            </Layer>
        );
    };

    const deselectedGeometry = useMemo(() => {
        return <Layer key={`${LAYER_TYPE}-deselected`}>{calibrationObjects(deselectedObjs)}</Layer>;
    }, [JSON.stringify(deselectedObjs), isLayerSelected, scale, visibleObjects]);

    const selectedGeometry = <Layer key={`${LAYER_TYPE}-selected`}>{calibrationObjects(selectedObj)}</Layer>;

    return visible ? (
        <React.Fragment key={`${LAYER_TYPE}-++layer`}>
            {helperRect()}
            {deselectedGeometry}
            {selectedGeometry}
        </React.Fragment>
    ) : null;
};

export default MeasuredCoordObjectsLayer;
