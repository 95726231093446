import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Slider } from 'office-ui-fabric-react/lib/Slider';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { useTranslation } from 'react-i18next';
import { PerspectiveContext } from '../perspective';
import Accordion from '../../accordion';
import TitleComponent from './title_component';
import CameraImage from './camera_image';
import CP_Footer from './control_panel_footer';
import MainCP from '../../wrappers/main_cp';
import CamerasList from './cameras_list';

const ControlPanel = React.memo(({ layers, ...props }) => {
    const { objects: layersObjects, selectedObjectOptions } = layers;
    const { t } = useTranslation();
    const {
        CAMERAS_LAYER_TYPE,
        visiblePlanLayers,
        camerasById,
        selectedCamerasIds,
        selectedPlanLayer,
        activePlanId,
        plans,
        plansOptions,
        _planOptionsChange,
        _toggleLayerVisibility,
    } = useContext(PerspectiveContext);

    const selectedCamera = camerasById[selectedCamerasIds[0]];

    if (!plans || !activePlanId) return null;
    const plan = plans.filter((item) => item.id === activePlanId)[0];
    const planOpacity = plansOptions[activePlanId] && plansOptions[activePlanId].opacity ? plansOptions[activePlanId].opacity : 100;
    const planDarkness = plansOptions[activePlanId] && plansOptions[activePlanId].darkness ? plansOptions[activePlanId].darkness : 0;
    const planLightness = plansOptions[activePlanId] && plansOptions[activePlanId].lightness ? plansOptions[activePlanId].lightness : 0;

    const sliderValueFormat = (value) => `${value.toFixed(0)}%`;

    const planOptionsChange = (mode) => (value) => _planOptionsChange({ planId: plan.id, value, mode });

    const layersList = Object.keys(layersObjects).map((key) => {
        const isLayerSelected = selectedPlanLayer === key;
        const visible = visiblePlanLayers.includes(key);
        return (
            <Div
                eyeVisible={visible}
                color={'blue'}
                label={key}
                key={key}
                open={false}
                selected={isLayerSelected}
                title_сomponent={<TitleComponent name={key} color={'red'} id={key} />}
            >
                {layersObjects[key]({ plan, isLayerSelected })}
            </Div>
        );
    });

    const camerasList = [
        <Div
            eyeVisible={visiblePlanLayers.includes(CAMERAS_LAYER_TYPE)}
            color={'red'}
            label={t('Cameras')}
            key={CAMERAS_LAYER_TYPE}
            id={CAMERAS_LAYER_TYPE}
            open={true}
            selected={selectedPlanLayer === CAMERAS_LAYER_TYPE}
            title_сomponent={<TitleComponent name={t('Cameras')} color={'red'} id={CAMERAS_LAYER_TYPE} />}
        >
            <CamerasList />
        </Div>,
    ];

    const planOptions = [
        <Div
            eyeVisible={false}
            showSectionEye={false}
            color={'yellow'}
            label={'planOptions'}
            key={'planOptions'}
            id={'planOptions'}
            open={false}
            selected={false}
            title_сomponent={<TitleComponent name={t('Plan options')} color={'yellow'} />}
        >
            <PlanOptionsWrapper>
                <StyledSlider
                    label={t('Plan opacity')}
                    max={100}
                    valueFormat={sliderValueFormat}
                    value={planOpacity}
                    showValue
                    onChange={planOptionsChange('opacity')}
                />
                <StyledSlider
                    label={t('Plan darkness')}
                    max={100}
                    valueFormat={sliderValueFormat}
                    value={planDarkness}
                    showValue
                    onChange={planOptionsChange('darkness')}
                />
                <StyledSlider
                    label={t('Plan lightness')}
                    max={100}
                    valueFormat={sliderValueFormat}
                    value={planLightness}
                    showValue
                    onChange={planOptionsChange('lightness')}
                />
            </PlanOptionsWrapper>
        </Div>,
    ];

    const objectOptions = () => {
        if (selectedPlanLayer === CAMERAS_LAYER_TYPE) {
            return <CameraImage selectedCamera={selectedCamera} />;
        } else {
            const Componentas = selectedObjectOptions[selectedPlanLayer];
            return Componentas ? <Componentas /> : null;
        }
    };

    return (
        <MainCP>
            <Box>
                <ObjectOptionsWrapper>{objectOptions()}</ObjectOptionsWrapper>
                <Accordion allowMultipleOpen={false} onSectionEyeClick={_toggleLayerVisibility} showArrows={true}>
                    {[...planOptions, ...camerasList, ...layersList]}
                </Accordion>
            </Box>
            <CP_Footer />
        </MainCP>
    );
});

export default ControlPanel;

const ObjectOptionsWrapper = styled.div`
    height: 200px;
    width: 100%;
    overflow: hidden;
    border: 1px solid #aaa;
    flex-shrink: 0;
    margin-bottom: 5px;
`;

const PlanOptionsWrapper = styled.div`
    padding: 10px;
`;

const Box = styled.div`
    width: 100%;
    height: calc(100% - 50px);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const StyledSeparator = styled(Separator)`
    margin-top: ${(p) => p.margintop || 0};
    margin-bottom: ${(p) => p.marginbottom || 0};
    ::before {
        background-color: #aaa;
    }
    div {
        background-color: rgb(240, 242, 245);
    }
`;

const Div = styled.div`
    /* display: flex;
    flex-direction: column; */
    /* overflow: hidden; */
    /* height: 100%; */
`;
const StyledSlider = styled(Slider)`
    cursor: pointer;
    /* margin-top: 10px; */
`;
