import React, { useState, useMemo, useRef, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Stage, Layer, Star, Text, Image, Line, Rect, Shape, Circle, Group, Ellipse } from 'react-konva';
import { VideosContext } from '../videos';

import styled from 'styled-components/macro';

import ObjectLabel from './object_label';
import './react_player_style.css';

const Vector = ({ video, tracks, playerSize, currentVideoTime, ...props }) => {
    const { step } = useContext(VideosContext);
    const { t } = useTranslation();
    const stageRef = useRef(null);
    const [stageScale, setStageScale] = useState(1);
    const [objectLabel, setObjectLabel] = useState({ show: false });

    useEffect(() => {
        const stageScale = playerSize.width / video.params.size.width;
        setStageScale(() => stageScale);
    }, [playerSize, video]);

    const currentFrame = useMemo(() => {
        const result = Math.floor(currentVideoTime * video.params.fps - 0.1).toFixed(0);
        // console.log(currentVideoTime, ' ___ ', result);
        return result;
    }, [currentVideoTime, step, video.params.fps]);

    const onMouseOverHead =
        ({ track, headCoords }) =>
        (e) => {
            e.evt.preventDefault();
            setObjectLabel({ show: true, coords: headCoords, text: track.track_id });
        };

    const onMouseOutHead = (e) => {
        e.evt.preventDefault();
        setObjectLabel({ show: false });
    };

    const geometry = useMemo(() => {
        const { pyr } = video.params;
        const strokeColor = 'rgb(0,255,255)';
        const fillColor = 'rgba(0,255,255,0.2)';
        if (tracks?.[currentFrame]) {
            return tracks[currentFrame].map((track, i) => {
                try {
                    const headCoords = { x: track.head_p[0] / Math.pow(2, pyr), y: track.head_p[1] / Math.pow(2, pyr) };
                    const feetCoords = { x: track.feet_p[0] / Math.pow(2, pyr), y: track.feet_p[1] / Math.pow(2, pyr) };
                    return (
                        <React.Fragment key={`${track.track_id}__track${i}`}>
                            <Ellipse
                                x={feetCoords.x}
                                y={feetCoords.y}
                                radius={{ x: track.wscale / 20, y: track.wscale / 40 }}
                                fill={'transparent'}
                                stroke={strokeColor}
                                strokeWidth={2 / stageScale}
                            />
                            <Line
                                points={[headCoords.x, headCoords.y, feetCoords.x, feetCoords.y]}
                                stroke={strokeColor}
                                strokeWidth={2 / stageScale}
                            />
                            <Circle
                                x={headCoords.x}
                                y={headCoords.y}
                                radius={track.wscale / 20}
                                onMouseOver={onMouseOverHead({ track, headCoords })}
                                onMouseOut={onMouseOutHead}
                                fill={fillColor}
                                stroke={strokeColor}
                                strokeWidth={2 / stageScale}
                            />
                            {objectLabel.show && (
                                <ObjectLabel coords={objectLabel.coords} text={objectLabel.text} stageScale={stageScale} />
                            )}
                        </React.Fragment>
                    );
                } catch (error) {
                    // console.log(track);
                    return null;
                }
            });
        } else {
            return null;
        }
    }, [currentFrame, objectLabel.coords, objectLabel.show, objectLabel.text, stageScale, tracks, video.params, playerSize]);

    const text =
        currentFrame && Number(currentFrame) > 0 ? (
            <Text x={10} y={10} text={`fr: ${currentFrame}`} fontSize={16 / stageScale} fill={'cyan'} />
        ) : null;

    return (
        <Cont playerSize={playerSize}>
            <Stage
                ref={stageRef}
                width={playerSize.width}
                height={playerSize.height}
                draggable={false}
                // onMouseUp={onMouseUp}
                // onDblClick={_onStageDblClick}
                // onClick={deselectAll}
                // onMouseMove={_onMouseMove}
                // onWheel={_onStageWheel}
                scaleX={stageScale}
                scaleY={stageScale}
                // x={scale.stageX}
                // y={scale.stageY}
            >
                <Layer>{geometry}</Layer>
                <Layer>{text}</Layer>
            </Stage>
        </Cont>
    );
};

export default Vector;

const Cont = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${(p) => p.playerSize.width + 'px'};
    height: ${(p) => p.playerSize.height + 'px'};
`;
