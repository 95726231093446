import React, { useMemo, useState } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { useConstCallback } from '@uifabric/react-hooks';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const AddNewPlanModal = React.memo(({ modalData, ...props }) => {
    const { t } = useTranslation();
    const [planData, set_planData] = useState({ image: '', name: '', floor: '', measured_segment: { coords: [], length_m: '' } });
    const [theme] = useState(getTheme());

    const onTextFieldChange = (field) => (event, newValue) => {
        set_planData({ ...planData, [field]: event.target.type === 'number' ? Number(newValue) : newValue });
    };

    const onOKClick = (options) => () => {
        props._onOKClick(options);
    };

    const disabled = useMemo(() => {
        let result = false;
        if (planData.image === '' || planData.floor === '' || planData.name === '') {
            result = true;
        }
        return result;
    }, [planData]);

    const _onFileChange = (e) => {
        const file = [...e.target.files][0];
        if (file.size > 100 * 1024 * 1024) {
            alert(t('File too large'));
        } else {
            var URL = window.webkitURL || window.URL;
            var image = URL.createObjectURL(file);
            set_planData({ ...planData, image });
        }
    };

    const stackTokens = {
        childrenGap: 5,
        // padding: 10,
    };

    return (
        <MainWrapper>
            <input accept="image/*" onChange={_onFileChange} style={{ display: 'none' }} id="raised-button-file" type="file" />
            <LabelWrapper>
                <FileLabel className="FileLabel" htmlFor="raised-button-file">
                    <ChooseFile component="span" bgColor={theme.palette.themePrimary} hoverBgColor={theme.palette.themeDark}>
                        {t('Choose plan file')}
                    </ChooseFile>
                </FileLabel>
            </LabelWrapper>
            {planData?.image && <img src={planData.image}></img>}

            <Stack horizontal tokens={stackTokens}>
                <Stack.Item grow={10}>
                    <TextField value={planData.name} label={t('Plan name')} onChange={onTextFieldChange('name')} autoFocus />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField type={'number'} value={planData.floor} label={t('Floor')} onChange={onTextFieldChange('floor')} />
                </Stack.Item>
            </Stack>
            <Footer>
                <PrimaryButton text={t('Add')} disabled={disabled} onClick={onOKClick({ planData })} />
            </Footer>
        </MainWrapper>
    );
});

export default AddNewPlanModal;

const MainWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    img {
        object-fit: contain;
    }
`;

const Footer = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const FileLabel = styled.label`
    display: flex;
    align-items: center;
    flex-grow: 0;
    /* width: 100%; */
`;

const ChooseFile = styled.section`
    -webkit-tap-highlight-color: transparent;
    -webkit-box-direction: normal;
    background: transparent;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    text-overflow: ellipsis;
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 0px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding-left: 16px;
    height: 32px;
    background-color: ${(p) => p.bgColor};
    color: rgb(255, 255, 255);
    user-select: none;
    outline: transparent;
    /* border-width: 1px;
    border-style: solid;
    border-image: initial; */
    text-decoration: none;
    border-radius: 2px;
    font-family: inherit;
    flex-shrink: 1;
    :hover {
        background-color: ${(p) => p.hoverBgColor};
    }
`;
