import React, { useContext } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { useTranslation } from 'react-i18next';
import { PerspectiveContext } from '../perspective';
import { GlobalContext } from '../../../App';
import FooterCP from '../../wrappers/footer_cp';

const ControlPanelFooter = React.memo(() => {
    const { t } = useTranslation();

    const { activePlanId } = useContext(PerspectiveContext);
    const { _saveLayers } = useContext(GlobalContext);

    const onSaveLayersClick = () => {
        _saveLayers(activePlanId);
    };

    return (
        <FooterCP>
            <PrimaryButton text={t('Save layers')} onClick={onSaveLayersClick} allowDisabledFocus disabled={false} />
        </FooterCP>
    );
});

export default ControlPanelFooter;
