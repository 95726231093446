import React, { useContext } from 'react';

import { CorrectionContext } from '../correction';
import styled from 'styled-components/macro';
import Accordion from '../../accordion';
import TitleComponent from './title_component';
import ControlPanelFooter from './control_panel_footer';
import MainCP from '../../wrappers/main_cp';

const ControlPanel = React.memo(() => {
    const context = useContext(CorrectionContext);
    const { controlDictionary, activeGroup, _onSectionEyeClick, _onClipboardClick } = context;

    const data = context[activeGroup];

    const groupsList = Object.keys(controlDictionary)
        .filter((item) => controlDictionary[item].canDraw)
        .map((key) => {
            const { name, color } = controlDictionary[key];
            const selected = activeGroup === key;
            const disabled = activeGroup !== key;
            const eyeVisible = controlDictionary[key].visible;
            return (
                <Div
                    color={color}
                    eyeVisible={eyeVisible}
                    label={key}
                    key={key}
                    open={true}
                    selected={selected}
                    disabled={disabled}
                    data={data}
                    title_сomponent={<TitleComponent name={name} color={color} id={key} />}
                />
            );
        });

    return (
        <MainCP>
            <Accordion onSectionEyeClick={_onSectionEyeClick} onClipboardClick={_onClipboardClick} showClipBoard={true} showArrows={false}>
                {groupsList}
            </Accordion>
            <ControlPanelFooter />
        </MainCP>
    );
});

export default ControlPanel;

const Div = styled.div``;
