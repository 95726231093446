import { DateTime } from 'luxon';

export const getVideoParamsFromName = (name) => {
    let duration = 600;
    let startTimeStamp = 0;
    let size = { width: 800, height: 600 };
    let fps = 24;
    let pyr = 1;
    try {
        duration = Number(name.match(/(?<=ds)([\s\S]+?)(?=_)/i)[0]);
    } catch (error) {}
    try {
        startTimeStamp = DateTime.fromISO(name.match(/(?<=dt)([\s\S]+?)(?=_)/i)[0]).ts;
    } catch (error) {}
    try {
        fps = Number(name.match(/(?<=fps)([\s\S]+?)(?=_)/i)[0]);
    } catch (error) {}
    try {
        pyr = Number(name.match(/(?<=pyr)([\s\S]+?)(?=_)/i)[0]);
    } catch (error) {}
    try {
        const s = name
            .match(/(?<=size)([\s\S]+?)(?=\.)/i)[0]
            .split('x')
            .map((item) => Number(item));
        size = { width: s[0], height: s[1] };
    } catch (error) {}
    return { duration, fps, size, startTimeStamp, pyr };
};
