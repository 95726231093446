import { cloneDeep } from 'lodash';

class MatrixConverter {
    static convert = (x, y, z = 0, matrix) => {
        const c = matrix[Math.abs(z).toFixed(1)];
        const w = x * c[2][0] + y * c[2][1] + c[2][2];
        const px = (x * c[0][0] + y * c[0][1] + c[0][2]) / w;
        const py = (x * c[1][0] + y * c[1][1] + c[1][2]) / w;

        return [px, py];
    };
}
class PolygonSplitter {
    static split = (polygon) => {
        return polygon.map((point, i, arr) => {
            if (i === arr.length - 1) {
                return [point, arr[0]];
            } else {
                return [point, arr[i + 1]];
            }
        });
    };
}

class SpacePointsGenerator {
    static find = (points, dist = 0.2) => {
        let A = points[0];
        let B = points[1];
        let C = cloneDeep(B);
        const result = [];

        const getDistance = (point1, point2) => {
            return Math.sqrt(Math.pow(point2[0] - point1[0], 2) + Math.pow(point2[1] - point1[1], 2) + Math.pow(point2[2] - point1[2], 2));
        };

        function addVectors(v1, v2) {
            return v1.map((x, i) => x + v2[i]);
        }

        function scalarMultiply(v, c) {
            return v.map((x) => x * c);
        }

        function getLength(v) {
            return Math.hypot(...v);
        }

        while (getDistance(A, C) >= dist) {
            const vecB2A = addVectors(A, scalarMultiply(C, -1)); // Step 1
            const normB2A = scalarMultiply(vecB2A, 1 / getLength(vecB2A)); // Step 2
            const distB2A = scalarMultiply(normB2A, dist); // Step 3
            C = addVectors(C, distB2A); // Final step
            result.push(C);
        }

        result.unshift(B);
        result.push(A);

        return result;
    };
}
class PointTransform3dToImage {
    #pixelsOutside = 100;
    constructor(imageSize, matrix, point) {
        this.imageSize = imageSize;
        this.matrix = matrix;
        this.point = point;
    }

    getImagePoint = () => {
        const result = MatrixConverter.convert(...this.point, this.matrix);
        if (
            result[0] > -this.#pixelsOutside &&
            result[0] < this.imageSize.width + this.#pixelsOutside &&
            result[1] > -this.#pixelsOutside &&
            result[1] < this.imageSize.height + this.#pixelsOutside
        ) {
            return result;
        } else {
            return null;
        }
    };
}
class LineTransform3dToImage {
    #generatedSpacePoints;
    constructor(imageSize, matrix, points) {
        this.imageSize = imageSize;
        this.matrix = matrix;
        this.#generatedSpacePoints = SpacePointsGenerator.find(points);
    }

    getImageLine = () => {
        let arr = [];
        this.#generatedSpacePoints.forEach((spacePoint) => {
            const imagePoint = new PointTransform3dToImage(this.imageSize, this.matrix, spacePoint);
            arr.push(imagePoint.getImagePoint());
        });
        arr = arr.filter((item) => item !== null);
        return arr.length ? [arr[0], arr[arr.length - 1]] : [];
    };
}

class PolygonTransform3dToImage {
    #generatedLines;
    constructor(imageSize, matrix, points) {
        this.imageSize = imageSize;
        this.matrix = matrix;
        this.#generatedLines = PolygonSplitter.split(points);
    }

    getImagePolygon = () => {
        const round = (num, digits = 1) => Number(num.toFixed(digits));
        const imagePolygon = this.#generatedLines
            .map((line) => {
                const lineInstance = new LineTransform3dToImage(this.imageSize, this.matrix, line);
                return lineInstance.getImageLine().reverse();
            })
            .reduce((acc, item) => {
                return [...acc, ...item];
            }, [])
            .reduce((acc, item) => {
                if (acc.length && round(acc[acc.length - 1][0]) === round(item[0]) && round(acc[acc.length - 1][1]) === round(item[1])) {
                    return acc;
                } else {
                    return [...acc, item];
                }
            }, []);

        return imagePolygon;
    };
}

export class Transform3dToImage {
    #transformedGeometry;
    constructor(type, imageSize, matrix, data) {
        switch (type) {
            case 'point':
                this.#transformedGeometry = new PointTransform3dToImage(imageSize, matrix, data).getImagePoint();
                break;
            case 'line':
                this.#transformedGeometry = new LineTransform3dToImage(imageSize, matrix, data).getImageLine();
                break;
            case 'polygon':
                this.#transformedGeometry = new PolygonTransform3dToImage(imageSize, matrix, data).getImagePolygon();
                break;

            default:
                break;
        }
    }
    getImageGeometry = () => {
        return this.#transformedGeometry;
    };
}
