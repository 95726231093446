import React, { useState, useMemo, useRef, useEffect, useContext, useCallback } from 'react';
import Split from 'react-split';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import ReactPlayer from 'react-player';

import useImage from 'use-image';
import { Stage, Layer, Star, Text, Image, Line, Rect, Shape, Circle, Group } from 'react-konva';
import { generateId, handleScale, generateCurrentCoordsInPixels } from '../../../tools/tools';
import { drawPolygon, drawCircle, drawLine } from '../../../tools/draw_tools';
import { GlobalContext } from '../../../App';
import { VideosContext } from '../videos';

import styled from 'styled-components/macro';
import Dock from './dock';

const ImagePanel = ({ ...props }) => {
    const playerRef = useRef();
    const { plans } = useContext(VideosContext);
    const { windowSize } = useContext(GlobalContext);

    const { t } = useTranslation();
    const stageRef = useRef(null);
    const stageWrapperRef = useRef(null);
    const mainWrapperRef = useRef(null);
    const [cursor, set_cursor] = useState('auto');
    const [stageSize, set_stageSize] = useState({
        width: window.innerWidth - 346,
        height: window.innerHeight * 0.8,
    });

    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const [stageDraggable, set_stageDraggable] = useState(true);
    const [canTransform, set_canTransform] = useState(false);
    const [initialPos, set_initialPos] = useState(0);
    const [initialZoom, set_initialZoom] = useState(1);
    const [imagePanelSizes, set_imagePanelSizes] = useState(null);
    const [initialAzimuth, set_initialAzimuth] = useState(1);
    const [linesCoords, set_linesCoords] = useState(null);
    const [currentCoords, set_currentCoords] = useState({});
    const [splitSizes, set_splitSizes] = useState([]);

    const plan = plans[0];
    const [image] = useImage(plan?.image_url || plan?.image);

    useEffect(() => {
        playerRef?.current && console.log('>>>', playerRef.current.player.player);
    }, [playerRef?.current?.player?.player]);

    useEffect(() => {
        checkSize();
        window.addEventListener('resize', checkSize);
        return () => {
            window.removeEventListener('resize', checkSize);
        };
    }, []);

    useEffect(() => {
        if (stageWrapperRef.current) {
            set_imagePanelSizes({
                width: stageWrapperRef.current.offsetWidth,
                height: stageWrapperRef.current.offsetHeight,
            });
        }
    }, [stageWrapperRef, windowSize, splitSizes]);

    const checkSize = () => {
        const { offsetWidth: width, offsetHeight: height } = stageWrapperRef.current;
        set_stageSize({ width, height });
    };

    const onSplitDrag = (sizes) => {
        checkSize();
        set_splitSizes(() => sizes);
    };

    const generateCurrentCoords = (e) => {
        const p = e.currentTarget.getPointerPosition();
        const x = e.currentTarget.attrs.x ? p.x - e.currentTarget.attrs.x : p.x;
        const y = e.currentTarget.attrs.y ? p.y - e.currentTarget.attrs.y : p.y;
        return { x: x / scale.stageScale, y: y / scale.stageScale };
    };

    const _onMouseMove = (e) => {
        const { x, y } = generateCurrentCoords(e);
        // set_currentCoords({ x, y });
    };

    const _onStageDblClick = (e) => {
        e.evt.preventDefault();
        const stageScale =
            stageWrapperRef.current.clientWidth /
            e.currentTarget.children.filter((item) => item.attrs.id === 'imageLayer')[0].children[0].attrs.image.naturalWidth;
        set_scale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    };

    const _onStageWheel = (e) => {
        e.evt.preventDefault();
        set_scale(handleScale({ e, isWheel: true }));
    };

    const onMouseUp = (e) => {
        set_canTransform(false);
        set_stageDraggable(true);
    };

    const setStageDraggable = (isDraggable) => {
        set_stageDraggable(isDraggable);
    };
    const setCursor = (cursor) => {
        set_cursor(cursor);
    };

    const _onStageMouseUp = (e) => {
        e.evt.preventDefault();
        console.log('GGGG');
    };

    const grid = () => {
        if (!plan) return null;
        const grid = [];
        for (let i = 0; i <= plan.width; i = i + plan.scale) {
            grid.push(
                drawLine({
                    key: `grid_y${i}`,
                    points: [i, 0, i, plan.height],
                    strokeColor: '#555',
                    strokeWidth: 1 / scale.stageScale,
                    opacity: 0.2,
                })
            );
        }
        for (let i = plan.height; i > 0; i = i - plan.scale) {
            grid.push(
                drawLine({
                    key: `grid_x${i}`,
                    points: [0, i, plan.width, i],
                    strokeColor: '#555',
                    strokeWidth: 1 / scale.stageScale,
                    opacity: 0.2,
                })
            );
        }
        return grid;
    };

    return (
        <MainWrapper ref={mainWrapperRef} cursor={cursor}>
            <StyledSplit
                split_cursor={'col-resize'}
                onDrag={onSplitDrag}
                sizes={[60, 40]}
                minSize={100}
                expandToMin={false}
                gutterSize={10}
                gutterAlign="center"
                snapOffset={30}
                dragInterval={1}
                direction={'horizontal'}
                // cursor={split_cursor}
            >
                <StageWrapper ref={stageWrapperRef}>
                    {imagePanelSizes ? (
                        <Stage
                            ref={stageRef}
                            width={imagePanelSizes.width}
                            height={imagePanelSizes.height}
                            draggable={stageDraggable}
                            onMouseUp={onMouseUp}
                            onDblClick={_onStageDblClick}
                            // onClick={deselectAll}
                            onMouseMove={_onMouseMove}
                            onWheel={_onStageWheel}
                            scaleX={scale.stageScale}
                            scaleY={scale.stageScale}
                            x={scale.stageX}
                            y={scale.stageY}
                        >
                            <Layer id={'imageLayer'}>{image ? <Image opacity={1} image={image} /> : null}</Layer>
                            {/* <Layer id={'filtersLayer'}>
                                <Group>
                                    {darker()}
                                    {lighter()}
                                    {grid()}
                                </Group>
                            </Layer>

                            {allLayers()} */}
                        </Stage>
                    ) : null}
                </StageWrapper>
                <Dock splitSizes={splitSizes} />
            </StyledSplit>
        </MainWrapper>
    );
};

export default ImagePanel;

const StyledSplit = styled(Split)`
    height: 100%;
    display: flex;
    .gutter {
        :hover {
            cursor: ${(p) => p.split_cursor};
        }
    }
`;

const Cont = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`;
const PlayerWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;
    /* height: ${(p) => p.height + 'px'}; */
`;

const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const StageWrapper = styled.div`
    position: relative;
    background-color: rgb(231, 234, 237);
    width: 100%;
    overflow: hidden;
`;

const MainWrapper = styled.div`
    cursor: ${(p) => {
        return p.cursor;
    }};
    /* height: 100%; */
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    position: relative;
`;

const TextContent = styled.svg`
    text-rendering: optimizeSpeed;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    width: 100%;
    height: 100%;
`;
