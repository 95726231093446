import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { showModal } from '../../reducers/modalReducer';
import { LocationsWrapper, stackTokens } from './Locations.styles';
import List from './components/List/List';
import FilterInput from '../FilterInput/FilterInput';
import { theme } from '../../constants/theme';

/**
 * Отображает список локаций (реальные отчетные объекты, обладающие набором свойств.)
 */
const Locations = React.memo(() => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { locationsList } = useSelector((state) => state.locationsReducer);
    const { neutralLighterAlt, neutralDark } = theme.palette;

    const [filteredLocationsList, setFilteredLocationsList] = useState(locationsList);

    const updateFilteredArray = (filteredArray) => {
        setFilteredLocationsList(filteredArray);
    };

    const onAddNewLocationClick = () => {
        dispatch(showModal({ modalType: 'add_new_location', width: '60%', options: {} }));
    };

    return (
        <LocationsWrapper bgcolor={neutralLighterAlt} color={neutralDark}>
            <Stack horizontal tokens={stackTokens}>
                <PrimaryButton text={t('Add new location')} onClick={onAddNewLocationClick} allowDisabledFocus disabled={false} />
                <FilterInput inputArray={locationsList} updateFilteredArray={updateFilteredArray} matchField={'name'} />
            </Stack>
            {locationsList && locationsList.length !== 0 ? <List filteredLocationsList={filteredLocationsList} /> : null}
        </LocationsWrapper>
    );
});

export default Locations;
