import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import styled from 'styled-components/macro';

import { showModal } from '../../reducers/modalReducer';
import { delayedChangeCamerasById, resetCamerasById } from '../../reducers/camerasReducer';
import {
    toggleTool,
    setPlansOptionsFromLocalStorage,
    planOptionsChange,
    toggleCameraToDock,
    onCameraMarkerClick,
    toggleCameraVisible,
    manageChangedCamerasIds,
    selectPlanLayer,
    handleObjectsChange,
    toggleLayerVisibility,
    removeFromChangedCamerasIds,
} from '../../reducers/perspectiveReducer';
import ToolsPanel from './components/tools_panel';
import ControlPanel from './components/control_panel';
import ImagePanel from './components/image_panel';
import GeometryEditor from '../wrappers/geometry_editor_wrapper';
import { GlobalContext } from '../../App';
import useWindowFocus from '../../tools/useWindowFocus';

export const PerspectiveContext = React.createContext({});

const Perspective = React.memo((props) => {
    const windowFocused = useWindowFocus();
    const CAMERAS_LAYER_TYPE = 'camerasLayer';
    const [selectedList, set_selectedListState] = useState(null);
    const [isCamerasLayerSelected, set_isCamerasLayerSelected] = useState(true);
    const { activePlanId, activePlan } = useSelector((state) => state.generalSetup);
    const { plans } = useSelector((state) => state.plansReducer);
    const { camerasById, camerasById3D } = useSelector((state) => state.camerasReducer);
    const {
        currentPerspective,
        tool,
        plansOptions,
        dockIds,
        selectedCamerasIds,
        visibleCamerasIds,
        changedCamerasIds,
        planLayers,
        visiblePlanLayers,
        selectedMeasuredCoordObject,
        selectedPlanLayer,
    } = useSelector((state) => state.perspectiveReducer);
    const { layers, _getPlanLayers } = useContext(GlobalContext);

    const dispatch = useDispatch();

    useEffect(() => {
        if (windowFocused && activePlan && activePlanId) {
            _getPlanLayers({ planId: activePlanId, planFloor: activePlan.floor });
        }
    }, [windowFocused]);

    useEffect(() => {
        set_isCamerasLayerSelected(selectedPlanLayer === 'camerasLayer');
    }, [selectedPlanLayer]);

    useEffect(() => {
        if (!isCamerasLayerSelected) {
            dispatch(onCameraMarkerClick({}));
        }
    }, [isCamerasLayerSelected]);

    useEffect(() => {
        Object.keys(camerasById).forEach((key) => {
            const { id } = camerasById[key];
            if (visibleCamerasIds[id] === undefined) {
                dispatch(toggleCameraVisible(id));
            }
        });
    }, [camerasById]);

    const sliderValueFormat = (value) => `${value.toFixed(0)}%`;

    useEffect(() => {
        dispatch(setPlansOptionsFromLocalStorage({ plans, plansOptions: JSON.parse(localStorage.getItem('plansOptions')) }));
    }, []);

    useEffect(() => {
        if (Object.keys(plansOptions).length !== 0) {
            delayedQuery(plansOptions);
        }
    }, [plansOptions]);

    const delayedQuery = useCallback(
        debounce((plansOptions) => {
            localStorage.setItem('plansOptions', JSON.stringify(plansOptions));
        }, 500),
        []
    );

    const _toggleTool = (id) => {
        dispatch(toggleTool(id));
    };

    const _onCameraMarkerClick = (id) => {
        dispatch(onCameraMarkerClick({ id }));
    };
    const _selectPlanLayer = (id) => {
        dispatch(selectPlanLayer({ id }));
    };

    const _delayedChangeCamerasById = (selectedList) => {
        // console.log('>>>>>>');
        dispatch(delayedChangeCamerasById({ selectedList }));
    };

    const _manageChangedCamerasIds = () => {
        dispatch(manageChangedCamerasIds({ camerasById, camerasById3D }));
    };

    const _set_selectedListState = (selectedList) => {
        set_selectedListState(selectedList);
    };

    const _toggleCameraToDock = (id) => {
        dispatch(toggleCameraToDock({ id }));
    };

    const _planOptionsChange = (args) => {
        dispatch(planOptionsChange(args));
    };

    const _resetCameraClick = (id) => {
        dispatch(removeFromChangedCamerasIds({ id }));
        dispatch(resetCamerasById({ id }));
    };

    const _handleObjectsChange = (args) => {
        dispatch(handleObjectsChange(args));
    };

    const _toggleLayerVisibility = (id) => {
        dispatch(toggleLayerVisibility({ id }));
    };

    const _showBigCameraImage = (selectedCamera) => {
        dispatch(
            showModal({
                modalType: 'big_camera_image',
                width: '80%',
                options: { selectedCamera },
            })
        );
    };

    const _onClipboardClick = (options) => {
        dispatch(
            showModal({
                modalType: 'copy_to_clipboard',
                width: '80%',
                options,
            })
        );
    };

    const _onCameraEyeClick = (id) => {
        dispatch(toggleCameraVisible(id));
    };

    const perspectiveContextValue = {
        CAMERAS_LAYER_TYPE,
        isCamerasLayerSelected,
        planLayers,
        selectedPlanLayer,
        visiblePlanLayers,
        selectedMeasuredCoordObject,
        changedCamerasIds,
        selectedCamerasIds,
        visibleCamerasIds,
        dockIds,
        tool,
        activePlanId,
        plans,
        camerasById,
        camerasById3D,
        currentPerspective,
        plansOptions,
        selectedList,
        _onCameraEyeClick,
        _toggleTool,
        _onCameraMarkerClick,
        _showBigCameraImage,
        _planOptionsChange,
        _delayedChangeCamerasById,
        _set_selectedListState,
        _toggleCameraToDock,
        _manageChangedCamerasIds,
        _selectPlanLayer,
        _handleObjectsChange,
        _toggleLayerVisibility,
        _resetCameraClick,
        _onClipboardClick,
    };

    return (
        <PerspectiveContext.Provider value={perspectiveContextValue}>
            <GeometryEditor>
                <ToolsPanel />
                <ImagePanel layersDraw={layers.draw} />
                <ControlPanel layers={layers} />
            </GeometryEditor>
        </PerspectiveContext.Provider>
    );
});

export default Perspective;
