import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { createSlice } from '@reduxjs/toolkit';

import { getSelectedGroup, generateId, generateNameNumber, between } from '../tools/tools';
import { keyBy } from 'lodash';

const initialState = {
    planLayersMap: { measured_coord_objects_layer: 'measuredCoordObjectsLayer' },
    planLayers: {},
    selectedPlanLayer: 'camerasLayer',
    visiblePlanLayers: ['camerasLayer', 'pass_ways_layer'],
    selectedCamerasIds: [],
    changedCamerasIds: [],
    visibleCamerasIds: {},
    dockIds: [],
    tool: {
        id: null,
    },
    currentPerspective: null,
    plansOptions: {},
};

export const perspectiveReducer = createSlice({
    name: 'perspectiveReducer',
    initialState,

    reducers: {
        toggleCameraVisible: (state, action) => {
            const { visibleCamerasIds } = cloneDeep(state);
            const id = action.payload;
            if (!visibleCamerasIds[id]) {
                visibleCamerasIds[id] = true;
            } else {
                visibleCamerasIds[id] = false;
            }
            state.visibleCamerasIds = visibleCamerasIds;
        },

        toggleLayerVisibility: (state, action) => {
            const { id } = action.payload;
            const { visiblePlanLayers } = cloneDeep(state);
            let newVisiblePlanLayers = [];
            if (visiblePlanLayers.includes(id)) {
                newVisiblePlanLayers = visiblePlanLayers.filter((item) => item !== id);
            } else {
                visiblePlanLayers.push(id);
                newVisiblePlanLayers = visiblePlanLayers;
            }
            state.visiblePlanLayers = newVisiblePlanLayers;
        },

        onCameraMarkerClick: (state, action) => {
            const { id } = action.payload;
            const { selectedCamerasIds } = cloneDeep(state);
            if (id === null) {
                state.selectedCamerasIds = [];
            } else if (selectedCamerasIds.includes(id)) {
                return;
            } else {
                state.selectedCamerasIds = [id];
            }
            state.selectedMeasuredCoordObject = null;
        },

        selectPlanLayer: (state, action) => {
            const { id } = action.payload;
            state.selectedPlanLayer = id;
        },

        fillCurrentPerspective: (state, action) => {
            const { currentPerspective } = action.payload;
            state.currentPerspective = currentPerspective;
        },

        toggleTool: (state, action) => {
            state.tool = { ...state.tool, id: action.payload };
        },

        setPlansOptionsFromLocalStorage: (state, action) => {
            const { plans } = action.payload;
            const plansOptions = action.payload.plansOptions ? action.payload.plansOptions : {};

            plans.forEach((plan) => {
                if (!plansOptions[plan.id]) {
                    plansOptions[plan.id] = {};
                    plansOptions[plan.id].opacity = 100;
                } else if (plansOptions[plan.id] && !plansOptions[plan.id].opacity) {
                    plansOptions[plan.id].opacity = 100;
                }
            });
            state.plansOptions = plansOptions;
        },

        planOptionsChange: (state, action) => {
            const { planId, value, mode } = action.payload;
            const { plansOptions } = cloneDeep(state);
            const newPlansOptions = { ...plansOptions, [planId]: { ...plansOptions[planId], [mode]: value } };
            state.plansOptions = newPlansOptions;
        },

        toggleCameraToDock: (state, action) => {
            const { id } = action.payload;
            const { dockIds } = cloneDeep(state);
            if (!dockIds.includes(id)) {
                dockIds.push(id);
                state.dockIds = dockIds;
            } else {
                const newDockIds = dockIds.filter((item) => item !== id);
                state.dockIds = newDockIds;
            }
        },

        resetPerspectiveReducer: (state, action) => {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },

        getPlanLayers: (state, action) => {
            const { visiblePlanLayers, planLayersMap } = cloneDeep(state);
            const { data } = action.payload;
            const planLayers = {};

            Object.keys(data).forEach((key) => {
                if (planLayersMap[key] !== undefined) {
                    const byId = {};
                    const newData = data[key] ? data[key] : [];
                    newData.forEach((obj) => {
                        byId[obj._id] = obj;
                    });
                    planLayers[planLayersMap[key]] = { src: newData, byId };
                    visiblePlanLayers.push(planLayersMap[key]);
                }
            });

            state.planLayers = planLayers;
            state.visiblePlanLayers = visiblePlanLayers;
        },

        getMapsLayers: (state, action) => {
            const { data } = action.payload;
            const { planFloor } = action.payload.additionalParams;
            const { planLayers, visiblePlanLayers } = cloneDeep(state);
            const mapsLayers = data.filter((item) => item.floor === planFloor && visiblePlanLayers.includes(item.layer_type));
            const mapsLayersObj = {};
            mapsLayers.forEach((item) => {
                const byId = {};
                item.data.forEach((obj) => {
                    byId[obj.id] = obj;
                });
                mapsLayersObj[item.layer_type] = { src: item.data, byId };
            });
            console.log(mapsLayersObj);
            state.planLayers = { ...planLayers, ...mapsLayersObj };
        },

        handleObjectsChange: (state, action) => {
            const { objectsById, layerType } = action.payload;
            const { planLayers } = cloneDeep(state);
            const currentPlanLayer = planLayers[layerType];
            if (currentPlanLayer?.byId) {
                currentPlanLayer.byId = objectsById;
                state.planLayers = planLayers;
            }
        },

        manageChangedCamerasIds: (state, action) => {
            const { camerasById, camerasById3D } = cloneDeep(action.payload);
            let newChangedCamerasIds = [];

            Object.keys(camerasById).forEach((key) => {
                const { id } = camerasById[key];
                if (!isEqual(camerasById[id], camerasById3D[id])) {
                    newChangedCamerasIds.push(id);
                }
            });

            state.changedCamerasIds = newChangedCamerasIds;
        },

        removeFromChangedCamerasIds: (state, action) => {
            const { id } = action.payload;
            const changedCamerasIds = cloneDeep(state.changedCamerasIds).filter((item) => item !== id);
            state.changedCamerasIds = changedCamerasIds;
        },
    },
});

// export const manageChangedCamerasIds = () => (dispatch, getState) => {
//     const { perspectiveReducer, camerasReducer } = getState();
//     const { changedCamerasIds } = cloneDeep(perspectiveReducer);
//     const { camerasById, camerasById3D } = camerasReducer;
//     let newChangedCamerasIds = [];

//     Object.keys(camerasById).forEach((key) => {
//         const { id } = camerasById[key];
//         if (!isEqual(camerasById[id], camerasById3D[id])) {
//             newChangedCamerasIds.push(id);
//         }
//     });
//     dispatch(setChangedCamerasIds(newChangedCamerasIds));
// };

export const {
    getPlanLayers,
    getMapsLayers,
    fillCurrentPerspective,
    toggleTool,
    setPlansOptionsFromLocalStorage,
    planOptionsChange,
    toggleCameraToDock,
    onCameraMarkerClick,
    toggleCameraVisible,
    resetPerspectiveReducer,
    setChangedCamerasIds,
    selectPlanLayer,
    handleObjectsChange,
    toggleLayerVisibility,
    manageChangedCamerasIds,
    removeFromChangedCamerasIds,
} = perspectiveReducer.actions;

export default perspectiveReducer.reducer;
