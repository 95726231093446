import * as React from 'react';
import styled from 'styled-components/macro';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { useTranslation } from 'react-i18next';

const SpinnerComponent: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <MainWrapper>
            <Spinner label={t('Loading...')} ariaLive="assertive" labelPosition="top" size={SpinnerSize.large} />
        </MainWrapper>
    );
};

export default SpinnerComponent;

const MainWrapper = styled.section`
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
`;
