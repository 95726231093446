import React, { useState, useMemo, useRef, useEffect, useContext } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import Konva from 'konva';
import useImage from 'use-image';
import { Stage, Layer, Star, Text, Image, Line, Rect, Shape, Circle } from 'react-konva';
import { generateId, handleScale, generateCurrentCoordsInPixels } from '../../../tools/tools';
import { GlobalContext } from '../../../App';
import { CalibrationContext } from '../calibration';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { useConstCallback } from '@uifabric/react-hooks';

import styled from 'styled-components/macro';

const handleDragStart = (e) => {
    e.target.setAttrs({
        shadowOffset: {
            x: 3,
            y: 3,
        },
        scaleX: 1.1,
        scaleY: 1.1,
    });
};
const handleDragEnd = (e) => {
    e.target.to({
        duration: 0.5,
        easing: Konva.Easings.ElasticEaseOut,
        scaleX: 1,
        scaleY: 1,
        shadowOffsetX: 1,
        shadowOffsetY: 1,
    });
};

const ImagePanel = () => {
    const { t } = useTranslation();
    const stageRef = useRef(null);
    const layerRef = useRef(null);
    const mainWrapperRef = useRef(null);
    const [cursor, set_cursor] = useState('auto');
    const [contextMenu, set_contextMenu] = useState({ show: false });
    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const [linesCoords, set_linesCoords] = useState(null);
    const [shapes, set_shapes] = useState(null);
    const [currentCoords, set_currentCoords] = useState({});
    const [imagePanelSizes, set_imagePanelSizes] = useState(null);
    const {
        _toggleDraw,
        tool,
        activeGroup,
        controlDictionary,
        calibration_geometry,
        layersById,
        activeLayer,
        _onUpdateGeometry,
        _onCreateNewElement,
        _onDeleteElement,
        _showModal,
        _addAnchor,
        _onDeleteAnchor,
        currentCamera,
    } = useContext(CalibrationContext);

    const [image] = useImage(currentCamera?.image_profile?.corrected_image);

    const { deleteDrawingElementTrigger, deleteLastNodeTrigger, windowSize } = useContext(GlobalContext);
    useEffect(() => {
        if (deleteDrawingElementTrigger > 0) {
            set_linesCoords(null);
        }
    }, [deleteDrawingElementTrigger]);
    useEffect(() => {
        if (deleteLastNodeTrigger > 0 && linesCoords && linesCoords.length > 1) {
            const newLineCoords = [...linesCoords];
            newLineCoords.pop();
            set_linesCoords([...newLineCoords]);
        }
    }, [deleteLastNodeTrigger]);

    useEffect(() => {
        if (!tool.id) {
            set_cursor('auto');
        } else if (tool.id === 'zoom_in') {
            set_cursor('zoom-in');
        } else if (tool.id === 'zoom_out') {
            set_cursor('zoom-out');
        } else if (tool.id === 'text_edit') {
            set_cursor('text');
        } else if (controlDictionary[tool.id].canDraw) {
            set_cursor('crosshair');
        } else {
            set_cursor('auto');
        }
    }, [tool, controlDictionary]);

    useEffect(() => {
        // console.log(mainWrapperRef);
    }, [mainWrapperRef]);

    const _onMouseMove = (e) => {
        if (tool.id && controlDictionary[tool.id].canDraw) {
            const { x, y } = generateCurrentCoordsInPixels(e);
            set_currentCoords({ x, y });
        }
    };

    const _onStageDblClick = (e) => {
        if (tool.id === 'zoom_in' || tool.id === 'zoom_out' || !image) return;
        e.evt.preventDefault();
        const stageScale =
            mainWrapperRef.current.clientWidth /
            e.currentTarget.children.filter((item) => item.attrs.id === 'imageLayer')[0].children[0].attrs.image.naturalWidth;
        set_scale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    };

    const showModal = (modalType, point) => {
        _showModal({ modalType, width: '400px', options: { ...point } });
    };

    const _onTextClick = (pointId) => (e) => {
        if (tool.id === 'text_edit') {
            showModal('rename_anchor_point', { ...e.target.attrs, pointId, allAnchorPoints: calibration_geometry.anchor_points });
        }
    };

    const _onShapeContextMenu = (groupId, layerId, elementId, anchorIndex, pointsNum) => (e) => {
        e.evt.preventDefault();
        if (tool.id !== 'anchors_edit' || activeLayer !== layerId) {
            return;
        } else {
            const { x, y } = e.evt;
            const menuItems = [
                {
                    key: 'delete_el',
                    text: t('Delete element'),
                    onClick: () => {
                        _onDeleteElement({ groupId, layerId, elementId });
                    },
                },
            ];

            if (pointsNum > 2) {
                menuItems.unshift({
                    key: 'delete_anchor',
                    text: t('Delete anchor'),
                    onClick: () => {
                        _onDeleteAnchor({ groupId, layerId, elementId, anchorIndex });
                    },
                });
            }
            set_contextMenu({ show: true, cursor: { x, y }, menuItems });
        }
    };

    const _onShapeMouseOver = (layer, elementId) => (e) => {
        e.evt.preventDefault();
        if (activeLayer === layer.id && tool.id === 'anchors_edit') {
            set_cursor('crosshair');
            e.target.to({ strokeWidth: 4 / scale.stageScale });
        }
    };

    const _onShapeMouseOut = (layer, elementId) => (e) => {
        e.evt.preventDefault();
        if (activeLayer === layer.id && tool.id === 'anchors_edit') {
            set_cursor('auto');
            e.target.to({ strokeWidth: 2 / scale.stageScale });
        }
    };

    const _onStageWheel = (e) => {
        e.evt.preventDefault();
        set_scale(handleScale({ e, isWheel: true, tool }));
    };

    let numberOfElementsInLayer = 1000;

    if (tool.id && controlDictionary[tool.id].layer_elements_limit) {
        numberOfElementsInLayer = controlDictionary[tool.id].layer_elements_limit;
    }

    const _onStageClick = (e) => {
        if (!calibration_geometry[tool.id]) return;
        const { x, y } = generateCurrentCoordsInPixels(e);
        const newLinesCoords = linesCoords ? [...linesCoords] : [];
        const filtered = calibration_geometry[tool.id].filter((item) => item.id === activeLayer)[0];

        if (tool.id === 'zoom_in' || tool.id === 'zoom_out') {
            set_scale(handleScale({ e, isWheel: false, tool }));
        } else if (tool.id && controlDictionary[tool.id].canDraw && filtered?.elements.length >= numberOfElementsInLayer) {
            _toggleDraw(null);
            return;
        } else if (tool.id && controlDictionary[tool.id].canDraw && activeLayer) {
            const { layer_element } = controlDictionary[tool.id];
            if (layer_element === 'line' || layer_element === 'polygon' || layer_element === 'point') {
                newLinesCoords.push([x, y]);
                set_linesCoords(newLinesCoords);
            }
        }
    };

    const onAnchorMouseOver = (e) => {
        e.evt.preventDefault();
        // e.target.attrs.strokeWidth = 4;
        set_cursor('pointer');
    };
    const onAnchorMouseOut = (e) => {
        e.evt.preventDefault();
        // e.target.attrs.strokeWidth = 4;
        set_cursor('auto');
    };

    const onUpdateGeometry = (args) => (e) => {
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const p = stage.getPointerPosition();
        const x = stage.attrs.x ? p.x - stage.attrs.x : p.x;
        const y = stage.attrs.y ? p.y - stage.attrs.y : p.y;
        const newCoords = [x / scale.stageScale, y / scale.stageScale];
        _onUpdateGeometry({ ...args, newCoords });
    };

    const _onShapeClick = (groupId, layerId, elementId) => (e) => {
        e.evt.preventDefault();
        const p = stageRef.current.getPointerPosition();
        const x = stageRef.current.attrs.x ? p.x - stageRef.current.attrs.x : p.x;
        const y = stageRef.current.attrs.y ? p.y - stageRef.current.attrs.y : p.y;
        const coords = { x: x / scale.stageScale, y: y / scale.stageScale };
        // const { x, y } = generateCurrentCoords(e);
        _addAnchor({ anchorCoord: [coords.x, coords.y], groupId, layerId, elementId });
    };

    const geometryLayers = useMemo(() => {
        const result = { common: [], editable: [] };

        const pushToResult = (isLayerEditable, shapes, layerParams, addType) => {
            const wrapShapes = (shapes) => {
                return (
                    <Layer id={layerParams.layer.id} key={layerParams.layer.id} visible={layerParams.visible}>
                        {shapes}
                    </Layer>
                );
            };
            if (!isLayerEditable && addType === 'push') {
                result.common.push(wrapShapes(shapes));
            } else if (!isLayerEditable && addType === 'unshift') {
                result.common.unshift(wrapShapes(shapes));
            } else if (isLayerEditable && tool.id === 'anchors_edit') {
                shapes.push(anchors(layerParams.key, layerParams.layer, layerParams.layer_element));
                result.editable.push(wrapShapes(shapes));
            } else if (isLayerEditable) {
                result.editable.push(wrapShapes(shapes));
            }
        };

        const anchors = (groupId, layer, layer_element) => {
            const result = [];
            layer.elements.forEach((line) => {
                line.coords.forEach((point, i) => {
                    const pointsNum = line.coords.length;
                    result.push(
                        <Circle
                            id={`${line.id}:${i}`}
                            key={`${line.id}:${i}`}
                            draggable
                            onContextMenu={_onShapeContextMenu(groupId, layer.id, line.id, i, pointsNum)}
                            onMouseOver={onAnchorMouseOver}
                            onMouseOut={onAnchorMouseOut}
                            onDragMove={onUpdateGeometry({ groupId, layerId: layer.id, elementId: line.id, pointIndex: i })}
                            x={point[0]}
                            y={point[1]}
                            radius={10 / scale.stageScale}
                            // opacity={0.6}
                            // fill={'red'}
                            stroke={'#fff'}
                            strokeWidth={2 / scale.stageScale}
                        />
                    );
                });
            });

            return result;
        };

        Object.keys(calibration_geometry).forEach((key) => {
            const group = calibration_geometry[key];
            const { color, layer_element } = controlDictionary[key];
            group.forEach((layer) => {
                const isLayerEditable = layer.id === activeLayer;
                const visible = layersById[layer.id].visible;
                if (!visible) return null;
                if (layer_element === 'line') {
                    const shapes = layer.elements.map((line, i) => {
                        return (
                            <Shape
                                id={line.id}
                                key={line.id}
                                // onContextMenu={_onShapeContextMenu(layer, line.id)}
                                // onMouseOver={_onShapeMouseOver(layer, line.id)}
                                // onMouseOut={_onShapeMouseOut(layer, line.id)}
                                sceneFunc={(context, shape) => {
                                    context.beginPath();
                                    context.moveTo(...line.coords[0]);
                                    context.lineTo(...line.coords[1]);
                                    //   context.quadraticCurveTo(150, 100, 260, 170);
                                    //   context.closePath();
                                    context.fillStrokeShape(shape);
                                }}
                                // fill="#00D2FF"
                                stroke={color}
                                strokeWidth={2 / scale.stageScale}
                            />
                        );
                    });
                    pushToResult(isLayerEditable, shapes, { layer, visible, layer_element, key }, 'push');
                } else if (layer_element === 'point') {
                    const shapes = layer.elements.map((point, i) => {
                        return (
                            <Circle
                                id={`circle-${point.id}`}
                                key={`circle-${point.id}`}
                                // onContextMenu={_onShapeContextMenu(layer, point.id)}
                                onMouseOver={_onShapeMouseOver(layer)}
                                onMouseOut={_onShapeMouseOut(layer)}
                                x={point.coords[0][0]}
                                y={point.coords[0][1]}
                                radius={4 / scale.stageScale}
                                fill={color}
                            />
                        );
                    });
                    const pointNames = layer.elements.map((point, i) => {
                        return (
                            <Text
                                // id={`${point.id}`}
                                key={`text-${point.id}`}
                                onClick={_onTextClick(point.id)}
                                x={point.coords[0][0] + 8 / scale.stageScale}
                                y={point.coords[0][1] - 20 / scale.stageScale}
                                fontSize={20 / scale.stageScale}
                                text={point.name}
                                fill={color}
                            />
                        );
                    });
                    shapes.push(...pointNames);
                    pushToResult(isLayerEditable, shapes, { layer, visible, layer_element, key }, 'push');
                } else if (layer_element === 'polygon') {
                    const shapes = layer.elements.map((polygon, i) => {
                        return (
                            <Shape
                                id={polygon.id}
                                key={polygon.id}
                                // onContextMenu={_onShapeContextMenu(layer, polygon.id)}
                                onMouseOver={_onShapeMouseOver(layer)}
                                onMouseOut={_onShapeMouseOut(layer)}
                                onClick={_onShapeClick(key, layer.id, polygon.id)}
                                sceneFunc={(context, shape) => {
                                    context.beginPath();
                                    context.moveTo(...polygon.coords[0]);
                                    polygon.coords.forEach((coord, i) => {
                                        if (i > 0) {
                                            context.lineTo(...coord);
                                        }
                                    });
                                    //   context.quadraticCurveTo(150, 100, 260, 170);
                                    context.closePath();
                                    context.fillStrokeShape(shape);
                                }}
                                fill={color}
                                opacity={0.4}
                                stroke={color}
                                strokeWidth={2 / scale.stageScale}
                            />
                        );
                    });
                    pushToResult(isLayerEditable, shapes, { layer, visible, layer_element, key }, 'unshift');
                }
            });
        });

        return result;
    }, [calibration_geometry, scale, layersById, activeLayer, tool]);

    useEffect(() => {
        const shapes = [];
        if (!linesCoords || !activeLayer) {
        } else {
            const args = {
                groupId: activeGroup,
                layerId: activeLayer,
                elementId: `element:${activeGroup}:${generateId()}`,
            };

            const { layer_element } = controlDictionary[tool.id];

            if (layer_element === 'line') {
                if (linesCoords.length === 2) {
                    const newCoords = [];
                    newCoords.push(linesCoords[0], linesCoords[1]);
                    _onCreateNewElement({ ...args, newCoords });
                    set_linesCoords(null);
                    set_shapes(null);
                } else {
                    shapes.push(
                        <Shape
                            key={`shape`}
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(...linesCoords[0]);
                                context.lineTo(currentCoords.x, currentCoords.y);
                                //   context.quadraticCurveTo(150, 100, 260, 170);
                                //   context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            // fill="#00D2FF"
                            stroke="red"
                            strokeWidth={2 / scale.stageScale}
                        />
                    );
                }
            } else if (layer_element === 'polygon') {
                if (
                    linesCoords.length > 2 &&
                    Math.abs(linesCoords[linesCoords.length - 1][0] - linesCoords[0][0]) < 5 &&
                    Math.abs(linesCoords[linesCoords.length - 1][1] - linesCoords[0][1]) < 5
                ) {
                    const newCoords = [...linesCoords];
                    newCoords.pop();
                    _onCreateNewElement({ ...args, newCoords });
                    set_linesCoords(null);
                    set_shapes(null);
                    _toggleDraw(null);
                } else {
                    shapes.push(
                        <Shape
                            key={`shape`}
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(...linesCoords[0]);
                                linesCoords.forEach((coord, i) => {
                                    if (i > 0) {
                                        context.lineTo(...coord);
                                    }
                                });
                                context.lineTo(currentCoords.x, currentCoords.y);

                                //   context.quadraticCurveTo(150, 100, 260, 170);
                                //   context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            // fill="#00D2FF"
                            stroke="red"
                            strokeWidth={2 / scale.stageScale}
                        />
                    );

                    if (Math.abs(currentCoords.x - linesCoords[0][0]) < 5 && Math.abs(currentCoords.y - linesCoords[0][1]) < 5) {
                        shapes.push(
                            <Circle
                                id={`circle`}
                                key={`circle`}
                                // draggable
                                // onMouseOver={onAnchorMouseOver}
                                // onMouseOut={onAnchorMouseOut}
                                // onDragMove={onUpdateGeometry({ groupId, layerId: layer.id, elementId: line.id, pointIndex: i })}
                                x={linesCoords[0][0]}
                                y={linesCoords[0][1]}
                                radius={10 / scale.stageScale}
                                stroke={'#fff'}
                                strokeWidth={2 / scale.stageScale}
                            />
                        );
                    }
                }
            } else if (layer_element === 'point') {
                const newCoords = [...linesCoords];
                _onCreateNewElement({ ...args, newCoords });
                set_linesCoords(null);
                set_shapes(null);
                _toggleDraw(null);
            }
        }

        set_shapes(shapes);
    }, [currentCoords, linesCoords]);

    const onHideContextualMenu = useConstCallback(() => set_contextMenu({ show: false }));

    useEffect(() => {
        if (mainWrapperRef.current) {
            set_imagePanelSizes({ width: mainWrapperRef.current.offsetWidth, height: mainWrapperRef.current.offsetHeight });
        }
    }, [mainWrapperRef, windowSize]);

    return (
        <MainWrapper ref={mainWrapperRef} cursor={cursor}>
            <TextContent></TextContent>

            <ContextualMenu
                // styles={menuStyles}
                items={contextMenu.menuItems}
                hidden={!contextMenu.show}
                target={contextMenu.cursor}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />

            {imagePanelSizes ? (
                <Stage
                    ref={stageRef}
                    width={imagePanelSizes.width}
                    height={imagePanelSizes.height}
                    draggable
                    onMouseMove={_onMouseMove}
                    onClick={_onStageClick}
                    onDblClick={_onStageDblClick}
                    onWheel={_onStageWheel}
                    scaleX={scale.stageScale}
                    scaleY={scale.stageScale}
                    x={scale.stageX}
                    y={scale.stageY}
                >
                    <Layer id={'imageLayer'}>
                        {/* <Text text="Try to drag a star" /> */}
                        {image ? <Image image={image} /> : null}
                    </Layer>
                    {geometryLayers.common}
                    {geometryLayers.editable}
                    <Layer ref={layerRef} id={'11'} clearBeforeDraw>
                        {shapes}
                    </Layer>
                </Stage>
            ) : null}
        </MainWrapper>
    );
};

export default ImagePanel;

const MainWrapper = styled.div`
    cursor: ${(p) => p.cursor};
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
`;

const TextContent = styled.svg`
    text-rendering: optimizeSpeed;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    width: 100%;
    height: 100%;
`;
