import React, { useMemo, useEffect } from 'react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Layer, Group } from 'react-konva';
import { cloneDeep } from 'lodash';

import PassWay from './passway';

import { setObjectsById, selectObject } from './PassWaysLayerReducer';

const PassWaysLayer = ({
    objects,
    plan,
    scale,
    tool,
    isLayerSelected,
    visible,
    setStageDraggable,
    handleObjectsChange,
    setCursor,
    ...props
}) => {
    const { objectsById, selectedObject, visibleObjects } = useSelector((state) => state.PassWaysLayerReducer);

    // const { t } = useTranslation();
    const dispatch = useDispatch();
    const LAYER_TYPE = 'passWaysLayer';

    useEffect(() => {
        if (objects?.byId) {
            dispatch(setObjectsById({ objects: objects.byId }));
        }
    }, [objects?.src]);

    useEffect(() => {
        if (!isLayerSelected) {
            dispatch(selectObject({}));
        }
    }, [isLayerSelected]);

    const onSelectObjectClick = (args) => {
        if (!isLayerSelected) return;
        dispatch(selectObject({ ...args }));
    };

    const geometry = useMemo(() => {
        if (!objects || Object.keys(objects).length === 0 || !plan?.scale) return null;
        const arr = objects.src.map((passWay) => {
            try {
                const { passMargin, marker, id, passLine, name } = passWay;
                const selected = id === selectedObject;
                const args = {
                    coords: passLine.coordinates,
                    passMargin,
                    currentPlanData: plan,
                    stageScale: scale.stageScale,
                    key: id,
                    id,
                };
                return visibleObjects[id] ? <PassWay {...args} onClick={onSelectObjectClick} selected={selected} /> : null;
            } catch (error) {
                console.log('>>>>> ERROR in PassWay Draw');
                return null;
            }
        });

        return <Group>{arr}</Group>;
    }, [objects, plan, scale.stageScale, visibleObjects, selectedObject, isLayerSelected]);

    return visible ? <Layer key={`${LAYER_TYPE}-++layer`}>{geometry}</Layer> : null;
};

export default PassWaysLayer;
