import cloneDeep from 'lodash/cloneDeep';

import { createSlice } from '@reduxjs/toolkit';

import { getSelectedGroup, generateId, generateNameNumber, between } from '../tools/tools';

const initialState = {
    finishingStage1: false,
    correctedRawImage: null,
    activeGroup: null,
    dist_lines: {
        name: 'Distortion lines',
        elements: [],
    },
    vert_lines: {
        name: 'Vertical lines',
        elements: [],
    },
    tool: {
        id: null,
    },
    controlDictionary: {
        anchors_edit: {
            name: 'Anchors edit',
            icon: { iconName: 'RadioBtnOn', size: '16px' },
            canDraw: false,
        },
        dist_lines: {
            name: 'Distortion lines',
            icon: { iconName: 'PenWorkspace', size: '20px' },
            canDraw: true,
            elements_limit: null,
            element: 'line',
            color: 'yellow',
            visible: true,
        },
        vert_lines: {
            name: 'Vertical lines',
            icon: { iconName: 'PenWorkspace', size: '20px' },
            canDraw: true,
            elements_limit: null,
            element: 'line',
            color: 'blue',
            visible: true,
        },
        zoom_in: {
            name: 'Zoom in',
            icon: { iconName: 'ZoomIn', size: '20px' },
            canDraw: false,
        },
        zoom_out: {
            name: 'Zoom out',
            icon: { iconName: 'ZoomOut', size: '20px' },
            canDraw: false,
        },
    },
};

export const correctionReducer = createSlice({
    name: 'correctionReducer',

    initialState,

    reducers: {
        onUpdateGeometry: (state, action) => {
            const { groupId, elementId, pointIndex, newCoords } = action.payload;
            const newGeometry = cloneDeep(state[groupId]);
            const elements = newGeometry.elements.map((element) => {
                if (element.id !== elementId) {
                    return element;
                } else {
                    const coords = element.coords.map((point, i) => {
                        if (i !== pointIndex) {
                            return point;
                        } else {
                            return newCoords;
                        }
                    });
                    return { ...element, coords };
                }
            });

            newGeometry.elements = elements;

            state[groupId] = { ...newGeometry };
        },

        addAnchor: (state, action) => {
            const { anchorCoord, groupId, elementId } = action.payload;
            const newGeometry = cloneDeep(state[groupId]);

            const elements = newGeometry.elements.map((element) => {
                if (element.id !== elementId) {
                    return element;
                } else {
                    const coords = element.coords;
                    let index = null;
                    coords.forEach((point, i, arr) => {
                        if (i > 0) {
                            if (between(arr[i - 1], point, anchorCoord)) {
                                index = i;
                            }
                        }
                    });
                    if (index !== null) {
                        coords.splice(index, 0, anchorCoord);
                    }
                    return { ...element, coords };
                }
            });

            newGeometry.elements = elements;

            state[groupId] = { ...newGeometry };
        },

        onDeleteAnchor: (state, action) => {
            const { groupId, elementId, anchorIndex } = action.payload;
            const newGeometry = cloneDeep(state[groupId]);

            const elements = [];
            newGeometry.elements.forEach((element) => {
                if (element.id !== elementId) {
                    elements.push(element);
                } else if (element.id === elementId && element.coords.length > 2) {
                    const coords = element.coords;
                    coords.splice(anchorIndex, 1);
                    elements.push({ ...element, coords });
                }
            });
            newGeometry.elements = elements;
            state[groupId] = { ...newGeometry };
        },

        onCreateNewElement: (state, action) => {
            const { groupId, elementId, newCoords } = action.payload;
            const newGeometry = cloneDeep(state[groupId]);
            const elements = newGeometry.elements;
            const newElement = { id: elementId, coords: newCoords };
            elements.push(newElement);

            newGeometry.elements = elements;

            state[groupId] = { ...newGeometry };
        },

        onDeleteElement: (state, action) => {
            const { groupId, elementId } = action.payload;
            const newGeometry = cloneDeep(state[groupId]);
            const elements = newGeometry.elements.filter((item) => item.id !== elementId);
            newGeometry.elements = elements;
            state[groupId] = { ...newGeometry };
        },

        toggleDraw: (state, action) => {
            state.tool = { ...state.tool, id: action.payload };
        },

        onSectionEyeClick: (state, action) => {
            const { groupId } = action.payload;
            const { controlDictionary } = cloneDeep(state);
            controlDictionary[groupId].visible = !controlDictionary[groupId].visible;
            state.controlDictionary = controlDictionary;
        },

        inactiveGroupsVisibleOff: (state, action) => {
            const { activeGroup } = action.payload;
            const { controlDictionary } = cloneDeep(state);
            Object.keys(controlDictionary)
                .filter((key) => controlDictionary[key].canDraw)
                .forEach((key) => {
                    if (key !== activeGroup) {
                        controlDictionary[key].visible = false;
                    } else {
                        controlDictionary[key].visible = true;
                    }
                });
            state.controlDictionary = controlDictionary;
        },

        onGroupNameClick: (state, action) => {
            const { groupId } = action.payload;
            state.activeGroup = groupId;
        },

        getCorrectedRawImage: (state, action) => {
            const { blobUrl, additionalParams, formData } = action.payload;
            const correctedRawImage = { blobUrl, camera: { ...additionalParams, image_profile: formData } };
            state.correctedRawImage = correctedRawImage;
            if (additionalParams.calibration_stage === 'vert_lines') {
                state.activeGroup = 'vert_lines';
            } else if (additionalParams.calibration_stage === '1_to_2') {
                state.finishingStage1 = true;
            }
        },

        chooseCorrectionStep: (state, action) => {
            const { nextStep } = action.payload;
            const { dist_lines, vert_lines } = cloneDeep(state);
            if (nextStep === 'dist_lines') {
                console.log(action.payload);
                dist_lines.elements = action.payload.camera.image_profile.correction_dist_lines;
                state.dist_lines = dist_lines;
                vert_lines.elements = action.payload.camera.image_profile.correction_vertical_lines;
                state.vert_lines = vert_lines;
            } else {
                vert_lines.elements = action.payload.camera.image_profile.correction_vertical_lines;
                state.vert_lines = vert_lines;
            }
            state.activeGroup = nextStep;
        },

        cancelChanges: (state, action) => {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },

        manualEdit: (state, action) => {
            try {
                const { text, additionalOptions } = action.payload;
                const editedData = JSON.parse(text);
                state[additionalOptions.activeGroup] = editedData;
            } catch (error) {
                console.warn("Can't parse data!");
            }
        },
    },
});

export const {
    getCorrectedRawImage,
    cancelChanges,
    chooseCorrectionStep,
    toggleDraw,
    onUpdateGeometry,
    onCreateNewElement,
    onDeleteElement,
    onGroupNameClick,
    onSectionEyeClick,
    addAnchor,
    onDeleteAnchor,
    manualEdit,
    inactiveGroupsVisibleOff,
} = correctionReducer.actions;

export default correctionReducer.reducer;
