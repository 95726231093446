import React, { useState, useEffect, Suspense, useMemo } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import axios from 'axios';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { initializeIcons } from '@uifabric/icons';
import cookie from 'react-cookies';

//____________________________________________

import { changeLang, setWindowSize, setCameraInstallations, fillBackend, storeToken } from './reducers/generalSetup';
import { getPlans, resetPlanToEdit } from './reducers/plansReducer';
import { changeActiveLocationId, changeMsLocationId } from './reducers/locationsReducer';
import { getLocations } from './reducers/locationsReducer';
import { getCameras, changeCamerasById } from './reducers/camerasReducer';
import { getCorrectedRawImage } from './reducers/correctionReducer';
import { getPlanLayers, getMapsLayers } from './reducers/perspectiveReducer';
import { resetCalibrationReducer } from './reducers/calibrationReducer';
import { showModal, hideModal } from './reducers/modalReducer';

//____________________________________________

import DistorsionCorrection from './components/correction/correction';
import Videos from './components/videos/videos';
import Plans from './components/plans/plans';
import Locations from './components/Locations/Locations';
import Cameras from './components/cameras/cameras';
import Calibration from './components/calibration/calibration';
import Perspective from './components/perspective/perspective';
import Header from './components/header/header';
import Modal from './components/modal/modal';
import Spinner from './components/spinner/spinner';
import { parseQuery, useWindowSize, findToken, redirectToAuth } from './tools/tools';
import { getRequest, postRequest, doubleRequest } from './api';
import './normalize.css';
import './App.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { cloneDeep } from 'lodash';

import layers from './components/perspective/components/layers/layers';

import { urls } from './constants/urls';

initializeIcons();
export const GlobalContext = React.createContext({});

function App() {
    const [engineeringCount, setEngineeringCount] = useState(0);
    const [actualBackend, set_actualBackend] = useState(null);
    const [externalModal, set_externalModal] = useState(null);
    const [newElementTrigger, set_newElementTrigger] = useState(0);
    const [deleteDrawingElementTrigger, set_deleteDrawingElementTrigger] = useState(0);
    const [deleteLastNodeTrigger, set_deleteLastNodeTrigger] = useState(0);

    const windowDimensions = useWindowSize();
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const { lang, host, token, showSpinner, activePlanId, backend, windowSize, activePlan } = useSelector((state) => state.generalSetup);
    const correctionReducer = useSelector((state) => state.correctionReducer);
    const { planLayers, planLayersMap } = useSelector((state) => state.perspectiveReducer);
    const modalReducer = useSelector((state) => state.modalReducer);
    const { plans } = useSelector((state) => state.plansReducer);
    const { locationsList, msLocationId } = useSelector((state) => state.locationsReducer);
    const { camerasById } = useSelector((state) => state.camerasReducer);
    // const allState = useSelector((state) => state);
    const dispatch = useDispatch();

    useHotkeys('shift+d+o', () => setEngineeringCount((prev) => prev + 1));
    useHotkeys('n', () => set_newElementTrigger((prev) => prev + 1));
    useHotkeys('escape', () => set_deleteDrawingElementTrigger((prev) => prev + 1));
    useHotkeys('backspace', () => set_deleteLastNodeTrigger((prev) => prev + 1));

    useEffect(() => {
        const token = findToken();

        if (token) {
            dispatch(storeToken(token));
        } else {
            redirectToAuth();
        }
    }, []);

    useEffect(() => {
        const back = JSON.parse(localStorage.getItem('backend'));
        if (back !== null) {
            dispatch(fillBackend(back));
        }
    }, []);

    useEffect(() => {
        if (backend.checks && backend.checks.main) {
            set_actualBackend({ main: backend.main });
        } else {
            set_actualBackend({ main: host });
        }
        if (Object.keys(backend).length !== 0) {
            localStorage.setItem('backend', JSON.stringify(backend));
        }
        if (engineeringCount > 0) window.location.reload(false);
    }, [JSON.stringify(backend)]);

    useEffect(() => {
        if (!token) return;
        getRequest({ url: `${urls.LOCATIONS_URL}`, token, dispatch, actionName: getLocations });
    }, [token]);

    useEffect(() => {
        if (activePlanId) {
            getRequest({
                url: `${urls.CAMERAS_URL}/?plan_id=${activePlanId}`,
                token,
                dispatch,
                actionName: getCameras,
                additionalParams: plans,
            });
        } else {
            // history.push(`/`);
        }
    }, [activePlanId]);

    useEffect(() => {
        const userLang = navigator.language || navigator.userLanguage;
        if (userLang === 'ru' && !lang) {
            // dispatch(changeLang(userLang));
            dispatch(changeLang('en'));
        } else {
            dispatch(changeLang('en'));
        }
    }, []);

    useEffect(() => {
        dispatch(setWindowSize(windowDimensions));
    }, [windowDimensions]);

    const _createNewPlan = async (plan) => {
        const url = `${urls.PLANS_URL}/create_plan/`;

        await axios({
            method: 'get',
            url: plan.image_url || plan.image,
            responseType: 'blob',
        }).then((response) => {
            const file = new File([response.data], `${plan.name}.${response.data.type.replace('image/', '')}`, {
                type: response.data.type,
            });
            const formData = new FormData();
            formData.append('name', plan.name);
            formData.append('floor', Number(plan.floor));
            formData.append('measured_segment', JSON.stringify(plan.measured_segment));
            formData.append('image', file);
            postRequest({ url, token, dispatch, formData }).then(() => {
                getRequest({ url: `${urls.PLANS_URL}/`, token, dispatch, actionName: getPlans });
            });
            dispatch(resetPlanToEdit());
        });
    };

    const _updatePlan = async (plan) => {
        const url = `${urls.PLANS_URL}/update_plan/`;

        const formData = new FormData();
        formData.append('id', Number(plan.id));
        formData.append('name', plan.name);
        formData.append('floor', Number(plan.floor));
        formData.append('measured_segment', JSON.stringify(plan.measured_segment));

        postRequest({ url, token, dispatch, formData }).then(() => {
            getRequest({ url: `${urls.PLANS_URL}/`, token, dispatch, actionName: getPlans });
        });
        dispatch(resetPlanToEdit());
    };

    const _deletePlan = async ({ id }) => {
        const url = `${urls.PLANS_URL}/delete_plan/`;

        const formData = new FormData();
        formData.append('id', Number(id));

        await postRequest({ url, token, dispatch, formData }).then(() => {
            getRequest({ url: `${urls.PLANS_URL}/`, token, dispatch, actionName: getPlans });
        });
        dispatch(resetPlanToEdit());
    };

    const _deleteCamera = async ({ id }) => {
        const url = `${urls.CAMERAS_URL}/delete/`;

        const formData = new FormData();
        formData.append('ci_id', Number(id));

        postRequest({ url, token, dispatch, formData }).then(() => {
            getRequest({
                url: `${urls.CAMERAS_URL}/?plan_id=${activePlanId}`,
                token,
                dispatch,
                actionName: getCameras,
                additionalParams: plans,
            });
        });
    };

    const _onAddNewRawImageClick = async (args) => {
        const url = `${urls.CAMERAS_URL}/create/`;

        const formData = new FormData();
        formData.append('plan_id', Number(args.id));
        formData.append('camera_marker', args.camera_marker);
        formData.append('source_image', args.file);

        postRequest({ url, token, dispatch, formData }).then(() => {
            getRequest({
                url: `${urls.CAMERAS_URL}/?plan_id=${args.id}`,
                token,
                dispatch,
                actionName: getCameras,
                additionalParams: plans,
            });
        });
    };

    const _getPlanLayers = async ({ planId, planFloor }) => {
        const url = `${urls.PLANS_URL}/${planId}/layers/`;
        const mapsLayersUrl = `${urls.MAP_SERVICE_LAYERS_URL}?location_id=${msLocationId}`;

        await getRequest({
            url,
            token,
            dispatch,
            actionName: getPlanLayers,
        }).then(() => {
            getRequest({
                url: mapsLayersUrl,
                token,
                dispatch,
                actionName: getMapsLayers,
                additionalParams: { planFloor },
            });
        });
    };

    const _onShowRawImageClick = async (args) => {
        const url = `${urls.CALIBRATION_URL}/correct_camera_image/`;
        postRequest({
            url,
            token,
            dispatch,
            formData: args.image_profile,
            actionName: getCorrectedRawImage,
            additionalParams: { ...args, calibration_stage: 'dist_lines' },
            responseType: 'blob',
        });
    };

    const _onAddNewLocationModalClick = async (args) => {
        const url = urls.GET_OR_CREATE_URL;
        const formData = new FormData();
        formData.append('ms_host', args.ms_host);
        formData.append('ms_location_id', Number(args.ms_location_id));
        await postRequest({ url, token, formData, dispatch }).then(() => {
            getRequest({ url: `${urls.LOCATIONS_URL}`, token, dispatch, actionName: getLocations });
        });
    };

    const _onLocationClick = async (locationId) => {
        const [location] = locationsList.filter((item) => Number(item.id) === Number(locationId));
        const url = urls.GET_OR_CREATE_URL;
        dispatch(changeActiveLocationId(locationId));
        dispatch(changeMsLocationId(location.msLocationId));
        if (location.msHost && location.msLocationId) {
            const formData = new FormData();
            formData.append('ms_host', location.msHost);
            formData.append('ms_location_id', Number(location.msLocationId));
            await postRequest({ url, token, formData, dispatch }).then(() => {
                getRequest({ url: `${urls.LOCATIONS_URL}`, token, dispatch, actionName: getLocations });
                history.push(`/location/${locationId}`);
            });
        } else {
            getRequest({ url: `${urls.LOCATIONS_URL}`, token, dispatch, actionName: getLocations });
            history.push(`/location/${locationId}`);
        }
    };

    const _saveLayers = async (planId) => {
        const url = `${urls.PLANS_URL}/${planId}/layers/`;
        const formData = {};
        Object.keys(planLayersMap).forEach((alias) => {
            const layerData = [];
            const { byId } = planLayers[planLayersMap[alias]];
            Object.keys(byId).forEach((id) => {
                layerData.push(byId[id]);
            });
            formData[alias] = layerData;
        });

        await postRequest({
            url,
            token,
            dispatch,
            formData,
        }).then(() => {
            _getPlanLayers({ planId, planFloor: activePlan.floor });
        });
    };

    const _jumpToStage_2 = async (args) => {
        const url = `${urls.CAMERAS_URL}/set_image_profile/`;
        const data = cloneDeep(args.correctedRawImage.camera.image_profile);

        await postRequest({
            url,
            token,
            dispatch,
            formData: data,
        }).then(() => {
            getRequest({
                url: `${urls.CAMERAS_URL}/?plan_id=${activePlanId}`,
                token,
                dispatch,
                actionName: getCameras,
                additionalParams: plans,
            });
        });
        if (activePlanId) {
            history.push(`/cameras/${activePlanId}`);
        } else {
            history.push(`/`);
        }
    };

    const _savePerspectiveProfile = async (args) => {
        const url = `${urls.CAMERAS_URL}/set_perspective_profile/calibration_geometry/`;

        await postRequest({
            url,
            token,
            dispatch,
            formData: args,
        }).then(() => {
            getRequest({
                url: `${urls.CAMERAS_URL}/?plan_id=${activePlanId}`,
                token,
                dispatch,
                actionName: getCameras,
                additionalParams: plans,
            });
        });
        dispatch(resetCalibrationReducer());
        if (activePlanId) {
            history.push(`/cameras/${activePlanId}`);
        } else {
            history.push(`/`);
        }
    };

    const _saveFullPerspectiveGeometry = async (args) => {
        const url = `${urls.CAMERAS_URL}/set_perspective_profile/fixate_camera/`;

        await postRequest({
            url,
            token,
            dispatch,
            formData: args,
            actionName: changeCamerasById,
            additionalParams: camerasById,
            // showSpinner: false,
        });
        // .then(() => {
        //     getRequest({
        //         url: `${urls.CAMERAS_URL}/?plan_id=${activePlanId}`,
        //         token,
        //         dispatch,
        //         actionName: getCameras,
        //         additionalParams: plans,
        //         showSpinner: false,
        //     });
        // });
        // dispatch(resetCalibrationReducer());
        // if (activePlanId) {
        //     history.push(`/cameras/${activePlanId}`);
        // } else {
        //     history.push(`/`);
        // }
    };

    const _correctDistortion = async (activeGroup) => {
        const url = `${urls.CALIBRATION_URL}/complete_camera_image_profile/`;
        const url2 = `${urls.CALIBRATION_URL}/correct_camera_image/`;
        const data = { ...correctionReducer.correctedRawImage.camera.image_profile };
        if (activeGroup === 'dist_lines') {
            data.correction_dist_lines = correctionReducer[activeGroup].elements;
            data.correction_dist_coeffs = null;
            doubleRequest({
                url,
                url2,
                token,
                dispatch,
                data,
                actionName: getCorrectedRawImage,
                additionalParams: { ...correctionReducer.correctedRawImage.camera, image_profile: data, calibration_stage: 'vert_lines' },
            });
        } else if (activeGroup === 'vert_lines') {
            data.correction_vertical_lines = correctionReducer[activeGroup].elements;
            data.correction_roll_angle = null;
            doubleRequest({
                url,
                url2,
                token,
                dispatch,
                data,
                actionName: getCorrectedRawImage,
                additionalParams: { ...correctionReducer.correctedRawImage.camera, image_profile: data, calibration_stage: '1_to_2' },
            });
        }
    };

    useEffect(() => {
        if (correctionReducer?.correctedRawImage?.camera?.calibration_stage === 'dist_lines') {
            dispatch(
                showModal({
                    modalType: 'show_corrected_raw_image',
                    width: '80%',
                    options: { correctedRawImage: correctionReducer.correctedRawImage },
                })
            );
        }
    }, [correctionReducer.correctedRawImage]);

    useEffect(() => {
        if (!lang) return;
        i18n.changeLanguage(lang);
    }, [lang]);

    // useEffect(() => {
    //     const query = parseQuery(window.location.search);
    //     const xToken = query.token ? query.token : cookie.load('xtoken');
    //     const lang = query.lang || 'en';
    //     i18n.changeLanguage(lang);
    //     if (!xToken || xToken === 'undefined') {
    //         window.location.replace('https://sso.wifizone.me/accounts/login?next=' + document.location.href, '_blank');
    //     } else if (xToken) {
    //     }
    //     xToken && cookie.save('xtoken', xToken);
    //     window.history.pushState(null, null, window.location.href.split('?')[0]);
    // }, [i18n]);

    useEffect(() => {
        if (engineeringCount < 1) return;
        dispatch(
            showModal({
                modalType: 'dev_options',
                options: { backend },
            })
        );
    }, [engineeringCount]);

    const globalContextValue = {
        _saveLayers,
        _getPlanLayers,
        _saveFullPerspectiveGeometry,
        _savePerspectiveProfile,
        _jumpToStage_2,
        _correctDistortion,
        _onAddNewLocationModalClick,
        _onShowRawImageClick,
        _onAddNewRawImageClick,
        _deletePlan,
        _deleteCamera,
        _createNewPlan,
        _updatePlan,
        _onLocationClick,
        newElementTrigger,
        deleteDrawingElementTrigger,
        deleteLastNodeTrigger,
        windowSize,
        layers,
        token,
    };

    const customModals = Object.keys(layers.modals).map((key) => {
        return layers.modals[key]({ show: modalReducer.showExternal });
    });

    useEffect(() => {
        customModals.forEach((modal) => {
            if (modal) {
                set_externalModal(() => modal);
                dispatch(
                    showModal({
                        external: true,
                    })
                );
            } else {
                set_externalModal(() => null);
                dispatch(hideModal());
            }
        });
    }, [
        customModals.reduce((acc, item) => {
            return (acc += item?.props?.children?.type?.length);
        }, 0),
    ]);

    return (
        <GlobalContext.Provider value={globalContextValue}>
            <Suspense fallback="loading">
                {modalReducer.show && <Modal />}
                {modalReducer.showExternal && <Modal external>{externalModal}</Modal>}
                <StyledLayout>
                    <Header />
                    {showSpinner ? <Spinner /> : null}
                    <StyledLayoutContent>
                        <Switch>
                            <Route exact path="/" component={Locations} />
                            <Route path="/location/:locationId" component={Plans} />
                            <Route exact path="/cameras/:id" component={Cameras} />
                            <Route exact path="/correction" component={DistorsionCorrection} />
                            <Route exact path="/videos" component={Videos} />
                            <Route exact path="/calibration/:cameraId" component={Calibration} />
                            <Route exact path="/perspective" component={Perspective} />
                            <Route exact path="/">
                                <p>Home</p>
                            </Route>
                        </Switch>
                    </StyledLayoutContent>
                    {/* eslint-disable-next-line */}
                    <a id="downloadAnchor" style={{ display: 'none' }} download />
                </StyledLayout>
            </Suspense>
        </GlobalContext.Provider>
    );
}

export default App;

const StyledLayout = styled.section`
    height: 100vh;
    display: grid;
    grid-template-rows: 50px auto;
`;

const StyledLayoutContent = styled.div`
    height: calc(100vh - 50px);
`;
