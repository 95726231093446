import { cloneDeep } from 'lodash';

/**
 * Функция фильтрации списка объектов.
 * @param arr Входящий массив объектов.
 * @param fieldName Поле объекта, по которому фильтровать.
 * @param matchText Текст который должен содержаться в поле fieldName
 * @returns Возвращает отфильтрованный массив объектов.
 */
export const filterListOfObjects = (arr, fieldName, matchText) => {
    return matchText === ''
        ? cloneDeep(arr)
        : cloneDeep(arr).filter((item) => {
              if (typeof item[fieldName] === 'string') {
                  return String(item[fieldName]).toLowerCase().includes(matchText.toLowerCase());
              } else return true;
          });
};
