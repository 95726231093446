import { cloneDeep } from 'lodash';
import React from 'react';
import { Stage, Layer, Star, Text, Image, Line, Rect, Shape, Circle, Group } from 'react-konva';

const colorsArray = [
    '#0066FF',
    '#FF0000',
    '#FFFF00',
    '#00FF66',
    '#FF6600',
    '#CC00FF',
    '#00FFFF',
    '#f032e6',
    '#bcf60c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
];

export const getColorFromArray = ({ arr = colorsArray, index }) => {
    return arr[index] ? arr[index] : arr[Math.floor(Math.random() * (arr.length - 1))];
};

export const getRandomColor = () => {
    const arr = ['00', '66', 'FF'];
    const result = [1, 2, 3].reduce((acc, item) => {
        const value = arr[Math.floor(Math.random() * (arr.length - 1))];
        return acc + value;
    }, '#');
    return result;
};

export const drawPolygon = ({ polygon, color, key, opacity, strokeColor, strokeWidth, dash, ...props }) => {
    return (
        <Shape
            key={key || Math.random()}
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(...polygon[0]);
                polygon.forEach((coord, i) => {
                    if (i > 0) {
                        context.lineTo(...coord);
                    }
                });
                context.closePath();
                context.fillStrokeShape(shape);
            }}
            fill={color || 'yellow'}
            opacity={opacity || 1}
            stroke={strokeColor || color}
            dash={dash || []}
            strokeWidth={strokeWidth || 2}
            {...props}
        />
    );
};

export const drawCircle = ({ x, y, color, radius, key, draggable, opacity, strokeColor, strokeWidth, dash, ...props }) => {
    return (
        <Circle
            key={key || Math.random()}
            draggable={draggable || false}
            x={x}
            y={y}
            radius={radius || 10}
            fill={color || 'yellow'}
            opacity={opacity || 1}
            stroke={strokeColor || color}
            dash={dash || []}
            strokeWidth={strokeWidth || 2}
            {...props}
        />
    );
};

export const drawLine = ({ points, color, key, opacity, strokeColor, strokeWidth, dash, ...props }) => {
    return (
        <Line
            key={key || Math.random()}
            points={points}
            stroke={strokeColor || color}
            strokeWidth={strokeWidth || 2}
            dash={dash || []}
            opacity={opacity || 1}
            {...props}
        />
    );
};
