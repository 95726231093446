import { configureStore } from '@reduxjs/toolkit';
import generalSetup from './generalSetup';
import calibrationReducer from './calibrationReducer';
import perspectiveReducer from './perspectiveReducer';
import videosReducer from './videosReducer';
import correctionReducer from './correctionReducer';
import plansReducer from './plansReducer';
import locationsReducer from './locationsReducer';
import camerasReducer from './camerasReducer';
import modalReducer from './modalReducer';
import MeasuredCoordObjectsLayerReducer from '../components/perspective/components/layers/measured_coord_objects_layer/MeasuredCoordObjectsLayerReducer';
import PassWaysLayerReducer from '../components/perspective/components/layers/passways_layer/PassWaysLayerReducer';

export default configureStore({
    reducer: {
        generalSetup,
        calibrationReducer,
        correctionReducer,
        perspectiveReducer,
        modalReducer,
        plansReducer,
        videosReducer,
        locationsReducer,
        camerasReducer,
        
        MeasuredCoordObjectsLayerReducer,
        PassWaysLayerReducer,
    },
    middleware: [],
});
