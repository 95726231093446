import React from 'react';
import styled from 'styled-components/macro';

const HeaderWrapper = React.memo(({ children }) => {
    return <Container>{children} </Container>;
});

export default HeaderWrapper;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;
