import React, { useContext, useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

import Eye from '../../eye/eye';

export default function AccordionSection({
    disabled,
    selected,
    id,
    data,
    showArrows,
    title_сomponent,
    label,
    show = true,
    isOpen,
    children,
    showSectionEye = true,
    color,
    eyeVisible,
    additionalControls = null,
    showClipBoard = false,
    ...props
}) {
    const onSectionClick = () => {
        if (label) {
            props.onSectionClick(label);
        }
    };

    const iconClass = mergeStyles({
        fontSize: 16,
        height: 20,
        width: 20,
        margin: '0 25px',
    });
    const clopboardIconClass = mergeStyles({
        fontSize: 16,
        height: 20,
        width: 20,
        margin: '0 5px',
    });

    const onSectionEyeClick = (id) => {
        props.onSectionEyeClick(id);
    };

    const onClipboardClick = (data) => () => {
        props.onClipboardClick(data);
    };

    id = id ? id : label;

    const content = useMemo(() => {
        return <Content isOpen={isOpen}>{children}</Content>;
    }, [children, isOpen]);

    return show ? (
        <Container isOpen={isOpen}>
            <Title locationsBgColor={'#ddd'} disabled={disabled} selected={selected} color={color} onClick={onSectionClick}>
                {disabled && <DimOverlay />}
                {showSectionEye && <Eye eyeVisible={eyeVisible} id={id} onEyeClick={onSectionEyeClick} />}
                {title_сomponent}
                {showClipBoard && (
                    <ClipboardListWrapper>
                        <FontIcon iconName="ClipboardList" className={clopboardIconClass} onClick={onClipboardClick(data)} />
                    </ClipboardListWrapper>
                )}
                {showArrows && (
                    <IWrapper onClick={onSectionClick}>
                        {!isOpen && <FontIcon iconName="ChevronDown" className={iconClass} />}
                        {isOpen && <FontIcon iconName="ChevronUp" className={iconClass} />}
                    </IWrapper>
                )}
                {additionalControls}
            </Title>
            {/* {isOpen && <Content isOpen={isOpen}>{children}</Content>} */}
            {content}
        </Container>
    ) : null;
}

const ClipboardListWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: ${(props) => (props.isOpen ? '1' : '0')};
`;

const size = keyframes`
  0% { width: 0%; height: 0% }
  100% { width: 100%; height: 100% }
`;

const DimOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
    z-index: 100;
    animation: ${size} 0.3s;
`;

const animation = (p) => {
    const appearence = keyframes`
  0% { height: 0px }
  100% { height: 100% }
`;
    const disappearence = keyframes`
  0% { height: 0px }
  100% { height: 100% }
`;
    return p.isOpen
        ? css`
              ${appearence} 0.3s ease-in-out;
          `
        : css`
              ${disappearence} 0.3s ease-in-out;
          `;
};

const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transform: ${(p) => (p.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
    height: ${(p) => (p.isOpen ? '100%' : '0px')};
    overflow: auto;
    transform-origin: top;
    transition: transform 0.4s ease-out;
    /* max-height: 400px; */
    /* animation: ${animation}; */
`;

const Title = styled.div`
    border-left: ${(p) => `6px solid ${p.color}`};
    flex-shrink: 0;
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: 40px;
    margin-top: 1px;
    padding: 0 0 0 10px;
    background-color: ${(p) => (p.selected ? '#bbb' : p.locationsBgColor)};
`;

const IWrapper = styled.div`
    margin-left: auto;
    width: 30px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
