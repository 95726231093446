import React, { useState, useMemo, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHotkeys } from 'react-hotkeys-hook';
import Konva from 'konva';
import { Stage, Layer, Line, Shape, Circle } from 'react-konva';
import { generateId, handleScale } from '../../../tools/tools';
import { CorrectionContext } from '../correction';
import { GlobalContext } from '../../../App';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { useConstCallback } from '@uifabric/react-hooks';
import styled from 'styled-components/macro';

import URLImage from '../../url_image/url_image';

const ImagePanel = () => {
    const { t } = useTranslation();
    const stageRef = useRef(null);
    const layerRef = useRef(null);
    const mainWrapperRef = useRef(null);
    const [editingElement, set_editingElement] = useState(null);
    const [cursor, set_cursor] = useState('auto');
    const [contextMenu, set_contextMenu] = useState({ show: false });
    const [imagePanelSizes, set_imagePanelSizes] = useState(null);
    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const [linesCoords, set_linesCoords] = useState(null);
    const [shapes, set_shapes] = useState(null);
    const [currentCoords, set_currentCoords] = useState({});
    const context = useContext(CorrectionContext);
    const {
        finishingStage1,
        correctedRawImage,
        controlDictionary,
        tool,
        activeGroup,
        _onUpdateGeometry,
        _onCreateNewElement,
        _toggleDraw,
        _onDeleteElement,
        _addAnchor,
        _onDeleteAnchor,
    } = context;

    const { deleteDrawingElementTrigger, deleteLastNodeTrigger, windowSize } = useContext(GlobalContext);
    useEffect(() => {
        if (deleteDrawingElementTrigger > 0) {
            set_linesCoords(null);
        }
    }, [deleteDrawingElementTrigger]);
    useEffect(() => {
        if (deleteLastNodeTrigger > 0 && linesCoords && linesCoords.length > 1) {
            const newLineCoords = [...linesCoords];
            newLineCoords.pop();
            set_linesCoords([...newLineCoords]);
        }
    }, [deleteLastNodeTrigger]);

    useEffect(() => {
        if (mainWrapperRef.current) {
            set_imagePanelSizes({ width: mainWrapperRef.current.offsetWidth, height: mainWrapperRef.current.offsetHeight });
        }
    }, [mainWrapperRef, windowSize]);

    useEffect(() => {
        if (!tool.id) {
            set_cursor('auto');
        } else if (tool.id === 'zoom_in') {
            set_cursor('zoom-in');
        } else if (tool.id === 'zoom_out') {
            set_cursor('zoom-out');
        } else if (tool.id === 'text_edit') {
            set_cursor('text');
        } else if (controlDictionary[tool.id].canDraw) {
            set_cursor('crosshair');
        } else {
            set_cursor('auto');
        }
    }, [tool, controlDictionary]);

    const generateCurrentCoords = (e) => {
        const p = e.currentTarget.getPointerPosition();
        const x = e.currentTarget.attrs.x ? p.x - e.currentTarget.attrs.x : p.x;
        const y = e.currentTarget.attrs.y ? p.y - e.currentTarget.attrs.y : p.y;
        return { x: x / scale.stageScale, y: y / scale.stageScale };
    };

    const _onMouseMove = (e) => {
        if ((tool.id && controlDictionary[tool.id].canDraw) || tool.id === 'anchors_edit') {
            const { x, y } = generateCurrentCoords(e);
            set_currentCoords({ x, y });
        }
    };

    const _onStageDblClick = (e) => {
        if (tool.id === 'zoom_in' || tool.id === 'zoom_out') return;
        e.evt.preventDefault();
        const stageScale =
            mainWrapperRef.current.clientWidth /
            e.currentTarget.children.filter((item) => item.attrs.id === 'imageLayer')[0].children[0].attrs.image.naturalWidth;
        set_scale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    };

    const _onShapeContextMenu = (elementId, anchorIndex, pointsNum) => (e) => {
        e.evt.preventDefault();
        if (tool.id !== 'anchors_edit') {
            return;
        } else {
            const { x, y } = e.evt;
            const menuItems = [
                {
                    key: 'delete_el',
                    text: t('Delete element'),
                    onClick: () => {
                        _onDeleteElement({ groupId: activeGroup, elementId });
                    },
                },
            ];

            if (pointsNum > 2) {
                menuItems.unshift({
                    key: 'delete_anchor',
                    text: t('Delete anchor'),
                    onClick: () => {
                        _onDeleteAnchor({ groupId: activeGroup, elementId, anchorIndex });
                    },
                });
            }

            set_contextMenu({ show: true, cursor: { x, y }, menuItems });
        }
    };

    const _onShapeMouseOver = (groupId, elementId) => (e) => {
        e.evt.preventDefault();
        if (activeGroup === groupId && tool.id === 'anchors_edit') {
            set_cursor('crosshair');
            e.target.to({ strokeWidth: 4 / scale.stageScale });
            set_editingElement({ groupId, elementId });
        }
    };

    const _onShapeClick = (groupId, elementId) => (e) => {
        e.evt.preventDefault();
        const p = stageRef.current.getPointerPosition();
        const x = stageRef.current.attrs.x ? p.x - stageRef.current.attrs.x : p.x;
        const y = stageRef.current.attrs.y ? p.y - stageRef.current.attrs.y : p.y;
        const coords = { x: x / scale.stageScale, y: y / scale.stageScale };
        _addAnchor({ anchorCoord: [coords.x, coords.y], groupId, elementId });
    };

    const _onShapeMouseOut = (groupId) => (e) => {
        e.evt.preventDefault();
        if (activeGroup === groupId && tool.id === 'anchors_edit') {
            set_cursor('auto');
            e.target.to({ strokeWidth: 2 / scale.stageScale });
            set_editingElement(null);
        }
    };

    const _onStageWheel = (e) => {
        e.evt.preventDefault();
        set_scale(handleScale({ e, isWheel: true, tool }));
    };

    const _onStageClick = (e) => {
        const { x, y } = generateCurrentCoords(e);
        const newLinesCoords = linesCoords ? [...linesCoords] : [];

        if (tool.id === 'zoom_in') {
            set_scale(handleScale({ e, isWheel: false, tool }));
        } else if (tool.id === 'zoom_out') {
            set_scale(handleScale({ e, isWheel: false, tool }));
        } else if (tool.id && controlDictionary[tool.id].canDraw && activeGroup) {
            newLinesCoords.push([x, y]);
            set_linesCoords(newLinesCoords);
        } else if (tool.id === 'anchors_edit') {
        }
    };

    const onAnchorMouseOver = (e) => {
        e.evt.preventDefault();
        set_cursor('pointer');
    };
    const onAnchorMouseOut = (e) => {
        e.evt.preventDefault();
        set_cursor('auto');
    };

    const onUpdateGeometry = (args) => (e) => {
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const p = stage.getPointerPosition();
        const x = stage.attrs.x ? p.x - stage.attrs.x : p.x;
        const y = stage.attrs.y ? p.y - stage.attrs.y : p.y;
        const newCoords = [x / scale.stageScale, y / scale.stageScale];
        _onUpdateGeometry({ ...args, newCoords });
    };

    const geometryLayers = useMemo(() => {
        const result = { common: [], editable: [] };
        if (finishingStage1) return result;

        const pushToResult = (isGroupEditable, shapes, groupParams, addType) => {
            const wrapShapes = (shapes) => {
                return (
                    <Layer id={groupParams.key} key={groupParams.key} visible={groupParams.group.visible}>
                        {shapes}
                    </Layer>
                );
            };
            if (!isGroupEditable && addType === 'unshift') {
                result.common.unshift(wrapShapes(shapes));
            } else if (isGroupEditable && tool.id === 'anchors_edit') {
                shapes.push(anchors(groupParams.key, groupParams.group));
                result.editable.push(wrapShapes(shapes));
            } else if (isGroupEditable) {
                result.editable.push(wrapShapes(shapes));
            }
        };

        const anchors = (groupId, group) => {
            const result = [];
            group.elements.forEach((line) => {
                line.coords.forEach((point, i) => {
                    const pointsNum = line.coords.length;
                    result.push(
                        <Circle
                            id={`${line.id}:${i}`}
                            key={`${line.id}:${i}`}
                            draggable
                            onContextMenu={_onShapeContextMenu(line.id, i, pointsNum)}
                            onMouseOver={onAnchorMouseOver}
                            onMouseOut={onAnchorMouseOut}
                            onDragMove={onUpdateGeometry({ groupId, elementId: line.id, pointIndex: i })}
                            x={point[0]}
                            y={point[1]}
                            radius={10 / scale.stageScale}
                            // opacity={0.6}
                            // fill={'red'}
                            stroke={'#fff'}
                            strokeWidth={2 / scale.stageScale}
                        />
                    );
                });
            });

            return result;
        };

        Object.keys(controlDictionary)
            .filter((key) => controlDictionary[key].canDraw && controlDictionary[key].visible)
            .forEach((key) => {
                const isGroupEditable = key === activeGroup;
                const { color } = controlDictionary[key];
                const group = context[key];
                const shapes = group.elements.map((line, i) => {
                    const points = line.coords.reduce((acc, coord) => {
                        return [...acc, coord[0], coord[1]];
                    }, []);
                    return (
                        <Line
                            onMouseOver={_onShapeMouseOver(key, line.id)}
                            onMouseOut={_onShapeMouseOut(key, line.id)}
                            key={`lineOO${i}`}
                            // onContextMenu={_onShapeContextMenu(line.id)}
                            onClick={_onShapeClick(key, line.id)}
                            points={points}
                            stroke={color}
                            strokeWidth={2 / scale.stageScale}
                        />
                    );
                });
                pushToResult(isGroupEditable, shapes, { group, key }, 'unshift');
            });

        return result;
    }, [scale.stageScale, tool, context.dist_lines, context.vert_lines, controlDictionary, finishingStage1]);

    useEffect(() => {
        const shapes = [];
        if (!linesCoords || !activeGroup) {
        } else {
            const args = {
                groupId: activeGroup,
                elementId: `element:${activeGroup}:${generateId()}`,
            };

            if (
                activeGroup === 'dist_lines' &&
                linesCoords.length > 2 &&
                Math.abs(linesCoords[linesCoords.length - 1][0] - linesCoords[linesCoords.length - 2][0]) < 5 &&
                Math.abs(linesCoords[linesCoords.length - 1][1] - linesCoords[linesCoords.length - 2][1]) < 5
            ) {
                const newCoords = [...linesCoords];
                newCoords.pop();
                _onCreateNewElement({ ...args, newCoords });
                set_linesCoords(null);
                set_shapes(null);
                _toggleDraw(null);
            } else if (activeGroup === 'vert_lines' && linesCoords.length >= 2) {
                const newCoords = [...linesCoords];
                _onCreateNewElement({ ...args, newCoords });
                set_linesCoords(null);
                set_shapes(null);
                _toggleDraw(null);
            } else {
                shapes.push(
                    <Shape
                        key={`shape`}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(...linesCoords[0]);
                            linesCoords.forEach((coord, i) => {
                                if (i > 0) {
                                    context.lineTo(...coord);
                                }
                            });
                            context.lineTo(currentCoords.x, currentCoords.y);

                            context.fillStrokeShape(shape);
                        }}
                        // fill="#00D2FF"
                        stroke="red"
                        strokeWidth={2 / scale.stageScale}
                    />
                );

                if (
                    linesCoords.length > 2 &&
                    Math.abs(currentCoords.x - linesCoords[linesCoords.length - 1][0]) < 5 &&
                    Math.abs(currentCoords.y - linesCoords[linesCoords.length - 1][1]) < 5
                ) {
                    shapes.push(
                        <Circle
                            id={`circle`}
                            key={`circle`}
                            x={linesCoords[linesCoords.length - 1][0]}
                            y={linesCoords[linesCoords.length - 1][1]}
                            radius={10 / scale.stageScale}
                            stroke={'#fff'}
                            strokeWidth={2 / scale.stageScale}
                        />
                    );
                }
            }
        }
        set_shapes(shapes);
    }, [currentCoords, linesCoords]);

    const onHideContextualMenu = useConstCallback(() => set_contextMenu({ show: false }));

    if (!correctedRawImage) return <div></div>;

    return (
        <MainWrapper ref={mainWrapperRef} cursor={cursor}>
            <TextContent></TextContent>

            <ContextualMenu
                // styles={menuStyles}
                items={contextMenu.menuItems}
                hidden={!contextMenu.show}
                target={contextMenu.cursor}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />

            {imagePanelSizes ? (
                <Stage
                    ref={stageRef}
                    width={imagePanelSizes.width}
                    height={imagePanelSizes.height}
                    draggable
                    onMouseMove={_onMouseMove}
                    onClick={_onStageClick}
                    onDblClick={_onStageDblClick}
                    onWheel={_onStageWheel}
                    scaleX={scale.stageScale}
                    scaleY={scale.stageScale}
                    x={scale.stageX}
                    y={scale.stageY}
                >
                    <Layer id={'imageLayer'}>
                        <URLImage src={correctedRawImage.blobUrl} />
                    </Layer>
                    {geometryLayers.common}
                    {geometryLayers.editable}
                    <Layer ref={layerRef} id={'11'} clearBeforeDraw>
                        {shapes}
                    </Layer>
                </Stage>
            ) : null}
        </MainWrapper>
    );
};

export default ImagePanel;

const MainWrapper = styled.div`
    cursor: ${(p) => p.cursor};
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
`;

const TextContent = styled.svg`
    text-rendering: optimizeSpeed;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    width: 100%;
    height: 100%;
`;
