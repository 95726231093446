import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeLang, setActivePlanId } from '../../reducers/generalSetup';
import { setPlanToEdit } from '../../reducers/plansReducer';
import { resetPerspectiveReducer } from '../../reducers/perspectiveReducer';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useConstCallback } from '@uifabric/react-hooks';

const Header = React.memo(({ ...props }) => {
    const history = useHistory();

    const { t } = useTranslation();
    const { lang, activePlanId } = useSelector((state) => state.generalSetup);
    const { activeLocationId } = useSelector((state) => state.locationsReducer);
    const linkRef = React.useRef(null);
    const dispatch = useDispatch();
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const onShowContextualMenu = useConstCallback(() => setShowContextualMenu(true));
    const onHideContextualMenu = useConstCallback(() => setShowContextualMenu(false));

    const _changeLang = (lang) => {
        dispatch(changeLang(lang));
    };

    const itemStyles = {
        root: [
            {
                selectors: {
                    ':focus': {
                        outline: 'none',
                    },
                },
            },
        ],
        label: { fontSize: 14 },
    };

    const menuStyles = {
        root: { minWidth: 'auto', right: '0px' },
        subComponentStyles: { menuItem: itemStyles, callout: {} },
    };

    const menuItems = [
        {
            key: 'ru',
            text: 'ru',
            onClick: () => _changeLang('ru'),
        },
        {
            key: 'en',
            text: 'en',
            onClick: () => _changeLang('en'),
        },
    ];

    const onLocationsClick = () => {
        history.push(`/`);
        window.location.reload();
        // dispatch(setActivePlanId({ planId: null }));
        // dispatch(setPlanToEdit({ id: null }));
        // dispatch(resetPerspectiveReducer());
    };

    const onPlansClick = (locationId) => () => {
        // onLocationsClick();
        history.push(`/location/${locationId}`);
    };

    return (
        <HeaderContainer>
            <LeftSide>
                <StyledLink to="/" onClick={onLocationsClick}>
                    {t('Locations')}
                </StyledLink>
                {activePlanId && (
                    <StyledLink to={`/location/${activeLocationId}`} onClick={onPlansClick(activeLocationId)}>
                        {t('Plans')}
                    </StyledLink>
                )}
                <StyledLink to={`/videos`}>{t('Videos')}</StyledLink>
                {/* {activePlanId && <StyledLink to={`/cameras/${activePlanId}`}>{t('Cameras')}</StyledLink>} */}
            </LeftSide>
            <RightSide>
                <StContextualMenu
                    styles={menuStyles}
                    items={menuItems}
                    hidden={!showContextualMenu}
                    target={linkRef}
                    onItemClick={onHideContextualMenu}
                    onDismiss={onHideContextualMenu}
                />
                <Lang ref={linkRef} onClick={onShowContextualMenu}>
                    {lang}
                </Lang>
            </RightSide>
        </HeaderContainer>
    );
});

export default Header;

const HeaderContainer = styled.div`
    background-color: rgb(220, 222, 225);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
`;

const StyledLink = styled(Link)`
    padding: 0 10px 0 10px;
    color: black;
`;
const StContextualMenu = styled(ContextualMenu)`
    /* max-width: 20px !important; */
`;

const LeftSide = styled.div`
    cursor: pointer;
`;
const Lang = styled.div`
    cursor: pointer;
    padding: 0 10px 0 10px;
`;

const RightSide = styled.div``;
