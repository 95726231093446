import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { useTranslation } from 'react-i18next';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { GlobalContext } from '../../../App';
import { PerspectiveContext } from '../perspective';

import Eye from '../../eye/eye';
import SaveIcon from './save_icon';
import ResetIcon from './reset_icon';
import ToggleToDock from './toggle_to_dock';

const CameraItem = ({ item, changedCamerasIds, ...props }) => {
    const { t } = useTranslation();
    const { _saveFullPerspectiveGeometry } = useContext(GlobalContext);
    const {
        _onClipboardClick,
        _onCameraEyeClick,
        _onCameraMarkerClick,
        _resetCameraClick,
        visibleCamerasIds,
        selectedCamerasIds,
        isCamerasLayerSelected,
        dockIds,
        camerasById,
    } = useContext(PerspectiveContext);
    const { marker, id } = item;

    const clopboardIconClass = mergeStyles({
        fontSize: 16,
        height: 20,
        width: 20,
        margin: '0 5px',
    });

    const onSaveIconClick = (currentCamera) => () => {
        const { z_offset, angleh, x_offset, y_offset, id } = currentCamera?.perspective_profile;
        _saveFullPerspectiveGeometry({ z_offset, angleh, x_offset, y_offset, id, entity: 'PerspectiveProfile' });
    };

    const onResetIconClick = (currentCamera) => () => {
        const { id } = currentCamera?.perspective_profile;
        _resetCameraClick(id);
    };

    const onCameraClick = (id) => () => {
        _onCameraMarkerClick(id);
    };

    const onClipboardClick = (id) => () => {
        console.log(camerasById[id]);
        const data = camerasById[id].perspective_profile.calibration_geometry;
        const options = { data, additionalOptions: { canEdit: 'calibration_geometry', id } };
        _onClipboardClick(options);
    };

    const additionalControls = changedCamerasIds.includes(id)
        ? [
              <div key={`${marker}<<${id}`} onClick={onResetIconClick(item)}>
                  <TooltipHost content={t('Reset perspective geometry')}>
                      <ResetIcon />
                  </TooltipHost>
              </div>,
              <div key={`${marker}(())${id}`} onClick={onSaveIconClick(item)}>
                  <TooltipHost content={t('Save perspective geometry')}>
                      <SaveIcon />
                  </TooltipHost>
              </div>,
          ]
        : null;

    const bgcolor = selectedCamerasIds.includes(id) ? '#ccc' : '#eee';

    return (
        <ObjectWrapper bgcolor={bgcolor} key={id}>
            <Eye eyeVisible={visibleCamerasIds[id]} id={id} onEyeClick={_onCameraEyeClick} />
            <Camera disabled={!isCamerasLayerSelected} onClick={onCameraClick(id)}>
                <Marker>{marker}</Marker>
                <CiId>{`ci_id: ${id}`}</CiId>
            </Camera>
            <ToggleWrapper>
                <FontIcon iconName="ClipboardList" className={clopboardIconClass} onClick={onClipboardClick(id)} />
                <ToggleToDock id={id} dockIds={dockIds} />
            </ToggleWrapper>
            <AdditionalControlsWrapper>{additionalControls}</AdditionalControlsWrapper>
        </ObjectWrapper>
    );
};

export default CameraItem;

const Camera = styled.div`
    padding-left: 5px;
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: ${(p) => (p.disabled ? '0.4' : '1')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
`;
const Marker = styled.span``;
const CiId = styled.span`
    font-size: 10px;
    color: #999;
`;
const AdditionalControlsWrapper = styled.div`
    margin-left: auto;
    margin-right: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const ObjectWrapper = styled.div`
    margin-left: 6px;
    width: 100%;
    display: grid;
    grid-template-columns: 20px auto 80px 50px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background-color: ${(p) => p.bgcolor};
    padding-left: 10px;
`;
// const ObjectWrapper = styled.div`
//     margin-left: 6px;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.3);
//     background-color: ${(p) => p.bgcolor};
//     padding-left: 10px;
// `;
