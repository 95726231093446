import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNewObject, selectObject, toggleObjectVisible, showModal } from './MeasuredCoordObjectsLayerReducer';
import styled from 'styled-components/macro';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { useTranslation } from 'react-i18next';
import Eye from '../../../../eye/eye';

const ObjectsList = ({ plan, isLayerSelected, ...props }) => {
    const dispatch = useDispatch();
    const { objectsById = {}, selectedObject, visibleObjects } = useSelector((state) => state.MeasuredCoordObjectsLayerReducer);

    const onAddNewObjectClick = () => {
        dispatch(addNewObject({ plan }));
    };

    const onSelectObjectClick = (args) => () => {
        dispatch(selectObject({ ...args }));
    };

    const onEyeClick = (id) => {
        dispatch(toggleObjectVisible({ id }));
    };

    const onDeleteObjectClick = (options) => () => {
        dispatch(
            showModal({
                modalType: 'delete_object',
                width: '40%',
                options,
            })
        );
    };

    const { t } = useTranslation();

    const iconClass = mergeStyles({
        fontSize: 20,
        height: 20,
        width: 20,
        margin: '0 10px 0 0',
    });

    const deleteIconClass = mergeStyles({
        fontSize: 16,
        height: 16,
        width: 16,
        cursor: 'pointer',
        color: 'red',
    });

    const addObject = (
        <AddWrapper disabled={!isLayerSelected} bgcolor={'#eee'} key={'Add new object'} onClick={onAddNewObjectClick}>
            <FontIcon iconName="CircleAddition" className={iconClass} />
            <Main disabled={!isLayerSelected}>{t('Add new object')}</Main>
        </AddWrapper>
    );

    const layerObjects = Object.keys(objectsById).map((key) => {
        const obj = objectsById[key];
        const { _id: id, marker } = obj;
        const bgcolor = id === selectedObject ? '#ccc' : '#eee';
        return (
            // <ContextMenu key={obj.id} rightClick={true} menuItems={menuItems} margin={'0'} cursor={true}>
            <ObjectWrapper bgcolor={bgcolor} key={key}>
                <Eye eyeVisible={visibleObjects[id]} id={id} onEyeClick={onEyeClick} />
                <Main disabled={!isLayerSelected} onClick={onSelectObjectClick({ id })}>
                    {marker}
                </Main>
                <DeleteWrapper>
                    <FontIcon iconName="ErrorBadge" className={deleteIconClass} onClick={onDeleteObjectClick({ id, marker })} />
                </DeleteWrapper>
            </ObjectWrapper>
            // </ContextMenu>
        );
    });

    return <MainWrapper>{[addObject, ...layerObjects]}</MainWrapper>;
};

export default ObjectsList;

const DeleteWrapper = styled.div`
    margin-left: auto;
`;

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const Main = styled.div`
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    opacity: ${(p) => (p.disabled ? '0.4' : '1')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
`;
const ObjectWrapper = styled.div`
    margin-left: 6px;
    /* width: 100%; */
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background-color: ${(p) => p.bgcolor};
    padding: 0 10px;
`;
const AddWrapper = styled.div`
    margin-left: 6px;
    /* width: 100%; */
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background-color: ${(p) => p.bgcolor};
    padding: 0 10px;
    opacity: ${(p) => (p.disabled ? '0.4' : '1')};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
`;
