import React, { useMemo, useState, useEffect } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { useConstCallback } from '@uifabric/react-hooks';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const CopyToClipBoardModal = React.memo(({ modalData, ...props }) => {
    const { t } = useTranslation();
    const [value, set_value] = useState('');

    useEffect(() => {
        set_value(() => JSON.stringify(modalData.options.data, null, 4));
    }, []);

    const onOKClick = (options) => () => {
        props._onOKClick(options);
    };

    const onApplyChangesClick = (options) => () => {
        props._onApplyChangesClick(options);
    };

    const onChange = useConstCallback((event, newValue) => {
        if (modalData?.options?.additionalOptions?.canEdit) {
            set_value(() => newValue);
        }
    });

    const disabled =
        modalData?.options?.additionalOptions?.canEdit &&
        modalData?.options?.data &&
        JSON.stringify(modalData.options.data, null, 4) === value;

    return (
        <MainWrapper>
            <StTextField multiline rows={20} value={value} onChange={onChange} />

            <Footer>
                {modalData?.options?.additionalOptions?.canEdit && (
                    <StPrimaryButton
                        text={t('Apply changes')}
                        disabled={disabled}
                        onClick={onApplyChangesClick({ text: value, additionalOptions: modalData.options.additionalOptions })}
                    />
                )}
                <PrimaryButton text={t('Copy')} onClick={onOKClick({ text: value })} />
            </Footer>
        </MainWrapper>
    );
});

export default CopyToClipBoardModal;

const StTextField = styled(TextField)`
    /* margin-bottom: 40px; */
`;
const StPrimaryButton = styled(PrimaryButton)`
    margin-right: 10px;
`;

const MainWrapper = styled.div`
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Footer = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;
