import cloneDeep from 'lodash/cloneDeep';

import { createSlice } from '@reduxjs/toolkit';

import { getSelectedGroup, generateId, generateNameNumber, between } from '../tools/tools';

const initialState = {
    currentCamera: null,
    calibration_geometry: {
        parallel_lines: [
            {
                id: `layer:parallel_lines:${generateId()}`,
                name: 'Layer 1',
                elements: [],
            },
        ],
        vertical_lines: [
            {
                id: `layer:vertical_lines:${generateId()}`,
                name: 'Layer 1',
                elements: [],
            },
        ],
        areas: [
            {
                id: `layer:areas:${generateId()}`,
                name: 'Layer 1',
                elements: [],
            },
        ],
        anchor_points: [
            {
                id: `layer:anchor_points:${generateId()}`,
                name: 'Layer 1',
                elements: [],
            },
        ],
        equal_lines: [
            {
                id: `layer:equal_lines:${generateId()}`,
                name: 'Layer 1',
                meter_length: 1,
                elements: [],
            },
        ],
        perpendicular_lines: [
            {
                id: `layer:perpendicular_lines:${generateId()}`,
                name: 'Layer 1',
                elements: [],
            },
        ],
    },
    // camera_installations,
    tool: {
        id: null,
    },
    activeLayer: null,
    activeGroup: 'perpendicular_lines',
    layersById: null,
    controlDictionary: {
        anchors_edit: {
            name: 'Anchors edit',
            icon: { iconName: 'RadioBtnOn', size: '16px' },
            canDraw: false,
        },
        perpendicular_lines: {
            name: 'Perpendicular lines',
            icon: { iconName: 'PenWorkspace', size: '20px' },
            canDraw: true,
            layers_limit: null,
            layer_elements_limit: 2,
            layer_element: 'line',
            color: 'yellow',
            visible: true,
        },
        parallel_lines: {
            name: 'Parallel lines',
            icon: { iconName: 'PenWorkspace', size: '20px' },
            canDraw: true,
            layers_limit: null,
            layer_elements_limit: null,
            layer_element: 'line',
            color: 'red',
            visible: true,
        },
        vertical_lines: {
            name: 'Vertical lines',
            icon: { iconName: 'PenWorkspace', size: '20px' },
            canDraw: true,
            layers_limit: 1,
            layer_elements_limit: null,
            layer_element: 'line',
            color: 'blue',
            visible: true,
        },
        areas: {
            name: 'Areas',
            icon: { iconName: 'PenWorkspace', size: '20px' },
            canDraw: true,
            layers_limit: null,
            layer_elements_limit: 1,
            layer_element: 'polygon',
            color: 'yellow',
            visible: true,
        },
        anchor_points: {
            name: 'Anchor points',
            icon: { iconName: 'PenWorkspace', size: '20px' },
            canDraw: true,
            layers_limit: 1,
            layer_elements_limit: null,
            layer_element: 'point',
            color: '#00D2FF',
            visible: true,
        },
        equal_lines: {
            name: 'Equal lines',
            icon: { iconName: 'PenWorkspace', size: '20px' },
            canDraw: true,
            layers_limit: null,
            layer_elements_limit: null,
            layer_element: 'line',
            color: 'lime',
            visible: true,
        },
        zoom_in: {
            name: 'Zoom in',
            icon: { iconName: 'ZoomIn', size: '20px' },
            canDraw: false,
        },
        zoom_out: {
            name: 'Zoom out',
            icon: { iconName: 'ZoomOut', size: '20px' },
            canDraw: false,
        },
        text_edit: {
            name: 'Text edit',
            icon: { iconName: 'FontSize', size: '20px' },
            canDraw: false,
        },
    },
};

export const calibrationReducer = createSlice({
    name: 'calibrationReducer',
    initialState,

    reducers: {
        resetCalibrationReducer: (state, action) => {
            const { tool, currentCamera, calibration_geometry, activeLayer, activeGroup, layersById } = initialState;
            state.currentCamera = currentCamera;
            state.tool = tool;
            state.calibration_geometry = calibration_geometry;
            state.activeLayer = activeLayer;
            state.activeGroup = activeGroup;
            state.layersById = layersById;
        },

        fillCurrentCamera: (state, action) => {
            const { controlDictionary } = cloneDeep(state);
            const { currentCamera } = action.payload;
            // console.log(currentCamera);
            const layersById = {};

            state.layersById = layersById;
            state.currentCamera = currentCamera;
            const fillLayersById = (calibration_geometry) => {
                Object.keys(controlDictionary).forEach((key) => {
                    if (calibration_geometry[key]) {
                        calibration_geometry[key].forEach((item) => {
                            layersById[item.id] = { visible: true, groupId: key };
                        });
                    }
                });
            };
            if (currentCamera?.perspective_profile?.calibration_geometry) {
                const { calibration_geometry } = currentCamera.perspective_profile;
                state.calibration_geometry = calibration_geometry;
                fillLayersById(calibration_geometry);
            } else {
                const { calibration_geometry } = cloneDeep(state);
                fillLayersById(calibration_geometry);
            }
        },

        onUpdateGeometry: (state, action) => {
            const { groupId, layerId, elementId, pointIndex, newCoords } = action.payload;
            const { calibration_geometry } = cloneDeep(state);
            const group = calibration_geometry[groupId].map((layer) => {
                if (layer.id !== layerId) {
                    return layer;
                } else {
                    const elements = layer.elements.map((element) => {
                        if (element.id !== elementId) {
                            return element;
                        } else {
                            const coords = element.coords.map((point, i) => {
                                if (i !== pointIndex) {
                                    return point;
                                } else {
                                    return newCoords;
                                }
                            });
                            return { ...element, coords };
                        }
                    });
                    return { ...layer, elements };
                }
            });

            state.calibration_geometry = { ...calibration_geometry, [groupId]: group };
        },

        onCreateNewElement: (state, action) => {
            const { groupId, layerId, elementId, newCoords } = action.payload;
            const { calibration_geometry, controlDictionary } = cloneDeep(state);
            const group = calibration_geometry[groupId].map((layer) => {
                if (layer.id !== layerId) {
                    return layer;
                } else {
                    const elements = layer.elements;
                    const newElement = { id: elementId, coords: newCoords };

                    if (controlDictionary[groupId].layer_element === 'point') {
                        const { elements } = calibration_geometry[groupId][0];
                        newElement.name = generateNameNumber(elements, 'Point');
                    }

                    elements.push(newElement);
                    return { ...layer, elements };
                }
            });

            state.calibration_geometry = { ...calibration_geometry, [groupId]: group };
        },

        onDeleteElement: (state, action) => {
            const { groupId, layerId, elementId } = action.payload;
            const { calibration_geometry, controlDictionary } = cloneDeep(state);
            const group = calibration_geometry[groupId].map((layer) => {
                if (layer.id !== layerId) {
                    return layer;
                } else {
                    const elements = layer.elements.filter((item) => item.id !== elementId);
                    return { ...layer, elements };
                }
            });

            state.calibration_geometry = { ...calibration_geometry, [groupId]: group };
        },

        toggleDraw: (state, action) => {
            state.tool = { ...state.tool, id: action.payload };
        },

        onEyeClick: (state, action) => {
            const { layerId } = action.payload;
            const { layersById, controlDictionary } = cloneDeep(state);
            const currentLayerVisibility = layersById[layerId].visible;
            const currentGroupId = layersById[layerId].groupId;
            const currentGroupVisibility = controlDictionary[currentGroupId].visible;
            if (!currentGroupVisibility && !currentLayerVisibility) {
                controlDictionary[currentGroupId].visible = true;
                state.controlDictionary = controlDictionary;
            }
            layersById[layerId] = { ...layersById[layerId], visible: !currentLayerVisibility };
            state.layersById = layersById;
        },

        onSectionEyeClick: (state, action) => {
            const { groupId } = action.payload;
            const { layersById, controlDictionary } = cloneDeep(state);

            const newGroupVisibility = !controlDictionary[groupId].visible;

            controlDictionary[groupId].visible = newGroupVisibility;

            Object.keys(layersById).forEach((key) => {
                if (layersById[key].groupId === groupId) {
                    layersById[key].visible = newGroupVisibility;
                }
            });

            state.controlDictionary = controlDictionary;
            state.layersById = layersById;
        },

        onAddLayerClick: (state, action) => {
            const { activeGroup, calibration_geometry, layersById } = cloneDeep(state);
            let activeLayer;
            let group = [];
            if (!activeGroup) {
                return;
            } else {
                group = calibration_geometry[activeGroup];
                const id = `layer:${activeGroup}:${generateId()}`;
                const name = generateNameNumber(group, 'Layer');
                group.push({ id, name, elements: [] });
                layersById[id] = { visible: true, groupId: activeGroup };
                activeLayer = id;
            }

            state.calibration_geometry = { ...calibration_geometry, [activeGroup]: group };
            state.activeLayer = activeLayer;
            state.layersById = layersById;
        },

        onDeleteLayerClick: (state, action) => {
            const { layersById, calibration_geometry } = cloneDeep(state);
            const { activeGroup, activeLayer } = action.payload;
            let group;
            if (!activeGroup) {
                return;
            } else {
                group = calibration_geometry[activeGroup].filter((item) => item.id !== activeLayer);
                delete layersById[activeLayer];
            }

            state.calibration_geometry = { ...calibration_geometry, [activeGroup]: group };
            state.activeLayer = null;
            state.layersById = layersById;
        },

        onRenameLayerClick: (state, action) => {
            const { calibration_geometry, layersById } = cloneDeep(state);
            const { layerId, newValue } = action.payload;
            const { groupId } = layersById[layerId];
            const group = calibration_geometry[groupId].map((layer) => {
                if (layer.id !== layerId) {
                    return layer;
                } else {
                    return { ...layer, name: newValue };
                }
            });

            state.calibration_geometry = { ...calibration_geometry, [groupId]: group };
        },

        onDeleteAnchor: (state, action) => {
            const { groupId, layerId, elementId, anchorIndex } = action.payload;
            const { calibration_geometry } = cloneDeep(state);

            const group = calibration_geometry[groupId].map((layer) => {
                if (layer.id !== layerId) {
                    return layer;
                } else {
                    const elements = [];
                    layer.elements.forEach((element) => {
                        if (element.id !== elementId) {
                            elements.push(element);
                        } else if (element.id === elementId && element.coords.length > 2) {
                            const coords = element.coords;
                            coords.splice(anchorIndex, 1);
                            elements.push({ ...element, coords });
                        }
                    });
                    return { ...layer, elements };
                }
            });

            state.calibration_geometry = { ...calibration_geometry, [groupId]: group };
        },

        addAnchor: (state, action) => {
            const { anchorCoord, groupId, layerId, elementId } = action.payload;
            const { calibration_geometry } = cloneDeep(state);

            const group = calibration_geometry[groupId].map((layer) => {
                if (layer.id !== layerId) {
                    return layer;
                } else {
                    const elements = layer.elements.map((element) => {
                        if (element.id !== elementId) {
                            return element;
                        } else {
                            const coords = element.coords;
                            let index = null;
                            coords.forEach((point, i, arr) => {
                                if (i > 0) {
                                    if (between(arr[i - 1], point, anchorCoord)) {
                                        index = i;
                                    }
                                }
                            });
                            if (index !== null) {
                                coords.splice(index, 0, anchorCoord);
                            }
                            return { ...element, coords };
                        }
                    });
                    return { ...layer, elements };
                }
            });

            state.calibration_geometry = { ...calibration_geometry, [groupId]: group };
        },

        onRenameAnchorPointClick: (state, action) => {
            const { calibration_geometry } = cloneDeep(state);
            const { pointId, newValue } = action.payload;
            const group = calibration_geometry.anchor_points.map((layer) => {
                const elements = layer.elements.map((point) => {
                    if (point.id !== pointId) {
                        return point;
                    } else {
                        return { ...point, name: newValue };
                    }
                });
                return { ...layer, elements };
            });

            state.calibration_geometry = { ...calibration_geometry, anchor_points: group };
        },

        onLayerClick: (state, action) => {
            const { layerId, groupId } = action.payload;
            state.activeLayer = layerId;
            state.activeGroup = groupId;
            state.tool = { id: null };
        },

        onGroupNameClick: (state, action) => {
            const { groupId } = action.payload;
            state.activeLayer = null;
            state.activeGroup = groupId;
        },

        // fillInitial: (state) => {
        //     const { camera_installations, controlDictionary } = cloneDeep(state);
        //     const { calibration_geometry } = camera_installations[0];

        //     const layersById = {};
        //     Object.keys(controlDictionary).forEach((key) => {
        //         if (calibration_geometry[key]) {
        //             calibration_geometry[key].forEach((item) => {
        //                 layersById[item.id] = { visible: true, groupId: key };
        //             });
        //         }
        //     });
        //     state.layersById = layersById;
        //     state.calibration_geometry = calibration_geometry;
        // },
    },
});

export const {
    onDeleteAnchor,
    addAnchor,
    toggleDraw,
    // fillInitial,
    onEyeClick,
    onUpdateGeometry,
    onCreateNewElement,
    onDeleteElement,
    onLayerClick,
    onGroupNameClick,
    onAddLayerClick,
    onDeleteLayerClick,
    onRenameLayerClick,
    onRenameAnchorPointClick,
    onSectionEyeClick,
    fillCurrentCamera,
    resetCalibrationReducer,
} = calibrationReducer.actions;

export default calibrationReducer.reducer;
