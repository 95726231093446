import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { CorrectionContext } from '../correction';
import { GlobalContext } from '../../../App';
import FooterCP from '../../wrappers/footer_cp';

const ControlPanelFooter = React.memo(() => {
    const { t } = useTranslation();
    const { activeGroup, finishingStage1, _cancelChanges, correctedRawImage } = useContext(CorrectionContext);
    const { _correctDistortion, _jumpToStage_2 } = useContext(GlobalContext);

    const onSaveChangesClick = (activeGroup) => (e) => {
        _correctDistortion(activeGroup);
    };
    const jumpToStage_2 = (newStage) => () => {
        _jumpToStage_2({ correctedRawImage, newStage });
        _cancelChanges();
    };
    const onCancelChangesClick = (e) => {
        _cancelChanges();
    };

    const stackTokens = {
        childrenGap: 10,
        // padding: 10,
    };

    return (
        <FooterCP>
            {finishingStage1 ? (
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text={t('Cancel')} onClick={onCancelChangesClick} allowDisabledFocus disabled={false} />
                    <PrimaryButton text={t('Accept')} onClick={jumpToStage_2(2)} allowDisabledFocus disabled={false} />
                </Stack>
            ) : (
                <PrimaryButton text={t('Save changes')} onClick={onSaveChangesClick(activeGroup)} allowDisabledFocus disabled={false} />
            )}
        </FooterCP>
    );
});

export default ControlPanelFooter;
