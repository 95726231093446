import React, { useMemo, useState } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const DeleteCameraModal = React.memo(({ modalData, ...props }) => {
    const { t } = useTranslation();

    const onOKClick = (options) => () => {
        props._onOKClick(options);
    };

    return (
        <MainWrapper>
            <LabelWrapper>{t('Are you sure?')}</LabelWrapper>
            <Footer>
                <PrimaryButton text={t('Delete')} onClick={onOKClick({ id: modalData.options.id })} />
            </Footer>
        </MainWrapper>
    );
});

export default DeleteCameraModal;

const MainWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 10px; */
    img {
        object-fit: contain;
    }
`;

const Footer = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;
