import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import {
    toggleDraw,
    fillInitial,
    onEyeClick,
    onLayerClick,
    onGroupNameClick,
    onAddLayerClick,
    onDeleteLayerClick,
    onUpdateGeometry,
    onCreateNewElement,
    onDeleteElement,
    onSectionEyeClick,
    onDeleteAnchor,
    addAnchor,
} from '../../reducers/calibrationReducer';
import { showModal } from '../../reducers/modalReducer';
import ToolsPanel from './components/tools_panel';
import ControlPanel from './components/control_panel';
import ImagePanel from './components/image_panel';
import GeometryEditor from '../wrappers/geometry_editor_wrapper';

export const CalibrationContext = React.createContext({});

const Calibration = React.memo((props) => {
    const { tool, controlDictionary, layersById, activeLayer, activeGroup, calibration_geometry, currentCamera } = useSelector(
        (state) => state.calibrationReducer
    );

    const dispatch = useDispatch();

    const _toggleDraw = (id) => {
        dispatch(toggleDraw(id));
    };

    const _onEyeClick = (layerId) => {
        dispatch(onEyeClick({ layerId }));
    };
    const _onSectionEyeClick = (groupId) => {
        dispatch(onSectionEyeClick({ groupId }));
    };

    const _onLayerClick = (layerId, groupId) => {
        dispatch(onLayerClick({ layerId, groupId }));
    };
    const _onUpdateGeometry = (args) => {
        dispatch(onUpdateGeometry(args));
    };
    const _onCreateNewElement = (args) => {
        dispatch(onCreateNewElement(args));
    };
    const _onDeleteElement = (args) => {
        dispatch(onDeleteElement(args));
    };

    const _onGroupNameClick = (groupId) => {
        dispatch(onGroupNameClick({ groupId }));
    };

    const _onAddLayerClick = () => {
        dispatch(onAddLayerClick());
    };

    const _showModal = (args) => {
        dispatch(showModal({ ...args }));
    };

    const _onDeleteLayerClick = () => {
        dispatch(onDeleteLayerClick());
    };

    const _addAnchor = (args) => {
        dispatch(addAnchor(args));
    };

    const _onDeleteAnchor = (args) => {
        dispatch(onDeleteAnchor(args));
    };

    const _onClipboardClick = (data) => {
        dispatch(
            showModal({
                modalType: 'copy_to_clipboard',
                width: '80%',
                options: { data, additionalOptions: { canEdit: false, activeGroup } },
            })
        );
    };

    const perspectiveContextValue = {
        _onDeleteAnchor,
        _addAnchor,
        _onUpdateGeometry,
        _onCreateNewElement,
        _onDeleteElement,
        _onAddLayerClick,
        _onDeleteLayerClick,
        _onGroupNameClick,
        _onEyeClick,
        _onLayerClick,
        _toggleDraw,
        _showModal,
        _onSectionEyeClick,
        _onClipboardClick,
        tool,
        controlDictionary,
        calibration_geometry,
        layersById,
        activeLayer,
        activeGroup,
        currentCamera,
    };

    return (
        <CalibrationContext.Provider value={perspectiveContextValue}>
            {layersById && (
                <GeometryEditor>
                    <ToolsPanel />
                    <ImagePanel />
                    <ControlPanel />
                </GeometryEditor>
            )}
        </CalibrationContext.Provider>
    );
});

export default Calibration;
