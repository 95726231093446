import React, { useContext } from 'react';
import { TooltipHost, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { IconButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '../../../App';
import { cloneDeep } from 'lodash';

import FooterCP from '../../wrappers/footer_cp';

const ControlPanelFooter = React.memo(({ planToEdit }) => {
    const { t } = useTranslation();

    const { _createNewPlan, _updatePlan } = useContext(GlobalContext);

    const onSaveChangesClick = () => {
        const plan = cloneDeep(planToEdit);
        plan.measured_segment = { ...plan.measured_segment, length_m: Number(plan.measured_segment.length_m) };
        if (plan.id) {
            _updatePlan(plan);
        } else {
            _createNewPlan(plan);
        }
    };

    const disabled =
        planToEdit.name === '' ||
        planToEdit.floor === '' ||
        planToEdit.measured_segment.length_m === '' ||
        planToEdit.measured_segment.coords.length < 2;

    return (
        <FooterCP>
            <TooltipHost content={t('Save changes')} directionalHint={DirectionalHint.topCenter}>
                <PrimaryButton text="Save changes" onClick={onSaveChangesClick} allowDisabledFocus disabled={disabled} />
            </TooltipHost>
        </FooterCP>
    );
});

export default ControlPanelFooter;
