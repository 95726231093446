import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { PerspectiveContext } from '../perspective';

import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

export default function ToggleToDock({ id, ...props }) {
    const { dockIds, _toggleCameraToDock } = useContext(PerspectiveContext);
    const { t } = useTranslation();

    function _onChange(ev, checked) {
        _toggleCameraToDock(id);
    }

    const checked = dockIds.includes(id);

    const tooltipText = checked ? t('Remove from dock') : t('Place to dock');

    return (
        <TooltipHost content={tooltipText}>
            <StyledToggle onChange={_onChange} checked={checked} />
        </TooltipHost>
    );
}

const StyledToggle = styled(Toggle)`
    margin: 0;
`;
