import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TooltipHost, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { IconButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { PerspectiveContext } from '../perspective';

const CameraImage = React.memo(({ selectedCamera }) => {
    const { _showBigCameraImage } = useContext(PerspectiveContext);
    if (!selectedCamera) return null;
    let imgUrl = '';
    if (selectedCamera?.image_profile) {
        imgUrl = selectedCamera.image_profile.corrected_image;
    }

    const onClick = (selectedCamera) => () => {
        if (!selectedCamera) return;
        _showBigCameraImage(selectedCamera);
    };

    const infoText = `${selectedCamera.marker} (ci_id: ${selectedCamera.id})`;

    return (
        <Container onClick={onClick(selectedCamera)}>
            {imgUrl === '' ? null : (
                <Wrapper>
                    <Img src={imgUrl} />
                    <span>{infoText}</span>
                </Wrapper>
            )}
        </Container>
    );
});

export default CameraImage;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    cursor: pointer;
    width: 100%;
    height: auto;
    min-height: 100px;
    /* border: 1px solid #aaa; */
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Img = styled.img`
    max-width: 100%;
    height: auto;
    margin-bottom: 5px;
`;
