import React, { useState, useMemo, useRef, useEffect, useContext } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../App';
import { PerspectiveContext } from '../perspective';
import styled from 'styled-components/macro';
import Final3DPolygon from './final_3d_polygon';
import { cloneDeep } from 'lodash';

const ReactGridLayout = WidthProvider(RGL);

const ROW_HEIGHT = 10;
const HORIZONTAL_COLS = 1;
const GAP = 10;

const Dock = React.memo(({ splitSizes }) => {
    const mainWrapperRef = useRef();
    const { t } = useTranslation();
    const [myLayout, set_myLayout] = useState([]);
    const [mainWrapperSize, set_mainWrapperSize] = useState(null);

    const { dockIds, camerasById3D, selectedCamerasIds } = useContext(PerspectiveContext);
    const { windowSize } = useContext(GlobalContext);

    const generateDOM = useMemo(() => {
        if (!mainWrapperSize) return null;

        return dockIds.map((id, i) => {
            const camera = camerasById3D[id];
            const imgUrl = camera.image_profile.corrected_image;
            const selected = selectedCamerasIds.includes(id);

            return (
                <Block key={String(id)} selected={selected}>
                    <Final3DPolygon myLayout={myLayout} imgUrl={imgUrl} camera={camera} splitSizes={splitSizes} />
                </Block>
            );
        });
    }, [mainWrapperSize, dockIds, camerasById3D, selectedCamerasIds, myLayout, splitSizes]);

    const getLayoutSizes = (camera, mainWrapperSize) => {
        const width = camera.image_profile.width;
        const height = camera.image_profile.height;
        const koeff = width / height;
        const { width: clientWidth } = mainWrapperSize;
        const horizontal_H = div(clientWidth / koeff, ROW_HEIGHT + GAP);
        return horizontal_H;
    };

    useEffect(() => {
        if (!mainWrapperSize) return;

        const oldLayout = cloneDeep(myLayout).filter((item) => dockIds.includes(Number(item.i)));
        const oldLayoutIds = oldLayout.map((item) => Number(item.i));

        const newLayout = oldLayout.map((obj, index) => {
            const { x, y, i } = obj;
            const camera = camerasById3D[Number(i)];
            const h = getLayoutSizes(camera, mainWrapperSize);
            return { x: 0, y, w: HORIZONTAL_COLS, h, i };
        });

        dockIds.forEach((id, i) => {
            if (!oldLayoutIds.includes(id)) {
                const camera = camerasById3D[id];
                const h = getLayoutSizes(camera, mainWrapperSize);
                const newItem = { x: 0, y: 1000, w: HORIZONTAL_COLS, h, i: String(id) };
                newLayout.push(newItem);
            }
        });

        set_myLayout(newLayout);
    }, [dockIds, mainWrapperSize]);

    function div(val, by) {
        return (val - (val % by)) / by;
    }

    const onLayoutChange = (layout) => {
        set_myLayout(layout);
    };

    useEffect(() => {
        if (mainWrapperRef.current) {
            set_mainWrapperSize({ width: mainWrapperRef.current.offsetWidth, height: mainWrapperRef.current.offsetHeight });
        }
    }, [mainWrapperRef, windowSize, splitSizes]);

    return (
        <MainWrapper ref={mainWrapperRef}>
            {mainWrapperSize ? (
                <ReactGridLayout
                    cols={HORIZONTAL_COLS}
                    layout={myLayout}
                    margin={[GAP, GAP]}
                    compactType={'vertical'}
                    onLayoutChange={onLayoutChange}
                    rowHeight={ROW_HEIGHT}
                    // resizeHandle={ResizeElement}
                >
                    {generateDOM}
                </ReactGridLayout>
            ) : null}
        </MainWrapper>
    );
});

const ResizeElement = () => {
    return <Triangle />;
};

export default Dock;

const Block = styled.div`
    background-color: rgb(241, 243, 244);
    border: ${(p) => (p.selected ? '2px solid #ff0000' : '2px solid rgb(241, 243, 244)')};
    width: calc(100% - 20px) !important;
`;

const MainWrapper = styled.div`
    background-color: rgb(231, 234, 237);
    height: 100%;
    width: 100%;
    overflow-x: auto;
`;

const Triangle = styled.div`
    width: 10;
    height: 10;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent #ff0000 transparent;
`;
