import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TooltipHost, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { IconButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { CalibrationContext } from '../calibration';
import FooterCP from '../../wrappers/footer_cp';

const LayersControls = React.memo(() => {
    const {
        _onAddLayerClick,
        _showModal,
        activeLayer,
        activeGroup,
        calibration_geometry,
        controlDictionary,
        _onClipboardClick,
    } = useContext(CalibrationContext);

    const onAddLayerClick = () => {
        _onAddLayerClick();
    };

    const onSaveChangesClick = () => {};

    const showModal = (modalType) => () => {
        if (activeLayer) {
            const layerName = calibration_geometry[activeGroup].filter((item) => item.id === activeLayer)[0].name;
            _showModal({ modalType, width: '200px', options: { layerName, activeLayer, activeGroup } });
        }
    };

    const layersNumberInGroup = calibration_geometry[activeGroup] ? calibration_geometry[activeGroup].length : null;
    const layersLimit = controlDictionary[activeGroup] ? controlDictionary[activeGroup].layers_limit : null;
    let disableAddNewLayer = false;
    if (layersLimit && layersNumberInGroup >= layersLimit) {
        disableAddNewLayer = true;
    }

    const onClipboardClick = (data) => () => {
        _onClipboardClick(data);
    };

    return (
        <FooterCP>
            <TooltipHost content={'Copy to clipboard'} directionalHint={DirectionalHint.topCenter}>
                <StIconButton iconProps={{ iconName: 'ClipboardList' }} disabled={false} onClick={onClipboardClick(calibration_geometry)} />
            </TooltipHost>
            <TooltipHost content={'Add new layer'} directionalHint={DirectionalHint.topCenter}>
                <StIconButton iconProps={{ iconName: 'NewFolder' }} disabled={disableAddNewLayer} onClick={onAddLayerClick} />
            </TooltipHost>
            <TooltipHost content={'Delete layer'} directionalHint={DirectionalHint.topCenter}>
                <StIconButton iconProps={{ iconName: 'Delete' }} disabled={activeLayer === null} onClick={showModal('delete_layer')} />
            </TooltipHost>
            {/* <TooltipHost content={'Save changes'} directionalHint={DirectionalHint.topCenter}>
                <PrimaryButton text="Save changes" onClick={onSaveChangesClick} allowDisabledFocus disabled={false} />
            </TooltipHost> */}
        </FooterCP>
    );
});

export default LayersControls;

const StIconButton = styled(IconButton)`
    background-color: ${(p) => p.bgcolor};
    :hover {
        background-color: #ccc !important;
    }
    i {
        font-size: ${(p) => p.size};
    }
`;
