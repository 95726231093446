import React, { useMemo } from 'react';

import { Line, Group, Circle, Arrow } from 'react-konva';
import { getArrowEndPoint } from './tools/getArrowEndPoint';

// import { moveAnchor, passWaysReducerValues } from '../../../../passWays.reducer';
// import { IDrawPassWayProps } from './PassWay.interfaces';
// import { commonTools, ObjectLabel } from '../../../../../../layers';
// import { findLabelCoords } from './core/findLabelCoords';
// import { getArrowEndPoint } from './core/getArrowEndPoint';

/**
 * Компонент объект прохода
 */
const PassWay = (props) => {
    const {
        showFinishCircle,
        coords,
        passMargin,
        currentPlanData: { scale },
        stageScale,
        onClick,
        selected,
        id = '',
    } = props;

    // const { showLabels, snapToGrid } = useSelector(passWaysReducerValues);

    const onShapeClick = (id) => (e) => {
        console.log('YYY');
        onClick({ id });
    };

    const strokeWidth = scale ? passMargin * scale : 2;
    const stroke = selected ? 'rgba(255, 0, 0, 0.7)' : 'rgba(255, 0, 0, 0.2)';

    const points = useMemo(() => {
        const result: Array<number> = [];
        coords.forEach((point) => {
            result.push(...point);
        });
        return result;
    }, [coords]);

    const scaleTransformFoo = (size, scale, step = 0.5) => {
        return size / Math.pow(scale, step);
    };

    const arrows = useMemo(() => {
        const result = [];
        coords.forEach((point, i, arr) => {
            if (i === 0) return;
            const center = [(point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2];
            const arrowEndPoint = getArrowEndPoint(point, i, arr, scale || 1, passMargin, stageScale);
            !showFinishCircle &&
                result.push(
                    <Arrow
                        key={`${i}--${point[0]}`}
                        points={[center[0], center[1], arrowEndPoint[0], arrowEndPoint[1]]}
                        stroke="red"
                        strokeWidth={scaleTransformFoo(3, stageScale)}
                        pointerWidth={scaleTransformFoo(8, stageScale)}
                        pointerLength={scaleTransformFoo(16, stageScale)}
                    />
                );
        });
        return result;
    }, [coords, showFinishCircle, stageScale]);

    return (
        <>
            <Group key={`group == ${id}`}>
                <Line
                    onClick={onShapeClick(id)}
                    // onMouseMove={onMouseMove}
                    points={points}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                    lineJoin={'round'}
                    lineCap={'round'}
                />
                {arrows}
            </Group>
        </>
    );
};

export default PassWay;
