import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

export const camerasReducer = createSlice({
    name: 'camerasReducer',

    initialState: {
        cameras: [],
        camerasById: {},
        camerasById3D: {},
    },

    reducers: {
        getCameras: (state, action) => {
            const { data, additionalParams } = action.payload;

            const cameras = data.map((item) => {
                const { width, height, scale } = additionalParams.filter((plan) => plan.id === item.plan)[0];
                const pp = item.perspective_profile;
                if (
                    pp &&
                    pp.basic_perspective_geometry !== null &&
                    pp.x_offset === null &&
                    pp.y_offset === null &&
                    pp.z_offset === null &&
                    pp.angleh === null
                ) {
                    const { areas_3d } = pp.basic_perspective_geometry;
                    const pointsArr = [];
                    areas_3d.forEach((area) => {
                        area.elements.forEach((element) => {
                            element.coords.forEach((coord) => {
                                coord.forEach((point) => {
                                    pointsArr.push(Math.abs(point) * scale);
                                });
                            });
                        });
                    });
                    const perspective_profile = {
                        ...item.perspective_profile,
                        z_offset: 3,
                        angleh: 0,
                        x_offset: width / (2 * scale),
                        y_offset: height / (2 * scale),
                    };
                    return { ...item, perspective_profile };
                } else {
                    return item;
                }
            });

            const camerasById = {};
            const camerasById3D = {};
            cameras.forEach((item) => {
                camerasById[item.id] = item;
                camerasById3D[item.id] = item;
            });

            // const stressCameras = [];
            // for (let index = 0; index < 100; index++) {
            //     stressCameras.push({ ...cameras[0], id: index });
            // }

            state.cameras = cameras;
            state.camerasById = camerasById;
            state.camerasById3D = camerasById3D;
            // state.cameras = stressCameras;
        },

        delayedChangeCamerasById: (state, action) => {
            const { selectedList } = action.payload;
            const { camerasById } = cloneDeep(state);

            selectedList.forEach((item) => {
                camerasById[item.id] = item;
            });

            state.camerasById = camerasById;
        },

        changeCamerasById: (state, action) => {
            const { camerasById, camerasById3D } = cloneDeep(state);
            const newCamera = action.payload.data;
            camerasById[newCamera.id] = newCamera;
            camerasById3D[newCamera.id] = newCamera;
            state.camerasById = camerasById;
            state.camerasById3D = camerasById3D;
        },

        resetCamerasById: (state, action) => {
            const { camerasById, camerasById3D } = cloneDeep(state);
            const { id } = action.payload;

            camerasById[id] = camerasById3D[id];
            state.camerasById = camerasById;
        },
    },
});

export const { getCameras, delayedChangeCamerasById, changeCamerasById, resetCamerasById } = camerasReducer.actions;

export default camerasReducer.reducer;
