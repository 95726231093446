import React, { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { useId } from '@uifabric/react-hooks';
import { ColorPicker } from 'office-ui-fabric-react/lib/ColorPicker';
import { TooltipHost, TooltipDelay, DirectionalHint, ITooltipProps, ITooltipHostStyles } from 'office-ui-fabric-react/lib/Tooltip';
import { manualObjectChange } from './MeasuredCoordObjectsLayerReducer';
import styled from 'styled-components/macro';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { useTranslation } from 'react-i18next';
import Eye from '../../../../eye/eye';

const ObjectOptions = () => {
    const dispatch = useDispatch();
    const tooltipId = useId('tooltip');
    const { objectsById = {}, selectedObject, visibleObjects } = useSelector((state) => state.MeasuredCoordObjectsLayerReducer);

    const object = objectsById[selectedObject];

    const updateColor = useCallback(
        (ev, colorObj) => {
            dispatch(manualObjectChange({ fieldName: 'color', newValue: `#${colorObj.hex}`, id: selectedObject }));
        },
        [dispatch, selectedObject]
    );

    if (!object) return null;

    const onObjectChange = (fieldName) => (e, newValue) => {
        dispatch(manualObjectChange({ fieldName, newValue, id: selectedObject }));
    };

    const tooltipProps = {
        onRenderContent: () => (
            <ColorPicker
                color={object.color}
                onChange={updateColor}
                alphaType={'none'}
                showPreview={false}
                // styles={colorPickerStyles}
            />
        ),
    };

    return selectedObject ? (
        <MainWrapper>
            {/* {selectedObject} */}
            <StyledTextField label="Marker:" underlined value={object.marker} onChange={onObjectChange('marker')} />
            <ColorWrapper>
                <StyledTextField label="Color:" underlined value={object.color} onChange={onObjectChange('color')} />
                <TooltipHost
                    tooltipProps={tooltipProps}
                    delay={TooltipDelay.zero}
                    id={tooltipId}
                    directionalHint={DirectionalHint.bottomCenter}
                    // styles={hostStyles}
                >
                    <ColorPickerButton bgcolor={object.color} />
                </TooltipHost>
            </ColorWrapper>
            <StyledTextField
                label="Center X:"
                type="number"
                step={0.01}
                underlined
                value={object.x.toFixed(2)}
                onChange={onObjectChange('x')}
            />
            <StyledTextField
                label="Center Y:"
                type="number"
                step={0.01}
                underlined
                value={object.y.toFixed(2)}
                onChange={onObjectChange('y')}
            />
            <StyledTextField
                label="Azimuth:"
                type="number"
                step={0.01}
                underlined
                value={object.azimuth.toFixed(2)}
                onChange={onObjectChange('azimuth')}
            />
            <StyledTextField
                label="dX:"
                type="number"
                step={0.01}
                underlined
                value={object.dx.toFixed(2)}
                onChange={onObjectChange('dx')}
            />
            <StyledTextField
                label="dY:"
                type="number"
                step={0.01}
                underlined
                value={object.dy.toFixed(2)}
                onChange={onObjectChange('dy')}
            />
            <StyledTextField
                label="dZ:"
                type="number"
                max={2.9}
                step={0.01}
                underlined
                value={object.dz.toFixed(2)}
                onChange={onObjectChange('dz')}
            />
        </MainWrapper>
    ) : null;
};

export default ObjectOptions;

const ColorWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ColorPickerButton = styled.div`
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(p) => p.bgcolor};
    margin-left: auto;
    border: 1px solid rgb(96, 94, 92);
`;

const StyledTextField = styled(TextField)`
    width: 100%;
    margin: 0 10px 0 0;
    * {
        background-color: transparent !important;
    }
`;

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    overflow-y: auto;
`;
