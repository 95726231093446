import { createSlice } from '@reduxjs/toolkit';
import { backToFrontConverter } from '../tools/tools';

export const generalSetup = createSlice({
    name: 'generalSetup',
    initialState: {
        CONTROL_PANEL_WIDTH: '300px',
        TOOLS_PANEL_WIDTH: '40px',
        GRID_GAP: '3px',
        HEADER_HEIGHT: '50px',

        backend: {},
        windowSize: {},
        activePlanId: null,
        activePlan: null,
        showSpinner: false,
        host: 'http://avabr.xyz:7075',
        // token: '5adb55e3-555b-4a59-ae9e-114b375b0635',
        token: null,
        camera_installations: null,
        front_camera_installations: null,
        lang: null,
    },
    reducers: {
        setActivePlanId: (state, action) => {
            state.activePlan = action.payload.plan;
            if (action.payload?.plan?.id) {
                state.activePlanId = action.payload.plan.id;
            } else {
                state.activePlanId = null;
            }
        },
        setCameraInstallations: (state, action) => {
            const front_camera_installations = backToFrontConverter(action.payload);
            state.camera_installations = action.payload;
            state.front_camera_installations = front_camera_installations;
        },

        changeLang: (state, action) => {
            state.lang = action.payload;
        },

        setWindowSize: (state, action) => {
            state.windowSize = action.payload;
        },

        toggleSpinner: (state, action) => {
            state.showSpinner = action.payload;
        },

        fillBackend: (state, action) => {
            state.backend = action.payload;
        },

        storeToken: (state, action) => {
            state.token = action.payload;
        },
    },
});

export const {
    changeLang,
    setWindowSize,
    setCameraInstallations,
    toggleSpinner,
    setActivePlanId,
    fillBackend,
    storeToken,
} = generalSetup.actions;

export default generalSetup.reducer;
