/**
 * Словарь соответствий полей локации между бэком и фронтом.
 */
export const locationsMapping = new Map([
    ['id', 'id'],
    ['name', 'name'],
    ['description', 'description'],
    ['msHost', 'ms_host'],
    ['msLocationId', 'ms_location_id'],
]);

/**
 * Словарь соответствий полей плана между бэком и фронтом.
 */
export const plansMapping = new Map([
    ['locationId', 'location_id'],
    ['length', 'length'],
    ['planScale', 'scale'],
    ['planWidth', 'width'],
    ['planHeight', 'height'],
    ['planId', 'id'],
    ['floor', 'floor'],
    ['image', 'image'],
    ['isMain', 'is_main'],
    ['isAnchor', 'is_anchor'],
    ['activeFrom', 'active_from'],
    ['notes', 'notes'],
    ['line', 'line'],
    ['measureCutLength', 'length'],
    ['geoTopLeft', 'geo_top_left'],
    ['geoTopRight', 'geo_top_right'],
    ['geoBottomLeft', 'geo_bottom_left'],
    ['geoBottomRight', 'geo_bottom_right'],
]);

/**
 * Словарь соответствий полей слоя между бэком и фронтом.
 */
export const layersMapping = new Map([
    ['createdAt', 'created_at'],
    ['floor', 'floor'],
    ['mainPlanId', 'main_plan_id'],
    ['data', 'data'],
    ['layerType', 'layer_type'],
]);
