import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { GlobalContext } from '../../App';

const GeometryEditor = React.memo(({ children }) => {
    const { windowSize, CONTROL_PANEL_WIDTH, TOOLS_PANEL_WIDTH, GRID_GAP, HEADER_HEIGHT } = useContext(GlobalContext);

    return (
        <Container
            CONTROL_PANEL_WIDTH={CONTROL_PANEL_WIDTH || '300px'}
            TOOLS_PANEL_WIDTH={TOOLS_PANEL_WIDTH || '40px'}
            HEADER_HEIGHT={HEADER_HEIGHT || '50px'}
            GRID_GAP={GRID_GAP || '3px'}
        >
            {children}
        </Container>
    );
});

export default GeometryEditor;

const Container = styled.div`
    width: 100%;
    height: ${(p) => `calc(100vh - ${p.HEADER_HEIGHT})`};
    display: grid;
    grid-template-columns: ${(p) => `${p.TOOLS_PANEL_WIDTH} auto ${p.CONTROL_PANEL_WIDTH}`};
    grid-gap: ${(p) => p.GRID_GAP};
    background-color: rgb(200, 200, 200);
`;
