import React, { useState, useMemo, useRef, useEffect, useContext } from 'react';
import RGL from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../App';
import { VideosContext } from '../videos';
import styled from 'styled-components/macro';
import { cloneDeep } from 'lodash';

import VideoBox from './video_box';

const ROW_HEIGHT = 10;
const HORIZONTAL_COLS = 6;
const INITIAL_WIDTH_COLS = 6;
const GAP = 10;

const Dock = React.memo(({ splitSizes, ...props }) => {
    const mainWrapperRef = useRef();
    const { t } = useTranslation();
    const [myLayout, set_myLayout] = useState([]);
    const [mainWrapperSize, set_mainWrapperSize] = useState(null);
    const [resizeTrigger, setResizeTrigger] = useState(0);

    const { windowSize } = useContext(GlobalContext);
    const { neuralData } = useContext(VideosContext);

    const generateDOM = useMemo(() => {
        if (!mainWrapperSize) return null;

        return neuralData.map((item, i) => {
            return (
                <Block key={String(i)} selected={false}>
                    <VideoBox video={item.video} tracks={item.tracks} mainWrapperSize={mainWrapperSize} index={i} />
                </Block>
            );
        });
    }, [mainWrapperSize, neuralData, resizeTrigger, myLayout]);

    useEffect(() => {
        if (!mainWrapperSize) return;
        if (myLayout.length === 0) {
            const newLayout = [];
            neuralData.forEach((item, i) => {
                const ratio = item.video.params.size.width / item.video.params.size.height;
                const h = Number((mainWrapperSize.width / HORIZONTAL_COLS / (ratio * (ROW_HEIGHT + GAP))).toFixed(0)) - 1;
                const newItem = {
                    x: (i * INITIAL_WIDTH_COLS) % HORIZONTAL_COLS,
                    y: i,
                    w: INITIAL_WIDTH_COLS,
                    h,
                    i: String(i),
                };
                newLayout.push(newItem);
            });
            set_myLayout(newLayout);
        } else {
            const newLayout = myLayout.map((item) => {
                const h = getProportionalHeight(item, neuralData);
                return { ...item, h };
            });
            set_myLayout(newLayout);
        }
    }, [mainWrapperSize, neuralData]);

    useEffect(() => {
        if (mainWrapperRef.current) {
            set_mainWrapperSize({
                width: mainWrapperRef.current.offsetWidth,
                height: mainWrapperRef.current.offsetHeight,
            });
        }
    }, [mainWrapperRef, windowSize, splitSizes]);

    const getProportionalHeight = (layoutItem, arr) => {
        const index = Number(layoutItem.i);
        const ratio = arr[index].video.params.size.width / arr[index].video.params.size.height;
        const h = Number(((layoutItem.w * mainWrapperSize.width) / HORIZONTAL_COLS / (ratio * (ROW_HEIGHT + GAP))).toFixed(0)) + 1;
        return h;
    };

    const onLayoutChange = (layout) => {
        const newLayout = layout.map((item) => {
            const h = getProportionalHeight(item, neuralData);
            return { ...item, h };
        });
        set_myLayout(newLayout);
        setResizeTrigger(() => resizeTrigger + 1);
    };

    const onResize = (layout, oldLayoutItem, layoutItem, placeholder) => {
        layoutItem.h = getProportionalHeight(layoutItem, neuralData);
        setResizeTrigger(() => resizeTrigger + 1);
    };

    const onResizeStop = () => {
        setResizeTrigger(() => resizeTrigger + 1);
    };

    return (
        <MainWrapper ref={mainWrapperRef}>
            {mainWrapperSize ? (
                <RGL
                    width={mainWrapperSize.width}
                    cols={HORIZONTAL_COLS}
                    layout={myLayout}
                    margin={[GAP, GAP]}
                    compactType={'vertical'}
                    onLayoutChange={onLayoutChange}
                    onResize={onResize}
                    onResizeStop={onResizeStop}
                    rowHeight={ROW_HEIGHT}
                    // resizeHandle={ResizeElement}
                >
                    {generateDOM}
                </RGL>
            ) : null}
        </MainWrapper>
    );
});

// const ResizeElement = () => {
//     return <Triangle />;
// };

export default Dock;

const Block = styled.div`
    background-color: #ff0000;
    border: 2px solid #ff0000;
    /* height: 480px !important;
    width: 640px !important; */
`;

const MainWrapper = styled.div`
    background-color: rgb(231, 234, 237);
    height: 100%;
    width: 100%;
    overflow-x: auto;
`;

const Triangle = styled.div`
    width: 10;
    height: 10;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent #ff0000 transparent;
`;
