import cloneDeep from 'lodash/cloneDeep';
import { createSlice } from '@reduxjs/toolkit';
import { mapBack2Front } from '../tools/tools';
import { locationsMapping } from '../constants/keysMapping';

export const locationsReducer = createSlice({
    name: 'locationsReducer',

    initialState: {
        locationsList: [],
        activeLocationId: null,
        msLocationId: null,
    },

    reducers: {
        getLocations: (state, action) => {
            const locationsList = action.payload.data.map((item) => {
                return mapBack2Front(locationsMapping, item);
            });
            state.locationsList = locationsList;
        },
        changeActiveLocationId: (state, action) => {
            state.activeLocationId = action.payload;
        },
        changeMsLocationId: (state, action) => {
            state.msLocationId = action.payload;
        },
    },
});

export const { getLocations, changeActiveLocationId, changeMsLocationId } = locationsReducer.actions;

export default locationsReducer.reducer;
