import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import styled from 'styled-components';

const subDomains = ['main'];

const DevOptionsModal = React.memo(({ modalData, ...props }) => {
    const [localBackend, setLocalBackend] = useState(modalData.options.backend);

    const stackTokens = { childrenGap: 10 };

    const _onChange = (parameter) => (e, newValue) => {
        const loc = { ...localBackend, [parameter]: newValue };
        setLocalBackend({ ...loc });
    };

    const _onCheckChange = (parameter) => (e, checked) => {
        const loc = { ...localBackend, checks: { ...localBackend.checks, [parameter]: checked } };
        setLocalBackend({ ...loc });
    };

    const onOKClick = (options) => () => {
        props._onOKClick(options);
    };

    return (
        <MainWrapper>
            <Container>
                <StStack tokens={{ childrenGap: 15 }}>
                    {subDomains.map((item, i) => {
                        const checked = localBackend.checks && localBackend.checks[item] ? localBackend.checks[item] : false;
                        return (
                            <Wrapper key={`${i} - ${item}`}>
                                <StCheckbox checked={checked} onChange={_onCheckChange(item)} />
                                <StTextField label={item} value={localBackend[item] || ''} onChange={_onChange(item)} />
                            </Wrapper>
                        );
                    })}
                </StStack>
            </Container>
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text={'OK'} width={'100px'} onClick={onOKClick({ ...localBackend })} />
                </Stack>
            </Footer>
        </MainWrapper>
    );
});

export default DevOptionsModal;

const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
`;

const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
`;

const Container = styled.div`
    display: flex;
    width: 100%;
`;

const StTextField = styled(TextField)`
    width: 100% !important;
    margin-left: 10px;
    textarea {
        max-height: 300px !important;
        overflow: auto !important;
    }
`;
const StStack = styled(Stack)`
    width: 100% !important;
`;
const StCheckbox = styled(Checkbox)`
    margin-bottom: 5px;
`;
