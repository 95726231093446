import React, { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { TooltipHost, ITooltipHostStyles, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';

import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { GlobalContext } from '../../../App';
import { CalibrationContext } from '../calibration';

const ToolsPanel = React.memo(() => {
    const { _toggleDraw, tool, layersById, controlDictionary, calibration_geometry, activeGroup, activeLayer } = useContext(
        CalibrationContext
    );
    const { newElementTrigger } = useContext(GlobalContext);

    useEffect(() => {
        if (newElementTrigger > 0) {
            _toggleDraw(activeGroup);
        }
    }, [newElementTrigger]);

    const onClick = (id) => (e) => {
        if (id === tool.id) {
            _toggleDraw(null);
        } else {
            _toggleDraw(id);
        }
    };

    const toolsButtons = useMemo(() => {
        const active_layer =
            calibration_geometry[activeGroup] && calibration_geometry[activeGroup].filter((item) => item.id === activeLayer)[0];

        const result = Object.keys(controlDictionary)
            .filter((key) => !controlDictionary[key].canDraw)
            .map((key) => {
                const bgColor = key === tool.id ? '#ccc !important' : 'transparent !important';
                const tooltip = tool.name;

                return (
                    <TooltipHost content={tooltip} key={key} directionalHint={DirectionalHint.rightCenter}>
                        <StIconButton
                            iconProps={controlDictionary[key].icon}
                            disabled={false}
                            bgcolor={bgColor}
                            onClick={onClick(key)}
                            size={controlDictionary[key].icon.size}
                        />
                    </TooltipHost>
                );
            });

        let disabled = false;

        if (
            !active_layer ||
            (active_layer &&
                controlDictionary[activeGroup] &&
                controlDictionary[activeGroup].layer_elements_limit &&
                active_layer.elements.length >= controlDictionary[activeGroup].layer_elements_limit)
        ) {
            disabled = true;
        }

        const bgColor = activeGroup === tool.id && !disabled ? '#ccc !important' : 'transparent !important';
        const tooltip = disabled ? 'Please select layer' : tool.name;

        result.splice(
            1,
            0,
            <TooltipHost content={tooltip} key={activeGroup} directionalHint={DirectionalHint.rightCenter}>
                <StIconButton
                    iconProps={controlDictionary[activeGroup].icon}
                    disabled={disabled}
                    bgcolor={bgColor}
                    onClick={onClick(activeGroup)}
                    size={controlDictionary[activeGroup].icon.size}
                />
            </TooltipHost>
        );

        return result;
    }, [tool?.id, activeGroup, activeLayer, calibration_geometry]);

    return <MainWrapper>{toolsButtons}</MainWrapper>;
});

export default ToolsPanel;

const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 5px;
    display: grid;
    grid-gap: 5px;
    grid-template-rows: repeat(10, 40px);
    background-color: rgb(240, 242, 245);
`;

const StIconButton = styled(IconButton)`
    background-color: ${(p) => p.bgcolor};
    :hover {
        background-color: #ccc !important;
    }
    i {
        font-size: ${(p) => p.size};
    }
`;
