import cloneDeep from 'lodash/cloneDeep';

import { createSlice } from '@reduxjs/toolkit';

import { getSelectedGroup, generateId } from '../tools/tools';

export const modalReducer = createSlice({
    name: 'modalReducer',
    initialState: {
        show: false,
        showExternal: false,
    },

    reducers: {
        hideModal: (state, action) => {
            state.show = false;
            state.showExternal = false;
        },

        showModal: (state, action) => {
            const { external } = action.payload;
            if (!external) {
                state.show = true;
                Object.keys(action.payload).forEach((key) => {
                    state[key] = action.payload[key];
                });
            } else {
                state.showExternal = true;
            }
        },
    },
});

export const { hideModal, showModal } = modalReducer.actions;

export default modalReducer.reducer;
