import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { DateTime } from 'luxon';
import { useHotkeys } from 'react-hotkeys-hook';

import ControlPanel from './components/control_panel';
import ImagePanel from './components/image_panel';
import { GlobalContext } from '../../App';

import { getVideoParamsFromName } from './core/getVideoParamsFromName';
import { getRequest, postRequest, postFetchRequest } from '../../api';
import { urls } from '../../constants/urls';

import {
    changeCurrentProgress,
    stopPlayer,
    togglePlay,
    changePlayed,
    changeDate,
    changeHour,
    changeStep,
    changeMinute,
    getAvailableVideofiles,
    getAvailableTracksfiles,
    getVideos,
    getTracks,
} from '../../reducers/videosReducer';

export const VideosContext = React.createContext({});

// const videoUrls = [
//     'https://storage.yandexcloud.net/fc-processing-result/ci1033/20210520/ci1033_dt20210520T113000Z_ds600_v1.npHl2YKv.video_pyr1_fps2_size640x480.webm?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=I89EjR7hpjWoYGE7xm7A%2F20210520%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Date=20210520T114144Z&X-Amz-Expires=604800&X-Amz-Signature=fff91bc86e4d3dac364d51d8b3b89a7ae6a3c30ca7bbeb2d52a500f8dcec2799&X-Amz-SignedHeaders=host',
//     'https://storage.yandexcloud.net/fc-processing-result/ci1034/20210520/ci1034_dt20210520T113000Z_ds600_v1.PHKLNiGR.video_pyr1_fps2_size640x360.webm?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=I89EjR7hpjWoYGE7xm7A%2F20210520%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Date=20210520T114210Z&X-Amz-Expires=604800&X-Amz-Signature=f5e4f43a8f55ff9d77c8c7a8ead8a4cbad4ab6d384c3a1a85dcd449aaef41be4&X-Amz-SignedHeaders=host',
//     'https://storage.yandexcloud.net/fc-processing-result/ci1035/20210520/ci1035_dt20210520T113000Z_ds600_v1.D8Rsqduq.video_pyr1_fps2_size640x360.webm?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=I89EjR7hpjWoYGE7xm7A%2F20210520%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Date=20210520T114223Z&X-Amz-Expires=604800&X-Amz-Signature=cca29242f3bfcd979765997a0884b49123015b7c68edc9b227e6cdf457e70ea0&X-Amz-SignedHeaders=host',
//     'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
// ];

const Videos = React.memo((props) => {
    const { token } = useContext(GlobalContext);
    const {
        player,
        step,
        availableVideoFilesByCameraId,
        availableTracksFilesByCameraId,
        selectedDate,
        selectedHour,
        selectedMinute,
        sliceDuration,
        selectedCameras,
    } = useSelector((state) => state.videosReducer);
    const { plans } = useSelector((state) => state.plansReducer);
    const dispatch = useDispatch();
    const [arrowShift, setArrowShift] = useState({ shift: 0 });

    useHotkeys('left', () => setArrowShift({ shift: -1 }));
    useHotkeys('right', () => setArrowShift({ shift: 1 }));

    useEffect(() => {
        dispatch(stopPlayer());
        const moment = player.playedSeconds + arrowShift.shift * step;
        moment > 0 && dispatch(changePlayed(moment));
    }, [arrowShift, step]);

    const selectedStartTime = DateTime.fromJSDate(selectedDate).set({
        hour: Number(selectedHour),
        minute: Number(selectedMinute),
        second: 0,
        millisecond: 0,
    });

    const neuralData = selectedCameras.map((cameraId) => {
        const video = {};
        const dt = selectedStartTime.toUTC().toFormat('yyyyMMdd:HHmmss').replace(/:/, 'T').concat('Z');
        try {
            video.url = availableVideoFilesByCameraId[cameraId][dt].url;
        } catch (error) {
            video.url = null;
        }
        video.params = getVideoParamsFromName(video.url);

        let tracks = null;
        try {
            tracks = availableTracksFilesByCameraId[cameraId][dt].objects_by_fr;
        } catch (error) {}
        return { video, tracks };
    });

    const changeCurrentProgressTrans = (progress) => {
        dispatch(changeCurrentProgress(progress));
    };

    const changePlayedTrans = (playedSeconds) => {
        dispatch(changePlayed(playedSeconds));
    };

    const changeDateTrans = (date) => {
        dispatch(changeDate(date));
    };

    const onHourChangeTrans = (hour) => {
        dispatch(changeHour(hour));
        const dt = DateTime.fromJSDate(selectedDate).set({ hour: Number(hour), minute: 0, second: 0, millisecond: 0 });

        selectedCameras.forEach((cameraId) => {
            getRequest({
                url: `${urls.AVAILABLE_VIDEOFILES_URL}?timestamp=${dt.toUTC().toString()}&ci_id=${cameraId}`,
                token,
                dispatch,
                actionName: getAvailableVideofiles,
                additionalParams: { cameraId },
            });
            getRequest({
                url: `${urls.AVAILABLE_TRACKSFILES_URL}?timestamp=${dt.toUTC().toString()}&ci_id=${cameraId}`,
                token,
                dispatch,
                actionName: getAvailableTracksfiles,
                additionalParams: { cameraId },
            });
        });
    };

    const onStepChangeTrans = (step) => {
        dispatch(stopPlayer());
        dispatch(changeStep(step));
    };

    const onMinuteChangeTrans = (minute) => {
        dispatch(changeMinute(minute));

        selectedCameras.forEach((cameraId) => {
            const selectedTimeStamp = DateTime.fromJSDate(selectedDate).set({
                hour: Number(selectedHour),
                minute: Number(minute),
                second: 0,
                millisecond: 0,
            }).ts;

            const actualKey = Object.keys(availableVideoFilesByCameraId[cameraId]).filter((key) => {
                const item = availableVideoFilesByCameraId[cameraId][key];
                const sliceStartTimeStamp = DateTime.fromISO(item.datetime).ts;
                const sliceFinishTimeStamp = sliceStartTimeStamp + item.seconds * 1000;
                return sliceStartTimeStamp <= selectedTimeStamp && sliceFinishTimeStamp > selectedTimeStamp;
            })[0];

            const requestVideoObject = availableVideoFilesByCameraId[cameraId][actualKey];
            const requestTracksObject = availableTracksFilesByCameraId[cameraId][actualKey];

            postRequest({
                url: `${urls.ACCESS_VIDEOFILES_URL}`,
                token,
                dispatch,
                formData: requestVideoObject,
                actionName: getVideos,
                additionalParams: { cameraId },
                // showSpinner: false,
            }).then(() => {
                postRequest({
                    url: `${urls.TRACKS_URL}`,
                    token,
                    dispatch,
                    formData: requestTracksObject,
                    actionName: getTracks,
                    additionalParams: { cameraId, dateTime: actualKey },
                    // showSpinner: false,
                });
            });
        });
    };

    const stopPlayerTrans = () => {
        dispatch(stopPlayer());
    };
    const togglePlayTrans = () => {
        dispatch(togglePlay());
    };

    const videosContextValue = {
        selectedStartTime,
        selectedCameras,
        selectedDate,
        selectedHour,
        selectedMinute,
        sliceDuration,
        plans,
        neuralData,

        changeCurrentProgressTrans,
        togglePlayTrans,
        onHourChangeTrans,
        onMinuteChangeTrans,
        onStepChangeTrans,
        changePlayedTrans,
        changeDateTrans,
        stopPlayerTrans,
        player,
        step,
    };

    return (
        <VideosContext.Provider value={videosContextValue}>
            <Container>
                <ControlPanel />
                <ImagePanel />
            </Container>
        </VideosContext.Provider>
    );
});

export default Videos;

const Container = styled.div`
    width: 100%;
    height: calc(100vh - 50px);
    position: relative;
    display: flex;
    flex-direction: column;
`;
