import React, { useState, useMemo, useRef, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { drawPolygon, drawCircle, drawLine } from '../../../../../tools/draw_tools';

import { Layer, Text, Group } from 'react-konva';

const Layer3D = ({ transform3DToImage, scale, layer3dId, plan, visible, ...props }) => {
    const dispatch = useDispatch();
    const { objectsById, selectedObject, visibleObjects } = useSelector((state) => state.MeasuredCoordObjectsLayerReducer);
    const { t } = useTranslation();

    const geometry = useMemo(() => {
        return Object.keys(objectsById)
            .filter((key) => visibleObjects[key])
            .map((key) => {
                const step = 0.2;
                const obj = objectsById[key];
                const { x, y, dx, dy, dz, azimuth, color } = obj;

                const getAxisXpoint = (x, y, z, dx) => [Math.cos(azimuth) * dx + x, Math.sin(azimuth) * dx + y, z];
                const getAxisYpoint = (x, y, z, dy) => [
                    Math.cos(azimuth + Math.PI / 2) * dy + x,
                    Math.sin(azimuth + Math.PI / 2) * dy + y,
                    z,
                ];
                const getAxisZpoint = (x, y, z, dz) => [x, y, z + dz];

                const tops = [[x, y, 0], getAxisXpoint(x, y, 0, dx), getAxisYpoint(x, y, 0, dy), [x, y, dz]];

                const getImagePoints = (pointsArr) => {
                    return pointsArr
                        .map((point) => {
                            // const transform3dToImage = new Transform3dToImage('point', imageSize, matrix, point);
                            // return transform3dToImage.getImageGeometry();
                            return transform3DToImage('point', point);
                        })
                        .filter((point) => point !== null);
                };

                const getAxesLetters = (pointsArr) => {
                    return pointsArr
                        .map((point, i) => {
                            const letter = i === 0 ? '0' : i === 1 ? 'x' : i === 2 ? 'y' : 'z';
                            return { coord: transform3DToImage('point', point), letter };
                        })
                        .filter((point) => point.coord !== null);
                };

                const imageTops = getImagePoints(tops);

                const vertexes = imageTops.map((point, i) => {
                    return drawCircle({
                        x: point[0],
                        y: point[1],
                        key: `circle${key}++${i}`,
                        draggable: false,
                        radius: 4 / scale.stageScale,
                        color,
                        strokeColor: color,
                        strokeWidth: 2 / scale.stageScale,
                    });
                });

                const letters = getAxesLetters(tops).map((point, i) => {
                    return (
                        <Text
                            key={`text${point[0]}--${i}`}
                            text={point.letter}
                            fill={color}
                            x={point.coord[0] + 15 / scale.stageScale}
                            y={point.coord[1] - 15 / scale.stageScale}
                            fontSize={15 / scale.stageScale}
                        />
                    );
                });

                const axes = ['x', 'y', 'z'].map((line, i) => {
                    const imageLine = transform3DToImage('line', [tops[0], tops[i + 1]]);
                    return imageLine?.length
                        ? drawLine({
                              key: `axis${line}--${i}`,
                              points: [...imageLine[0], ...imageLine[1]],
                              strokeColor: color,
                              strokeWidth: 2 / scale.stageScale,
                          })
                        : null;
                });

                const dimensionalMarks = () => {
                    const points = [
                        { axisLength: dx, foo: getAxisXpoint },
                        { axisLength: dy, foo: getAxisYpoint },
                        { axisLength: dz, foo: getAxisZpoint },
                    ].reduce((acc, item) => {
                        const marks = [];
                        for (let i = step; i < item.axisLength; i = i + step) {
                            const iteration = Math.round(i * 10) / 10;
                            marks.push(item.foo(x, y, 0, iteration));
                        }
                        return [...acc, ...marks];
                    }, []);

                    const imagePoints = getImagePoints(points);

                    return imagePoints.map((point, i) => {
                        return drawCircle({
                            x: point[0],
                            y: point[1],
                            key: `Mark${i}`,
                            draggable: false,
                            radius: 1,
                            color: 'white',
                            // strokeColor: 'white',
                            // strokeWidth: 2 / scale.stageScale,
                        });
                    });
                };

                return (
                    <Group key={`group)<<)${key}`}>
                        {axes}
                        {vertexes}
                        {dimensionalMarks()}
                        {letters}
                    </Group>
                );
            });
    }, [objectsById, scale, visibleObjects, transform3DToImage]);

    return visible ? <Layer key={`MeasuredCoordObjectsLayer3D>>${layer3dId}`}>{geometry}</Layer> : null;
};

export default Layer3D;
