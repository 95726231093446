import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { DetailsList, buildColumns, IColumn, DetailsRow } from 'office-ui-fabric-react/lib/DetailsList';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../constants/theme';
import { GlobalContext } from '../../../../App';
import { ListWrapper, classNames } from './List.styles';

const List = React.memo(({ filteredLocationsList = [], _changeActiveLocationId = () => {} }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { _onLocationClick } = useContext(GlobalContext);
    const { themeLighterAlt, neutralLighterAlt, white } = theme.palette;

    const items = filteredLocationsList.map((location) => {
        const { name, id, description } = location;
        return { name, id, description };
    });

    function myBuildColumns(items: IColumns[]) {
        if (items.length === 0) return [];
        const columns = buildColumns(items);
        // const [thumbnailColumn] = columns.filter((column) => column.name === 'thumbnail');
        // const [deleteCellColumn] = columns.filter((column) => column.name === 'deleteCell');

        // thumbnailColumn.name = 'thumbnail';
        // thumbnailColumn.minWidth = 80;
        // thumbnailColumn.maxWidth = 80;
        // thumbnailColumn.ariaLabel = 'Thumbnail';

        // deleteCellColumn.name = '';
        return columns;
    }

    const onRowClick = (locationId) => () => {
        _onLocationClick(locationId);
    };

    const renderRow = (props) => {
        const customStyles = {
            root: {
                display: 'flex',
                backgroundColor: white,
                cursor: 'pointer',
            },
            cell: {
                display: 'flex',
                width: 'auto',
                alignItems: 'center',
            },
        };

        if (props) {
            if (props.itemIndex % 2 === 0) {
                customStyles.root = { display: 'flex', backgroundColor: themeLighterAlt, cursor: 'pointer' };
            }
            return (
                <div onClick={onRowClick(props.item.id)}>
                    <DetailsRow {...props} styles={customStyles} />
                </div>
            );
        }
        return <DetailsRow {...props} styles={customStyles} />;
    };

    function renderItemColumn(item, index, column) {
        if (!column?.fieldName) return null;

        const fieldContent = item[column.fieldName];

        switch (column.key) {
            // case 'isGeomapped':
            //     return (
            //         <span>
            //             {fieldContent ? (
            //                 <FontIcon iconName="BoxCheckmarkSolid" className={classNames.green} onClick={() => {}} />
            //             ) : (
            //                 <FontIcon iconName="BoxSubtractSolid" className={classNames.red} />
            //             )}
            //         </span>
            //     );

            default:
                return <span>{fieldContent}</span>;
        }
    }

    return (
        <ListWrapper>
            <DetailsList
                items={items}
                onRenderItemColumn={renderItemColumn}
                setKey="set"
                columns={myBuildColumns(items)}
                onRenderRow={renderRow}
                selectionMode={0}
            />
        </ListWrapper>
    );
});

export default List;
