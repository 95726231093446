import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { getSelectedGroup, generateId, generateNameNumber, between } from '../../../../../tools/tools';
import { getColorFromArray, getRandomColor } from '../../../../../tools/draw_tools';

import { createSlice } from '@reduxjs/toolkit';

import { keyBy } from 'lodash';

const initialState = { objectsById: {}, selectedObject: null, visibleObjects: {}, modal: null };

export const PassWaysLayerReducer = createSlice({
    name: 'PassWaysLayerReducer',
    initialState,

    reducers: {
        closeModal: (state, action) => {
            state.modal = null;
        },

        showModal: (state, action) => {
            const modal = {};
            modal.show = true;
            Object.keys(action.payload).forEach((key) => {
                modal[key] = action.payload[key];
            });
            state.modal = modal;
        },

        toggleObjectVisible: (state, action) => {
            const { visibleObjects } = cloneDeep(state);
            const { id } = action.payload;
            if (!visibleObjects[id]) {
                visibleObjects[id] = true;
            } else {
                visibleObjects[id] = false;
            }
            state.visibleObjects = visibleObjects;
        },

        setObjectsById: (state, action) => {
            const { objects } = action.payload;
            if (!objects) return;
            const visibleObjects = {};
            Object.keys(objects).forEach((id) => {
                visibleObjects[id] = true;
            });
            state.objectsById = objects;
            state.visibleObjects = visibleObjects;
        },

        selectObject: (state, action) => {
            const { id, key, object } = action.payload;
            const { selectedObject } = cloneDeep(state);
            if (!id) {
                state.selectedObject = null;
            } else if (selectedObject !== id) {
                state.selectedObject = id;
            }
        },

        dragMoveObject: (state, action) => {
            const { newObjects } = action.payload;
            state.objectsById = newObjects;
        },

        manualObjectChange: (state, action) => {
            const { fieldName, newValue, id } = action.payload;
            const { objectsById } = cloneDeep(state);
            const value = fieldName === 'color' || fieldName === 'marker' ? newValue : Number(newValue);
            const object = objectsById[id];
            object[fieldName] = value;
            state.objectsById = objectsById;
        },

        deleteObject: (state, action) => {
            const { id } = action.payload;
            const { objectsById, visibleObjects } = cloneDeep(state);
            delete objectsById[id];
            delete visibleObjects[id];

            state.objectsById = objectsById;
            state.visibleObjects = visibleObjects;
        },

        addNewObject: (state, action) => {
            const { width, height, scale } = action.payload.plan;

            const { objectsById, visibleObjects } = cloneDeep(state);
            let layer = [];
            Object.keys(objectsById).forEach((id) => {
                layer.push(objectsById[id]);
            });
            const _id = generateId();
            const color = getColorFromArray({ index: layer.length });
            // const color = getRandomColor();
            const newObject = {
                _id,
                marker: `${generateNameNumber(layer, 'Measured coord object', 'marker')}`,
                x: width / (2 * scale),
                y: height / (2 * scale),
                dx: 1,
                dy: 1,
                dz: 2,
                azimuth: 0,
                color,
            };
            objectsById[_id] = newObject;
            visibleObjects[_id] = true;

            state.objectsById = objectsById;
            state.visibleObjects = visibleObjects;
        },
    },
});

export const {
    setObjectsById,
    addNewObject,
    selectObject,
    dragMoveObject,
    deleteObject,
    toggleObjectVisible,
    showModal,
    closeModal,
    manualObjectChange,
} = PassWaysLayerReducer.actions;

export default PassWaysLayerReducer.reducer;
