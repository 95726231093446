import React, { useState, useMemo, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Konva from 'konva';
import { Stage, Layer, Shape, Circle } from 'react-konva';
import { generateId, handleScale, useParentSize } from '../../../tools/tools';
import { PlansContext } from '../plans';
import { GlobalContext } from '../../../App';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { useConstCallback } from '@uifabric/react-hooks';
import styled from 'styled-components/macro';

import URLImage from '../../url_image/url_image';

const ImagePanel = () => {
    const { t } = useTranslation();
    const stageRef = useRef(null);
    const layerRef = useRef(null);
    const mainWrapperRef = useRef(null);
    const [cursor, set_cursor] = useState('auto');
    const [contextMenu, set_contextMenu] = useState({ show: false });
    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const [linesCoords, set_linesCoords] = useState(null);
    const [shapes, set_shapes] = useState(null);
    const [imagePanelSizes, set_imagePanelSizes] = useState(null);
    const [currentCoords, set_currentCoords] = useState({});
    const context = useContext(PlansContext);
    const { planToEdit, tool, controlDictionary, activeGroup, _onUpdateGeometry, _onCreateNewElement, _toggleDraw } = context;
    const { windowSize } = useContext(GlobalContext);

    // const parentSize = useParentSize(mainWrapperRef);

    // useEffect(() => {
    //     console.log('>>>', parentSize);
    // }, [parentSize, windowSize]);

    useEffect(() => {
        if (!tool.id) {
            set_cursor('auto');
        } else if (tool.id === 'zoom_in') {
            set_cursor('zoom-in');
        } else if (tool.id === 'zoom_out') {
            set_cursor('zoom-out');
        } else if (tool.id === 'text_edit') {
            set_cursor('text');
        } else if (controlDictionary[tool.id].canDraw) {
            set_cursor('crosshair');
        } else {
            set_cursor('auto');
        }
    }, [tool, controlDictionary]);

    const generateCurrentCoords = (e) => {
        const p = e.currentTarget.getPointerPosition();
        const x = e.currentTarget.attrs.x ? p.x - e.currentTarget.attrs.x : p.x;
        const y = e.currentTarget.attrs.y ? p.y - e.currentTarget.attrs.y : p.y;
        return { x: x / scale.stageScale, y: y / scale.stageScale };
    };

    const _onMouseMove = (e) => {
        if ((tool.id && controlDictionary[tool.id].canDraw) || tool.id === 'anchors_edit') {
            const { x, y } = generateCurrentCoords(e);
            set_currentCoords({ x, y });
        }
    };

    const _onStageDblClick = (e) => {
        if (tool.id === 'zoom_in' || tool.id === 'zoom_out') return;
        e.evt.preventDefault();
        const stageScale =
            mainWrapperRef.current.clientWidth /
            e.currentTarget.children.filter((item) => item.attrs.id === 'imageLayer')[0].children[0].attrs.image.naturalWidth;
        set_scale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    };

    const _onStageWheel = (e) => {
        e.evt.preventDefault();
        set_scale(handleScale({ e, isWheel: true, tool }));
    };

    const _onStageClick = (e) => {
        const { x, y } = generateCurrentCoords(e);
        const newLinesCoords = linesCoords ? [...linesCoords] : [];

        if (tool.id === 'zoom_in' || tool.id === 'zoom_out') {
            set_scale(handleScale({ e, isWheel: false, tool }));
        } else if (tool.id && controlDictionary[tool.id].canDraw && activeGroup) {
            newLinesCoords.push([x, y]);
            set_linesCoords(newLinesCoords);
        } else if (tool.id === 'anchors_edit') {
        }
    };

    const onAnchorMouseOver = (e) => {
        e.evt.preventDefault();
        // e.target.attrs.strokeWidth = 4;
        set_cursor('pointer');
    };
    const onAnchorMouseOut = (e) => {
        e.evt.preventDefault();
        // e.target.attrs.strokeWidth = 4;
        set_cursor('auto');
    };

    const onUpdateGeometry = (args) => (e) => {
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const p = stage.getPointerPosition();
        const x = stage.attrs.x ? p.x - stage.attrs.x : p.x;
        const y = stage.attrs.y ? p.y - stage.attrs.y : p.y;
        const newCoords = [x / scale.stageScale, y / scale.stageScale];
        _onUpdateGeometry({ ...args, newCoords });
    };

    const geometryLayers = useMemo(() => {
        const result = { common: [], editable: [] };

        const pushToResult = (isGroupEditable, shapes, addType) => {
            const wrapShapes = (shapes) => {
                return <Layer key={'bbb'}>{shapes}</Layer>;
            };
            if (!isGroupEditable && addType === 'unshift') {
                result.common.unshift(wrapShapes(shapes));
            } else if (isGroupEditable && tool.id === 'anchors_edit') {
                shapes.push(anchors());
                result.editable.push(wrapShapes(shapes));
            } else if (isGroupEditable) {
                result.editable.push(wrapShapes(shapes));
            }
        };

        const anchors = () => {
            const result = [];
            planToEdit.measured_segment.coords.forEach((point, i) => {
                result.push(
                    <Circle
                        id={`__:${i}`}
                        key={`++:${i}`}
                        draggable
                        // onContextMenu={_onShapeContextMenu(line.id, i)}
                        onMouseOver={onAnchorMouseOver}
                        onMouseOut={onAnchorMouseOut}
                        onDragMove={onUpdateGeometry({ pointIndex: i })}
                        x={point[0]}
                        y={point[1]}
                        radius={10 / scale.stageScale}
                        // opacity={0.6}
                        // fill={'red'}
                        stroke={'red'}
                        strokeWidth={2 / scale.stageScale}
                    />
                );
            });

            return result;
        };

        const { color } = controlDictionary.calibration_cut;
        if (planToEdit.measured_segment.coords.length >= 2) {
            const shapes = [
                <Shape
                    id={'line.id'}
                    key={'line.id'}
                    // onContextMenu={_onShapeContextMenu(layer, line.id)}
                    // onMouseOver={_onShapeMouseOver(layer)}
                    // onMouseOut={_onShapeMouseOut(layer)}
                    sceneFunc={(context, shape) => {
                        context.beginPath();
                        context.moveTo(...planToEdit.measured_segment.coords[0]);
                        context.lineTo(...planToEdit.measured_segment.coords[1]);
                        //   context.quadraticCurveTo(150, 100, 260, 170);
                        //   context.closePath();
                        context.fillStrokeShape(shape);
                    }}
                    // fill="#00D2FF"
                    stroke={color}
                    strokeWidth={2 / scale.stageScale}
                />,
            ];

            pushToResult(true, shapes, 'unshift');
        }

        return result;
    }, [scale.stageScale, controlDictionary, planToEdit, tool]);

    useEffect(() => {
        const shapes = [];
        if (!linesCoords || !activeGroup) {
        } else {
            const args = {
                groupId: activeGroup,
                elementId: `element:${activeGroup}:${generateId()}`,
            };

            if (linesCoords.length >= 2) {
                const newCoords = [...linesCoords];
                // newCoords.pop();
                _onCreateNewElement({ ...args, newCoords });
                set_linesCoords(null);
                set_shapes(null);
                _toggleDraw(null);
            } else {
                shapes.push(
                    <Shape
                        key={`shape`}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(...linesCoords[0]);
                            context.lineTo(currentCoords.x, currentCoords.y);
                            context.fillStrokeShape(shape);
                        }}
                        // fill="#00D2FF"
                        stroke="red"
                        strokeWidth={2 / scale.stageScale}
                    />
                );
            }
        }

        set_shapes(shapes);
    }, [currentCoords, linesCoords]);

    const onHideContextualMenu = useConstCallback(() => set_contextMenu({ show: false }));

    useEffect(() => {
        if (mainWrapperRef.current) {
            set_imagePanelSizes({ width: mainWrapperRef.current.offsetWidth, height: mainWrapperRef.current.offsetHeight });
        }
    }, [mainWrapperRef, windowSize]);

    if (!planToEdit.image_url && !planToEdit.image) return <div></div>;

    return (
        <MainWrapper ref={mainWrapperRef} cursor={cursor}>
            <TextContent></TextContent>

            <ContextualMenu
                // styles={menuStyles}
                items={contextMenu.menuItems}
                hidden={!contextMenu.show}
                target={contextMenu.cursor}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />

            {imagePanelSizes ? (
                <Stage
                    ref={stageRef}
                    width={imagePanelSizes.width}
                    height={imagePanelSizes.height}
                    draggable
                    onMouseMove={_onMouseMove}
                    onClick={_onStageClick}
                    onDblClick={_onStageDblClick}
                    onWheel={_onStageWheel}
                    scaleX={scale.stageScale}
                    scaleY={scale.stageScale}
                    x={scale.stageX}
                    y={scale.stageY}
                >
                    <Layer id={'imageLayer'}>
                        <URLImage src={planToEdit.image_url || planToEdit.image} />
                    </Layer>
                    {geometryLayers.common}
                    {geometryLayers.editable}
                    <Layer ref={layerRef} id={'11'} clearBeforeDraw>
                        {shapes}
                    </Layer>
                </Stage>
            ) : null}
        </MainWrapper>
    );
};

export default ImagePanel;

const MainWrapper = styled.div`
    cursor: ${(p) => p.cursor};
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
`;

const TextContent = styled.svg`
    text-rendering: optimizeSpeed;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    width: 100%;
    height: 100%;
`;
