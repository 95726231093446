import React from 'react';
import styled from 'styled-components/macro';

const MainCP = React.memo(({ children }) => {
    return <MainWrapper>{children}</MainWrapper>;
});

export default MainCP;

const MainWrapper = styled.div`
    width: 100%;
    position: relative;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    padding: 5px;
    background-color: rgb(240, 242, 245);
    height: calc(100vh - 50px);
    overflow: hidden;
`;
