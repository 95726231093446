import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useConstCallback } from '@uifabric/react-hooks';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { useTranslation } from 'react-i18next';

const AddNewRawCameraImageModal = React.memo(({ modalData, ...props }) => {
    const [rawImage, set_rawImageFile] = useState({});
    const { t } = useTranslation();
    const [theme] = useState(getTheme());
    const [camera_marker, set_camera_marker] = useState('');

    const onChange = useConstCallback((event, newValue) => {
        const IsMatch = (str) => /^$|^[A-zА-яё\d\/_]+$/.test(str);
        if (IsMatch(newValue)) {
            set_camera_marker(newValue || '');
        }
    });

    const onOKClick = (options) => () => {
        props._onOKClick(options);
    };

    const _onFileChange = (e) => {
        const file = [...e.target.files][0];

        if (file.size > 100 * 1024 * 1024) {
            set_rawImageFile({ ...rawImage, file: null });
            alert(t('File too large'));
        } else {
            var URL = window.webkitURL || window.URL;
            var url = URL.createObjectURL(file);
            set_rawImageFile({ ...rawImage, file, url });
        }
    };

    const disabled = camera_marker.length < 2 || !rawImage.file;

    return (
        <MainWrapper>
            <input accept="image/*" onChange={_onFileChange} style={{ display: 'none' }} id="raised-button-file" type="file" />
            <LabelWrapper>
                <FileLabel className="FileLabel" htmlFor="raised-button-file">
                    <ChooseFile component="span" bgColor={theme.palette.themePrimary} hoverBgColor={theme.palette.themeDark}>
                        {t('Select raw image')}
                    </ChooseFile>
                </FileLabel>
                <StTextField value={camera_marker} onChange={onChange} autoFocus />
                <FileName>{rawImage.file && rawImage.file.name}</FileName>
            </LabelWrapper>

            <img src={rawImage.url}></img>
            <Footer>
                <PrimaryButton
                    text={t('Add')}
                    disabled={disabled}
                    onClick={onOKClick({ camera_marker, id: modalData.options.id, file: rawImage.file })}
                />
            </Footer>
        </MainWrapper>
    );
});

export default AddNewRawCameraImageModal;

const Footer = styled.div`
    margin-top: 20px;
    /* margin-bottom: 20px; */
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const MainWrapper = styled.div`
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
        object-fit: contain;
    }
`;

const StTextField = styled(TextField)`
    min-width: 300px;
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const FileLabel = styled.label`
    display: flex;
    align-items: center;
    margin-right: 10px;
    /* width: 100%; */
`;

const FileName = styled.span`
    margin-left: 10px;
`;

const ChooseFile = styled.section`
    -webkit-tap-highlight-color: transparent;
    -webkit-box-direction: normal;
    background: transparent;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    text-overflow: ellipsis;
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 0px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding-left: 16px;
    height: 32px;
    background-color: ${(p) => p.bgColor};
    color: rgb(255, 255, 255);
    user-select: none;
    outline: transparent;
    /* border-width: 1px;
    border-style: solid;
    border-image: initial; */
    text-decoration: none;
    border-radius: 2px;
    font-family: inherit;
    flex-shrink: 1;
    :hover {
        background-color: ${(p) => p.hoverBgColor};
    }
`;
