import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { urls } from '../constants/urls';

/**
 * Функция маппинга свойств с фронта на бэк.
 *
 * @param mapping Словарь для маппинга.
 * @param item Объект, свойства которого нужно переименовать.
 */
export const mapFront2Back = (mapping, item) => {
    const result = {};
    mapping.forEach((back, front) => {
        if (item[front] !== undefined) {
            result[back] = item[front];
        }
    });
    return result;
};

/**
 * Функция маппинга свойств с бэка на фронт.
 *
 * @param mapping Словарь для маппинга.
 * @param item Объект, свойства которого нужно переименовать.
 */
export const mapBack2Front = (mapping, item) => {
    const result = {};
    mapping.forEach((back, front) => {
        if (item[back] !== undefined) {
            result[front] = item[back];
        }
    });
    return result;
};

/**
 * Редирект на сервер аутентификации.
 */
export const redirectToAuth = () => window.location.replace(`${urls.AUTH_URL}?next=${window.location.origin + window.location.pathname}`);

/**
 * Пытаемся найти токен в урле и в LocalStorage.
 */
export const findToken = () => {
    let token;
    const searchParams = new window.URLSearchParams(document.location.search);
    if (searchParams.get('token')) {
        token = searchParams.get('token');
        token && localStorage.setItem('xtoken', token);
        removeTokenFromUrl();
    } else {
        token = localStorage.getItem('xtoken');
    }
    return token;
};

/**
 * Удаляем пришедший нам токен из урла.
 */
const removeTokenFromUrl = (): void => {
    window.location.href = window.location.origin + window.location.pathname;
};

export const generateCurrentCoordsInPixels = (e) => {
    const p = e.currentTarget.getPointerPosition();
    const x = e.currentTarget.attrs.x ? p.x - e.currentTarget.attrs.x : p.x;
    const y = e.currentTarget.attrs.y ? p.y - e.currentTarget.attrs.y : p.y;
    return { x: x / e.currentTarget.attrs.scaleX, y: y / e.currentTarget.attrs.scaleY };
};

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

export function useNodeSize(nodeRef) {
    const [nodeSize, setNodeSize] = useState(null);

    function handleResize(nodeRef) {
        if (nodeRef.current) {
            setNodeSize({ width: nodeRef.current.offsetWidth, height: nodeRef.current.offsetHeight });
        }
    }

    useEffect(() => {
        handleResize(nodeRef);
    }, [nodeRef]);

    return nodeSize;
}

export const handleScale = (args) => {
    const { e, isWheel, tool } = args;
    const wheelScaleCoeff = 1.05;
    const clickScaleCoeff = 1.3;

    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    let newScale = oldScale;
    if (isWheel) {
        newScale = e.evt.deltaY < 0 ? oldScale * wheelScaleCoeff : oldScale / wheelScaleCoeff;
    } else {
        newScale = tool.id === 'zoom_in' ? oldScale * clickScaleCoeff : oldScale / clickScaleCoeff;
    }

    return {
        stageScale: newScale,
        stageX: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        stageY: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    };
};

export const between = (point1, point2, anchorCoord) => {
    const dxc = anchorCoord[0] - point1[0];
    const dyc = anchorCoord[1] - point1[1];
    const dxl = point2[0] - point1[0];
    const dyl = point2[1] - point1[1];
    const cross = dxc * dyl - dyc * dxl;
    let result = false;
    if (Math.abs(dxl) >= Math.abs(dyl))
        result =
            dxl > 0
                ? point1[0] <= anchorCoord[0] && anchorCoord[0] <= point2[0]
                : point2[0] <= anchorCoord[0] && anchorCoord[0] <= point1[0];
    else
        result =
            dyl > 0
                ? point1[1] <= anchorCoord[1] && anchorCoord[1] <= point2[1]
                : point2[1] <= anchorCoord[1] && anchorCoord[1] <= point1[1];

    return result && Math.abs(cross) < 500;
};

export const getSelectedGroup = (controlDictionary) => {
    return Object.keys(controlDictionary).filter((key) => {
        return controlDictionary[key].selected;
    })[0];
};

export const backToFrontConverter = (structure) => {
    const result = cloneDeep(structure);
    console.log(result);
    const calibration_geometry = {};
    const dict = ['parallel_lines', 'vertical_lines', 'areas', 'anchor_points', 'equal_lines', 'perpendicular_lines'];
    dict.forEach((key) => {
        if (structure.geometry[key]) {
            calibration_geometry[key] = [
                {
                    id: `layer:${key}:HBLIibl`,
                    name: 'Layer 1',
                    elements: [
                        {
                            id: 'element:parallel_lines:gvg53645^%$',
                            coords: [
                                [229.89483696735653, 528.8338769376112],
                                [300.2708074675677, 122.99911371972674],
                            ],
                        },
                        {
                            id: 'element:parallel_lines:*^&*^kjk^%$',
                            coords: [
                                [786.646959146805, 460.8037721207404],
                                [448.8423007457913, 108.9239196196845],
                            ],
                        },
                    ],
                },
            ];
        } else {
            calibration_geometry[key] = [];
        }
    });
    return result;
};

export const generateId = (n = 8) => {
    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    };
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    return new Array(n)
        .fill()
        .map(() => chars[getRandomInt(0, chars.length)])
        .join('');
};

export const generateNameNumber = (arr, word, field = 'name') => {
    let result = '';
    if (!arr || arr.length === 0) {
        result = `${word} 1`;
    } else {
        const nameNumbers = arr
            .filter((item) => item[field].includes(`${word} `))
            .map((item) => {
                return Number(item[field].replace(/[^\d.]/gi, ''));
            });
        const maxNumber = Math.max(...nameNumbers);
        result = `${word} ${maxNumber + 1}`;
    }
    return result;
};

export const parseQuery = (queryString) => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

export const blockSize = (parentSize, block, borderWidth, style = {}) => {
    const width =
        (parentSize.width - 10) * block.rel_width - Number(style.paddingLeft || 0) - Number(style.paddingRight || 0) - 2 * borderWidth;
    const height = width * block.ratio - 2 * borderWidth;
    const ratio = `1 x ${block.ratio}`;
    const marginLeft = Number(style.paddingLeft || 0);
    const marginRight = Number(style.paddingRight || 0);
    const marginTop = Number(style.paddingTop || 0);
    const marginBottom = Number(style.paddingBottom || 0);
    return {
        ratio,
        width,
        height,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
    };
};

export const subBlockSize = (parentSize, block, borderWidth, style = {}) => {
    const width = parentSize.width * block.width - Number(style.paddingLeft || 0) - Number(style.paddingRight || 0) - 2 * borderWidth;
    const height = parentSize.height * block.height - 2 * borderWidth;
    const left = parentSize.width * block.left + Number(style.paddingLeft || 0);
    const top = parentSize.height * block.top + Number(style.paddingTop || 0);
    const ratio = `1 x ${(height / width).toFixed(1)}`;
    return {
        ratio,
        width,
        height,
        left,
        top,
    };
};

export const isIndexesEqual = (obj1, obj2) => {
    if (!obj1 || !obj2) return false;
    if (obj1.block === obj2.block && obj1.subBlock === obj2.subBlock) {
        return true;
    } else {
        return false;
    }
};

export const calculateSizes = (position, prototype, parentPrototype, constructorSize, style = {}, isWrapper) => {
    const parentWidth = constructorSize.width * parentPrototype.rel_width;
    const parentHeight = constructorSize.width * parentPrototype.rel_width * parentPrototype.ratio;
    const paddingTop = `${Number(style.paddingTop || 0)}px`;
    const paddingBottom = `${Number(style.paddingBottom || 0)}px`;
    const paddingLeft = `${Number(style.paddingLeft || 0)}px`;
    const paddingRight = `${Number(style.paddingRight || 0)}px`;
    const borderRadius = `${Number(style.borderRadius || 0)}px`;

    if (position === 'absolute') {
        const left = `${parentWidth * prototype.left}px`;
        const top = `${parentHeight * prototype.top}px`;
        const width = `${parentWidth * prototype.width}px`;
        const height = `${parentHeight * prototype.height + Number(style.paddingTop || 0)}px`;
        return {
            left,
            top,
            width,
            height,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight,
            borderRadius,
        };
    } else {
        const width = `${parentWidth}px`;
        const height = `${parentHeight + Number(style.paddingTop || 0)}px`;
        return {
            left: 0,
            top: 0,
            width,
            height,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight,
            borderRadius,
        };
    }
};

export const arraymove = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
};

export const convertWhiteLabelColor = (str) => {
    if (str.match(/rgb/i) !== null) {
        return RGBToHSL(str);
    } else if (str.match(/\#/i) !== null) {
        return RGBToHSL(hexToRGB(str));
    }
};

function RGBToHSL(rgb) {
    let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
    rgb = rgb.substr(4).split(')')[0].split(sep);

    for (let R in rgb) {
        let r = rgb[R];
        if (r.indexOf('%') > -1) rgb[R] = Math.round((r.substr(0, r.length - 1) / 100) * 255);
    }

    // Make r, g, and b fractions of 1
    let r = rgb[0] / 255,
        g = rgb[1] / 255,
        b = rgb[2] / 255;

    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    // Calculate hue
    // No difference
    if (delta === 0) h = 0;
    // Red is max
    else if (cmax === r) h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax === g) h = (b - r) / delta + 2;
    // Blue is max
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0) h += 360;

    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100);

    const step = 7;

    return {
        themePrimary: HSLToHex('hsl(' + h + ',' + s + '%,' + l.toFixed(1) + '%)'),
        themeLighterAlt: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 5 * step).toFixed(1) + '%)'),
        themeLighter: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 4 * step).toFixed(1) + '%)'),
        themeLight: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 3 * step).toFixed(1) + '%)'),
        themeTertiary: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 2 * step).toFixed(1) + '%)'),
        themeSecondary: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l + 1 * step).toFixed(1) + '%)'),
        themeDarkAlt: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l - 1 * step).toFixed(1) + '%)'),
        themeDark: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l - 2 * step).toFixed(1) + '%)'),
        themeDarker: HSLToHex('hsl(' + h + ',' + s + '%,' + Number(l - 3 * step).toFixed(1) + '%)'),
    };
    // return 'hsl(' + h + ',' + s + '%,' + l + '%)';
}

function HSLToHex(hsl) {
    let sep = hsl.indexOf(',') > -1 ? ',' : ' ';
    hsl = hsl.substr(4).split(')')[0].split(sep);

    let h = hsl[0],
        s = hsl[1].substr(0, hsl[1].length - 1) / 100,
        l = hsl[2].substr(0, hsl[2].length - 1) / 100;

    // Strip label and convert to degrees (if necessary)
    if (h.indexOf('deg') > -1) h = h.substr(0, h.length - 3);
    else if (h.indexOf('rad') > -1) h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI));
    else if (h.indexOf('turn') > -1) h = Math.round(h.substr(0, h.length - 4) * 360);
    if (h >= 360) h %= 360;

    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
        m = l - c / 2,
        r = 0,
        g = 0,
        b = 0;

    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
}

function hexToRGB(h) {
    let r = 0,
        g = 0,
        b = 0;

    // 3 digits
    if (h.length === 4) {
        r = '0x' + h[1] + h[1];
        g = '0x' + h[2] + h[2];
        b = '0x' + h[3] + h[3];

        // 6 digits
    } else if (h.length === 7) {
        r = '0x' + h[1] + h[2];
        g = '0x' + h[3] + h[4];
        b = '0x' + h[5] + h[6];
    }

    return 'rgb(' + +r + ',' + +g + ',' + +b + ')';
}
