import React, { useState, useMemo, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { CalibrationContext } from '../calibration';

import ContextMenu from '../../context_menu/context_menu';
import Eye from '../../eye/eye';

const Layers = React.memo(({ layers, groupId, ...props }) => {
    const { t } = useTranslation();
    const { _onEyeClick, _onLayerClick, layersById, activeLayer, _showModal, calibration_geometry } = useContext(CalibrationContext);

    const onEyeClick = (layerId) => {
        _onEyeClick(layerId);
    };
    const onLayerClick = (layerId) => (e) => {
        _onLayerClick(layerId, groupId);
    };

    const showModal = (modalType, layer, allLayers) => {
        _showModal({ modalType, width: '300px', options: { layer, allLayers } });
    };

    const drawLayers = layers.map((layer) => {
        const eyeVisible = layersById[layer.id].visible;
        const layerBgColor = activeLayer === layer.id ? '#ccc' : 'transparent';
        const menuItems = [
            {
                key: 'rename',
                text: t('Rename layer'),
                onClick: () => {
                    showModal('rename_layer', layer, calibration_geometry[layersById[layer.id].groupId]);
                },
            },
        ];
        return (
            <ContextMenu key={layer.id} rightClick={true} menuItems={menuItems} margin={'0'} cursor={true}>
                <LayerWrapper layerBgColor={layerBgColor}>
                    <Eye eyeVisible={eyeVisible} id={layer.id} onEyeClick={onEyeClick} />
                    <LayerMain onClick={onLayerClick(layer.id)}>{layer.name}</LayerMain>
                </LayerWrapper>
            </ContextMenu>
        );
    });

    return <MainWrapper>{drawLayers}</MainWrapper>;
});

export default Layers;

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const LayerMain = styled.div`
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
`;
const LayerWrapper = styled.div`
    margin-left: 6px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background-color: ${(p) => p.layerBgColor};
    padding-left: 20px;
`;
