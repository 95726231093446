import React, { useState, useMemo, useRef, useEffect, useContext, useCallback } from 'react';
import Split from 'react-split';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import ReactPlayer from 'react-player';

import useImage from 'use-image';
import { Stage, Layer, Star, Text, Image, Line, Rect, Shape, Circle, Group } from 'react-konva';
import { generateId, handleScale, generateCurrentCoordsInPixels } from '../../../tools/tools';
import { drawPolygon, drawCircle, drawLine } from '../../../tools/draw_tools';
import { GlobalContext } from '../../../App';
import { PerspectiveContext } from '../perspective';

import styled from 'styled-components/macro';
import Dock from './dock';

const ImagePanel = ({ layersDraw, ...props }) => {
    const playerRef = useRef();
    const {
        CAMERAS_LAYER_TYPE,
        planLayers,
        visiblePlanLayers,
        _showModal,
        tool,
        selectedCamerasIds,
        visibleCamerasIds,
        activePlanId,
        selectedPlanLayer,
        plans,
        plansOptions,
        camerasById,
        selectedList,
        _onCameraMarkerClick,
        _delayedChangeCamerasById,
        _toggleTool,
        _set_selectedListState,
        _manageChangedCamerasIds,
        _handleObjectsChange,
    } = useContext(PerspectiveContext);
    const { windowSize } = useContext(GlobalContext);

    const { t } = useTranslation();
    const stageRef = useRef(null);
    const stageWrapperRef = useRef(null);
    const mainWrapperRef = useRef(null);
    const [cursor, set_cursor] = useState('auto');
    const [stageSize, set_stageSize] = useState({
        width: window.innerWidth - 346,
        height: window.innerHeight * 0.8,
    });

    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const [selectedLayer, set_selectedLayer] = useState(selectedPlanLayer);
    const [stageDraggable, set_stageDraggable] = useState(true);
    const [canTransform, set_canTransform] = useState(false);
    const [initialPos, set_initialPos] = useState(0);
    const [initialZoom, set_initialZoom] = useState(1);
    const [imagePanelSizes, set_imagePanelSizes] = useState(null);
    const [initialAzimuth, set_initialAzimuth] = useState(1);
    const [linesCoords, set_linesCoords] = useState(null);
    const [currentCoords, set_currentCoords] = useState({});
    const [splitSizes, set_splitSizes] = useState([]);

    const plan = plans.filter((item) => item.id === activePlanId)[0];
    const [image] = useImage(plan?.image_url || plan?.image);
    const planOpacity = plansOptions[activePlanId] && plansOptions[activePlanId].opacity ? plansOptions[activePlanId].opacity : 100;
    const planDarkness = plansOptions[activePlanId] && plansOptions[activePlanId].darkness ? plansOptions[activePlanId].darkness : 0;
    const planLightness = plansOptions[activePlanId] && plansOptions[activePlanId].lightness ? plansOptions[activePlanId].lightness : 0;

    useEffect(() => {
        set_selectedLayer(selectedPlanLayer);
    }, [selectedPlanLayer]);

    useEffect(() => {
        playerRef?.current && console.log('>>>', playerRef.current.player.player);
    }, [playerRef?.current?.player?.player]);

    useEffect(() => {
        _manageChangedCamerasIds();
    }, [camerasById]);

    useEffect(() => {
        checkSize();
        window.addEventListener('resize', checkSize);
        return () => {
            window.removeEventListener('resize', checkSize);
        };
    }, []);

    useEffect(() => {
        if (stageWrapperRef.current) {
            set_imagePanelSizes({
                width: stageWrapperRef.current.offsetWidth,
                height: stageWrapperRef.current.offsetHeight,
            });
        }
    }, [stageWrapperRef, windowSize, splitSizes]);

    const checkSize = () => {
        const { offsetWidth: width, offsetHeight: height } = stageWrapperRef.current;
        set_stageSize({ width, height });
    };

    const onSplitDrag = (sizes) => {
        checkSize();
        set_splitSizes(sizes);
    };

    const camerasList = useMemo(() => {
        return Object.keys(camerasById)
            .map((key) => camerasById[key])
            .filter((item) => item.perspective_profile.basic_perspective_geometry && visibleCamerasIds[item.id]);
    }, [camerasById, visibleCamerasIds]);

    const deselectedList = useMemo(() => {
        return camerasList.filter((item) => !selectedCamerasIds.includes(item.id));
    }, [camerasList, selectedCamerasIds]);

    const selectedRef = useRef(null);

    const set_selectedList = useCallback((arr) => {
        selectedRef.current = arr;
        _set_selectedListState(arr);
    }, []);

    useEffect(() => {
        set_selectedList([...camerasList.filter((item) => selectedCamerasIds.includes(item.id))]);
    }, [camerasList, selectedCamerasIds]);

    useEffect(() => {
        if (!canTransform && selectedList?.length !== 0 && Object.keys(currentCoords).length) {
            // console.log(currentCoords);
            // delayedUpdateStore(selectedList);
            _delayedChangeCamerasById(selectedList);
        }
    }, [canTransform]);

    const delayedUpdateStore = useCallback(
        debounce((selectedList) => {
            console.log('+++');
            _delayedChangeCamerasById(selectedList);
        }, 100),
        []
    );

    const generateCurrentCoords = (e) => {
        const p = e.currentTarget.getPointerPosition();
        const x = e.currentTarget.attrs.x ? p.x - e.currentTarget.attrs.x : p.x;
        const y = e.currentTarget.attrs.y ? p.y - e.currentTarget.attrs.y : p.y;
        return { x: x / scale.stageScale, y: y / scale.stageScale };
    };

    const _onMouseMove = (e) => {
        const { x, y } = generateCurrentCoords(e);
        // set_currentCoords({ x, y });
    };

    const _onStageDblClick = (e) => {
        if (tool.id === 'zoom_in' || tool.id === 'zoom_out' || !image) return;
        e.evt.preventDefault();
        const stageScale =
            stageWrapperRef.current.clientWidth /
            e.currentTarget.children.filter((item) => item.attrs.id === 'imageLayer')[0].children[0].attrs.image.naturalWidth;
        set_scale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    };

    const showModal = (modalType, point) => {
        _showModal({ modalType, width: '400px', options: { ...point } });
    };

    const _onShapeMouseOver = (camera) => (e) => {
        if (!selectedCamerasIds.includes(camera.id)) return;
        e.evt.preventDefault();
        set_stageDraggable(false);
    };

    const _onShapeMouseMove = (camera) => (e) => {
        if (!selectedCamerasIds.includes(camera.id)) return;
        e.evt.preventDefault();

        const getDistance = (x1, y1, x2, y2) => {
            return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
        };
        const getAzimuth = (x1, y1, x2, y2) => {
            return -Math.atan2(y2 - y1, x2 - x1);
        };

        if (canTransform) {
            const stage = e.target.getStage();
            const p = stage.getPointerPosition();
            const x = stage.attrs.x ? p.x - stage.attrs.x : p.x;
            const y = stage.attrs.y ? p.y - stage.attrs.y : p.y;
            const { x_offset, y_offset } = camera.perspective_profile;

            const scaled_x_offset = x_offset * plan.scale;
            const scaled_y_offset = y_offset * plan.scale;

            const mapped_y_offset = plan.height - scaled_y_offset;

            const dist1 = getDistance(initialPos.x / scale.stageScale, initialPos.y / scale.stageScale, scaled_x_offset, mapped_y_offset);
            const dist2 = getDistance(scaled_x_offset, mapped_y_offset, x / scale.stageScale, y / scale.stageScale);
            const newZoffset = initialZoom + (dist2 - dist1) / plan.scale;

            const az1 = getAzimuth(scaled_x_offset, mapped_y_offset, initialPos.x / scale.stageScale, initialPos.y / scale.stageScale);
            const az2 = getAzimuth(scaled_x_offset, mapped_y_offset, x / scale.stageScale, y / scale.stageScale);
            const newAzimuth = initialAzimuth + (az2 - az1);

            if (tool.id === 'scale') {
                const newCameras = selectedList.map((camera) => {
                    const perspective_profile = {
                        ...camera.perspective_profile,
                        z_offset: newZoffset,
                    };
                    return { ...camera, perspective_profile };
                });
                set_selectedList(newCameras);
            } else if (tool.id === 'rotate') {
                const newCameras = selectedList.map((camera) => {
                    const perspective_profile = {
                        ...camera.perspective_profile,
                        angleh: newAzimuth,
                    };
                    return { ...camera, perspective_profile };
                });
                set_selectedList(newCameras);
            }
            set_currentCoords({ x, y });
        }
    };

    const _onShapeMouseOut = (camera) => (e) => {
        if (!selectedCamerasIds.includes(camera.id)) return;
        e.evt.preventDefault();
        set_stageDraggable(true);
        set_canTransform(false);
    };

    const _onStageWheel = (e) => {
        e.evt.preventDefault();
        set_scale(handleScale({ e, isWheel: true, tool }));
    };
    const onMouseDown = (camera) => (e) => {
        if (!selectedCamerasIds.includes(camera.id)) return;
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const p = stage.getPointerPosition();
        const x = stage.attrs.x ? p.x - stage.attrs.x : p.x;
        const y = stage.attrs.y ? p.y - stage.attrs.y : p.y;
        set_stageDraggable(false);
        set_initialPos(() => ({
            x,
            y,
        }));
        set_initialZoom(() => camera.perspective_profile.z_offset);
        set_initialAzimuth(() => camera.perspective_profile.angleh);
        set_canTransform(true);
    };

    const onMouseUp = (e) => {
        set_canTransform(false);
        set_stageDraggable(true);
    };

    const setStageDraggable = (isDraggable) => {
        set_stageDraggable(isDraggable);
    };
    const setCursor = (cursor) => {
        set_cursor(cursor);
    };

    const _onStageMouseUp = (e) => {
        e.evt.preventDefault();
        console.log('GGGG');
    };

    const onCircleMouseOver = (camera) => (e) => {
        if (!selectedCamerasIds.includes(camera.id)) return;
        e.evt.preventDefault();
        set_cursor('move');
    };
    const onCircleMouseOut = (camera) => (e) => {
        if (!selectedCamerasIds.includes(camera.id)) return;
        e.evt.preventDefault();
        set_cursor('auto');
    };

    const onDragMove = (camera) => (e) => {
        if (!selectedCamerasIds.includes(camera.id)) return;
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const p = stage.getPointerPosition();
        const x = stage.attrs.x ? p.x - stage.attrs.x : p.x;
        const y = stage.attrs.y ? p.y - stage.attrs.y : p.y;
        const newCoords = [x / scale.stageScale, y / scale.stageScale];
        const newCameras = selectedList.map((camera) => {
            const perspective_profile = {
                ...camera.perspective_profile,
                x_offset: newCoords[0] / plan.scale,
                y_offset: (plan.height - newCoords[1]) / plan.scale,
            };
            return { ...camera, perspective_profile };
        });
        set_currentCoords({ x, y });
        set_selectedList(newCameras);
    };

    const _onShapeClick = (camera) => (e) => {
        const list = selectedRef.current;
        if (selectedCamerasIds.includes(camera.id)) return;
        e.evt.preventDefault();
        _onCameraMarkerClick(camera.id, list);
        _toggleTool(null);
    };

    const makeGeometry = (arr) => {
        const result = [];
        arr.forEach((item, i) => {
            const selected = selectedCamerasIds.includes(item.id);
            const { x_offset, y_offset, z_offset, angleh } = item.perspective_profile;
            const scaled_x_offset = x_offset * plan.scale;
            const scaled_y_offset = y_offset * plan.scale;

            const dashLines = [];
            const polygon = item.perspective_profile.basic_perspective_geometry.areas_3d[0].elements[0].coords.map((coord, i) => {
                const scaled_coord = coord.map((item) => item * plan.scale);
                const x = (scaled_coord[0] * Math.cos(angleh) - scaled_coord[1] * Math.sin(angleh)) * z_offset + scaled_x_offset;
                const y =
                    plan.height - ((scaled_coord[0] * Math.sin(angleh) + scaled_coord[1] * Math.cos(angleh)) * z_offset + scaled_y_offset);
                dashLines.push(
                    <Line
                        key={`lineOO${i}`}
                        points={[scaled_x_offset, plan.height - scaled_y_offset, x, y]}
                        stroke={'#555'}
                        strokeWidth={2 / scale.stageScale}
                        dash={[8, 2]}
                        opacity={selected ? 0.2 : 0.05}
                    />
                );
                return [x, y];
            });
            const group = (
                <Group key={`group++${item.id}`} id={`group++${item.id}`}>
                    {selected ? dashLines : null}

                    <Circle
                        x={scaled_x_offset}
                        y={plan.height - scaled_y_offset}
                        key={`${item.id}:${i}`}
                        draggable={selected}
                        radius={10 / scale.stageScale}
                        opacity={selected ? 0.6 : 0.2}
                        fill={'green'}
                        stroke={'green'}
                        strokeWidth={2 / scale.stageScale}
                        onMouseOver={onCircleMouseOver(item)}
                        onMouseOut={onCircleMouseOut(item)}
                        onDragMove={onDragMove(item)}
                        onMouseDown={onMouseDown(item)}
                        onMouseUp={onMouseUp}
                    />

                    {drawPolygon({
                        polygon,
                        color: 'green',
                        key: item.id,
                        strokeColor: 'green',
                        strokeWidth: 2 / scale.stageScale,
                        opacity: selected ? 0.6 : 0.2,
                        onMouseDown: onMouseDown(item),
                        onMouseUp: onMouseUp,
                        onMouseOver: _onShapeMouseOver(item),
                        onMouseMove: _onShapeMouseMove(item),
                        onMouseOut: _onShapeMouseOut(item),
                        onClick: _onShapeClick(item),
                    })}
                </Group>
            );

            result.push(group);
        });
        return result;
    };

    const selectedGeometry = useMemo(() => {
        if (!selectedList || !visiblePlanLayers.includes(CAMERAS_LAYER_TYPE)) return null;
        return (
            <Layer id={'selected'} key={'selected'}>
                {makeGeometry(selectedList)}
            </Layer>
        );
    }, [selectedList, canTransform, scale, selectedCamerasIds, visiblePlanLayers]);

    const deselectedGeometry = useMemo(() => {
        if (!visiblePlanLayers.includes(CAMERAS_LAYER_TYPE)) return null;
        return (
            <Layer id={'deselected'} key={'deselected'}>
                {makeGeometry(deselectedList)}
            </Layer>
        );
    }, [deselectedList, scale, selectedCamerasIds, visiblePlanLayers]);

    const deselectAll = (e) => {
        _onCameraMarkerClick(null, null);
        _toggleTool(null);
    };

    const grid = () => {
        if (!plan) return null;
        const grid = [];
        for (let i = 0; i <= plan.width; i = i + plan.scale) {
            grid.push(
                drawLine({
                    key: `grid_y${i}`,
                    points: [i, 0, i, plan.height],
                    strokeColor: '#555',
                    strokeWidth: 1 / scale.stageScale,
                    opacity: 0.2,
                })
            );
        }
        for (let i = plan.height; i > 0; i = i - plan.scale) {
            grid.push(
                drawLine({
                    key: `grid_x${i}`,
                    points: [0, i, plan.width, i],
                    strokeColor: '#555',
                    strokeWidth: 1 / scale.stageScale,
                    opacity: 0.2,
                })
            );
        }
        return grid;
    };

    const darker = () => {
        if (!plan) return null;
        return <Rect height={plan.height} width={plan.width} fill={'#000'} opacity={planDarkness / 100 || 0} />;
    };

    const lighter = () => {
        if (!plan) return null;
        return <Rect onClick={deselectAll} height={plan.height} width={plan.width} fill={'#fff'} opacity={planLightness / 100 || 0} />;
    };

    const handleObjectsChange = (args) => {
        _handleObjectsChange(args);
    };

    const customLayers = Object.keys(layersDraw).map((key) => {
        const isLayerSelected = selectedLayer === key;
        const visible = visiblePlanLayers.includes(key);
        return layersDraw[key]({
            objects: planLayers[key],
            isLayerSelected,
            visible,
            plan,
            scale,
            tool,
            setStageDraggable,
            setCursor,
            handleObjectsChange,
        });
    });

    const allLayers = () => {
        if (selectedPlanLayer === 'camerasLayer') {
            return [...customLayers, deselectedGeometry, selectedGeometry];
        } else {
            return [deselectedGeometry, selectedGeometry, ...customLayers];
        }
    };

    return (
        <MainWrapper ref={mainWrapperRef} cursor={cursor}>
            <StyledSplit
                split_cursor={'col-resize'}
                onDrag={onSplitDrag}
                sizes={[60, 40]}
                minSize={100}
                expandToMin={false}
                gutterSize={10}
                gutterAlign="center"
                snapOffset={30}
                dragInterval={1}
                direction={'horizontal'}
                // cursor={split_cursor}
            >
                <StageWrapper ref={stageWrapperRef}>
                    {imagePanelSizes ? (
                        <Stage
                            ref={stageRef}
                            width={imagePanelSizes.width}
                            height={imagePanelSizes.height}
                            draggable={stageDraggable}
                            onMouseUp={onMouseUp}
                            onDblClick={_onStageDblClick}
                            onClick={deselectAll}
                            onMouseMove={_onMouseMove}
                            onWheel={_onStageWheel}
                            scaleX={scale.stageScale}
                            scaleY={scale.stageScale}
                            x={scale.stageX}
                            y={scale.stageY}
                        >
                            <Layer id={'imageLayer'}>{image ? <Image opacity={planOpacity / 100 || 1} image={image} /> : null}</Layer>
                            <Layer id={'filtersLayer'}>
                                <Group>
                                    {darker()}
                                    {lighter()}
                                    {grid()}
                                </Group>
                            </Layer>

                            {allLayers()}
                        </Stage>
                    ) : null}
                </StageWrapper>
                <Dock splitSizes={splitSizes} />
            </StyledSplit>
        </MainWrapper>
    );
};

export default ImagePanel;

const StyledSplit = styled(Split)`
    height: 100%;
    display: flex;
    .gutter {
        :hover {
            cursor: ${(p) => p.split_cursor};
        }
    }
`;

const Cont = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`;
const PlayerWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;
    /* height: ${(p) => p.height + 'px'}; */
`;

const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const StageWrapper = styled.div`
    position: relative;
    background-color: rgb(231, 234, 237);
    width: 100%;
    overflow: hidden;
`;

const MainWrapper = styled.div`
    cursor: ${(p) => {
        return p.cursor;
    }};
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
`;

const TextContent = styled.svg`
    text-rendering: optimizeSpeed;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    width: 100%;
    height: 100%;
`;
