import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { IconButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
import { DetailsList, buildColumns, DetailsRow } from 'office-ui-fabric-react/lib/DetailsList';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { useTranslation } from 'react-i18next';

import { showModal } from '../../reducers/modalReducer';
import { fillCurrentCamera } from '../../reducers/calibrationReducer';
import { fillCurrentPerspective } from '../../reducers/perspectiveReducer';
import Stages from './components/stages';
import Header from '../wrappers/header_wrapper';
import MainWrapper from '../wrappers/main_wrapper';

export const CamerasContext = React.createContext({});

const Cameras = React.memo(({ ...props }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [theme] = useState(getTheme());
    const { camerasById } = useSelector((state) => state.camerasReducer);

    const dispatch = useDispatch();

    const camerasContextValue = {};

    const items = [];

    Object.keys(camerasById).forEach((key) => {
        const camera = camerasById[key];
        const { marker, id } = camera;
        const { source_image, corrected_image } = camera.image_profile;
        const stages = true;
        const copyToBuffer = true;
        const deleteCell = true;
        items.push({
            source_image,
            corrected_image,
            marker,
            id,
            copyToBuffer,
            stages,
            deleteCell,
        });
    });

    function _buildColumns(items) {
        if (items.length === 0) return [];
        const columns = buildColumns(items);
        const [idColumn] = columns.filter((column) => column.name === 'id');
        const [source_imageColumn] = columns.filter((column) => column.name === 'source_image');
        const [corrected_imageColumn] = columns.filter((column) => column.name === 'corrected_image');
        const [stagesColumn] = columns.filter((column) => column.name === 'stages');
        const [deleteCellColumn] = columns.filter((column) => column.name === 'deleteCell');
        const [copyToBufferColumn] = columns.filter((column) => column.name === 'copyToBuffer');

        source_imageColumn.name = 'Raw image';
        source_imageColumn.minWidth = 140;
        source_imageColumn.maxWidth = 140;

        corrected_imageColumn.name = 'Corrected image';
        corrected_imageColumn.minWidth = 140;
        corrected_imageColumn.maxWidth = 140;

        stagesColumn.name = 'Stages';

        copyToBufferColumn.name = 'Copy';
        copyToBufferColumn.minWidth = 40;
        copyToBufferColumn.maxWidth = 40;

        idColumn.minWidth = 40;
        idColumn.maxWidth = 40;

        deleteCellColumn.name = '';

        return columns;
    }

    const _renderRow = (props) => {
        const customStyles = {
            root: [
                {
                    display: 'flex',
                },
            ],
            cell: [
                {
                    display: 'flex',
                    width: 'auto',
                    alignItems: 'center',
                },
            ],
        };
        if (props) {
            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                customStyles.root = { ...customStyles.root, backgroundColor: theme.palette.themeLighterAlt };
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    const onThumnailClick = (id, key) => () => {
        const camera = camerasById[id];
        dispatch(
            showModal({
                modalType: 'show_raw_image',
                width: '80%',
                options: { camera, key },
            })
        );
    };

    const onStageClick = (stage, id) => {
        const camera = camerasById[id];
        if (stage === 1) {
            dispatch(
                showModal({
                    modalType: 'show_raw_image',
                    width: '80%',
                    options: { camera },
                })
            );
        } else if (stage === 2) {
            dispatch(fillCurrentCamera({ currentCamera: camera }));
            history.push(`/calibration/${id}`);
        } else if (stage === 3) {
            dispatch(fillCurrentPerspective({ currentPerspective: camera }));
            history.push(`/perspective`);
        }
    };

    const onAddNewCameraClick = () => {
        dispatch(showModal({ modalType: 'add_new_camera', width: '80%', options: { id: props.match.params.id } }));
    };

    const onDeleteCameraClick = (item) => () => {
        dispatch(
            showModal({
                modalType: 'delete_camera',
                width: '40%',
                options: { id: item.id, name: item.marker },
            })
        );
    };

    const onClipboardClick = (data) => () => {
        dispatch(
            showModal({
                modalType: 'copy_to_clipboard',
                width: '80%',
                // height: '80%',
                options: { data },
            })
        );
    };

    function _renderItemColumn(item, index, column) {
        const fieldContent = item[column.fieldName];
        const camera = camerasById[item.id];

        switch (column.key) {
            case 'source_image':
            case 'corrected_image':
                return (
                    <StyledImage
                        src={fieldContent}
                        width={80}
                        height={60}
                        imageFit={ImageFit.cover}
                        onClick={onThumnailClick(item.id, column.key)}
                    />
                );
            case 'stages':
                return <Stages camera={camera} onStageClick={onStageClick} />;

            case 'name':
                return <StyledLink to="/correction">{fieldContent}</StyledLink>;

            case 'deleteCell':
                return (
                    <DeleteIconWrapper>
                        <StIconButton
                            iconProps={{ iconName: 'Delete', size: '16px' }}
                            // disabled={disabled}
                            // bgcolor={bgColor}
                            onClick={onDeleteCameraClick(item)}
                            // size={controlDictionary[activeGroup].icon.size}
                        />
                    </DeleteIconWrapper>
                );
            case 'copyToBuffer':
                return <StIconButton iconProps={{ iconName: 'ClipboardList', size: '16px' }} onClick={onClipboardClick(camera)} />;

            case 'color':
                return (
                    <span
                        data-selection-disabled={true}
                        className={mergeStyles({
                            color: fieldContent,
                            height: '100%',
                            display: 'block',
                        })}
                    >
                        {fieldContent}
                    </span>
                );

            default:
                return <span>{fieldContent}</span>;
        }
    }

    return (
        <CamerasContext.Provider value={camerasContextValue}>
            <MainWrapper>
                <Header>
                    <PrimaryButton text={t('Add new camera')} onClick={onAddNewCameraClick} allowDisabledFocus disabled={false} />
                </Header>

                <Main>
                    <DetailsList
                        items={items}
                        setKey="set"
                        columns={_buildColumns(items)}
                        onRenderItemColumn={_renderItemColumn}
                        onRenderRow={_renderRow}
                        selectionMode={0}
                    />
                </Main>
            </MainWrapper>
        </CamerasContext.Provider>
    );
});

export default Cameras;

const StyledLink = styled(Link)`
    padding: 0 10px 0 10px;
    color: black;
`;

const DeleteIconWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const StyledImage = styled(Image)`
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.3);
`;

const Main = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const StIconButton = styled(IconButton)`
    background-color: ${(p) => p.bgcolor};

    i {
        font-size: ${(p) => p.size};
    }
`;
