import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

export default function ResetIcon({ ...props }) {
    const iconClass = mergeStyles({
        fontSize: 14,
        height: 18,
        width: 18,
        marginRight: '5px',
    });

    return <StyledFontIcon iconName="RevToggleKey" className={iconClass} />;
}

function blinkingEffect() {
    return keyframes`
      50% {
        opacity: 0;
      }
    `;
}

const StyledFontIcon = styled(FontIcon)`
    cursor: pointer;
    /* animation: ${blinkingEffect} 0.5s ease-in-out infinite;
    :hover {
        animation: ${blinkingEffect} 2s ease-in-out infinite;
    } */
`;
