import React from 'react';
import styled from 'styled-components/macro';

const FooterCP = React.memo(({ children }) => {
    return <MainWrapper>{children}</MainWrapper>;
});

export default FooterCP;

const MainWrapper = styled.div`
    width: 100%;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    flex-shrink: 0;
`;
