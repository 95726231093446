import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { IconButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { DetailsList, buildColumns, IColumn, DetailsRow } from 'office-ui-fabric-react/lib/DetailsList';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { useTranslation } from 'react-i18next';

import { showModal } from '../../reducers/modalReducer';
import {
    toggleDraw,
    onUpdateGeometry,
    onCreateNewElement,
    changePlanTextFields,
    setPlanToEdit,
    resetPlanToEdit,
} from '../../reducers/plansReducer';
import { onGroupNameClick } from '../../reducers/correctionReducer';
import { setActivePlanId } from '../../reducers/generalSetup';
import { getPlans } from '../../reducers/plansReducer';
import { GlobalContext } from '../../App';
import { getRequest, postRequest, doubleRequest } from '../../api';

import ToolsPanel from './components/tools_panel';
import ControlPanel from './components/control_panel';
import ImagePanel from './components/image_panel';
import Header from '../wrappers/header_wrapper';
import MainWrapper from '../wrappers/main_wrapper';
import GeometryEditor from '../wrappers/geometry_editor_wrapper';
import { urls } from '../../constants/urls';

export const PlansContext = React.createContext({});

const Plans = React.memo(() => {
    const { t } = useTranslation();
    const [theme] = useState(getTheme());
    const history = useHistory();
    const { plans, planToEdit, tool, controlDictionary, activeGroup } = useSelector((state) => state.plansReducer);
    const { activeLocationId } = useSelector((state) => state.locationsReducer);

    const dispatch = useDispatch();

    const { _getPlanLayers, token } = useContext(GlobalContext);

    useEffect(() => {
        activeLocationId && getRequest({ url: `${urls.PLANS_URL}?location_id=${activeLocationId}`, token, dispatch, actionName: getPlans });
    }, [activeLocationId]);

    useEffect(() => {
        dispatch(setActivePlanId({ plan: null }));
    }, []);

    const _toggleDraw = (id) => {
        dispatch(toggleDraw(id));
    };
    const _changePlanTextFields = (args) => {
        dispatch(changePlanTextFields(args));
    };

    const _onUpdateGeometry = (args) => {
        dispatch(onUpdateGeometry(args));
    };

    const _onCreateNewElement = (args) => {
        dispatch(onCreateNewElement(args));
    };

    const plansContextValue = {
        _changePlanTextFields,
        _onCreateNewElement,
        _toggleDraw,
        _onUpdateGeometry,
        plans,
        planToEdit,
        tool,
        controlDictionary,
        activeGroup,
    };

    const items = plans.map((plan) => {
        const { id, floor, scale } = plan;
        const image = plan.image_url || plan.image;
        const thumbnail = image;
        const deleteCell = true;
        const edit = true;

        return {
            thumbnail,
            id,
            floor,
            scale,
            // edit,
            // deleteCell,
        };
    });

    function _buildColumns(items) {
        if (items.length === 0) return [];
        const columns = buildColumns(items);
        const [thumbnailColumn] = columns.filter((column) => column.name === 'thumbnail');
        // const [deleteCellColumn] = columns.filter((column) => column.name === 'deleteCell');

        thumbnailColumn.name = 'thumbnail';
        thumbnailColumn.minWidth = 80;
        thumbnailColumn.maxWidth = 80;
        thumbnailColumn.ariaLabel = 'Thumbnail';

        // deleteCellColumn.name = '';
        return columns;
    }

    const _renderRow = (props) => {
        const customStyles = {
            root: [
                {
                    display: 'flex',
                },
            ],
            cell: [
                {
                    display: 'flex',
                    width: 'auto',
                    alignItems: 'center',
                },
            ],
        };
        if (props) {
            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                customStyles.root = { ...customStyles.root, backgroundColor: theme.palette.themeLighterAlt };
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    const onAddNewPlanClick = () => {
        dispatch(showModal({ modalType: 'add_new_plan', width: '80%', options: {} }));
    };

    const onDeleteClick = (item) => () => {
        dispatch(
            showModal({
                modalType: 'delete_plan',
                width: '40%',
                options: { id: item.id, name: item.name },
            })
        );
    };

    const _setActivePlanId = (plan) => () => {
        dispatch(setActivePlanId({ plan }));
        _getPlanLayers({ planId: plan.id, planFloor: plan.floor });
    };

    const onEditClick = (item) => () => {
        dispatch(setPlanToEdit({ id: item.id }));
    };

    const _resetPlanToEdit = () => {
        dispatch(resetPlanToEdit());
    };

    // const onThumbnailClick = (imgUrl, id) => () => {
    //     dispatch(
    //         showModal({
    //             modalType: 'show_plan_image',
    //             width: '80%',
    //             options: { imgUrl, planName },
    //         })
    //     );
    // };

    function _renderItemColumn(item, index, column) {
        const fieldContent = item[column.fieldName];

        switch (column.key) {
            case 'thumbnail':
                return (
                    <Link to={`/cameras/${item.id}`} onClick={_setActivePlanId(item)}>
                        <StyledImage
                            src={fieldContent}
                            width={80}
                            height={60}
                            imageFit={ImageFit.cover}
                            // onClick={onThumbnailClick(fieldContent, item.id)}
                        />
                    </Link>
                );

            case 'name':
                // return <Name>{fieldContent}</Name>;
                return (
                    <StyledLink to={`/cameras/${item.id}`} onClick={_setActivePlanId(item.id)}>
                        {fieldContent}
                    </StyledLink>
                );

            case 'edit':
                return (
                    <EditIconWrapper>
                        <StIconButton iconProps={{ iconName: 'FullWidthEdit', size: '16px' }} onClick={onEditClick(item)} />
                    </EditIconWrapper>
                );
            case 'deleteCell':
                return (
                    <DeleteIconWrapper>
                        <StIconButton
                            iconProps={{ iconName: 'Delete', size: '16px' }}
                            // disabled={disabled}
                            onClick={onDeleteClick(item)}
                        />
                    </DeleteIconWrapper>
                );

            case 'color':
                return (
                    <span
                        data-selection-disabled={true}
                        className={mergeStyles({
                            color: fieldContent,
                            height: '100%',
                            display: 'block',
                        })}
                    >
                        {fieldContent}
                    </span>
                );
            case 'scale':
                return <span>{fieldContent.toFixed(2)}</span>;

            default:
                return <span>{fieldContent}</span>;
        }
    }

    const planEditor = () => {
        return (
            <GeometryEditor>
                <ToolsPanel />
                <ImagePanel />
                <ControlPanel />
            </GeometryEditor>
        );
    };

    return (
        <PlansContext.Provider value={plansContextValue}>
            {plans && !planToEdit ? (
                <MainWrapper>
                    {/* <Header>
                        <PrimaryButton text={t('Add new plan')} onClick={onAddNewPlanClick} allowDisabledFocus disabled={false} />
                    </Header> */}

                    <Main>
                        <DetailsList
                            items={items}
                            setKey="set"
                            columns={_buildColumns(items)}
                            onRenderItemColumn={_renderItemColumn}
                            onRenderRow={_renderRow}
                            selectionMode={0}
                        />
                    </Main>
                </MainWrapper>
            ) : (
                <React.Fragment>{planEditor()}</React.Fragment>
            )}
        </PlansContext.Provider>
    );
});

export default Plans;

const StyledLink = styled(Link)`
    padding: 0 10px 0 10px;
    color: black;
`;

const EditIconWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
`;
const DeleteIconWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
`;

const StyledImage = styled(Image)`
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.3);
`;

const Main = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const EditorMain = styled.div`
    width: 100%;
    height: 100%;
    /* background-color: rgb(220, 222, 225); */
    display: grid;
    grid-template-columns: 40px auto 300px;
    grid-gap: 3px;
`;

const Footer = styled.div`
    width: 100%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StIconButton = styled(IconButton)`
    background-color: ${(p) => p.bgcolor};
    padding: 0;
    span {
        justify-content: flex-start;
    }

    :hover {
        /* background-color: #ccc !important; */
    }
    i {
        font-size: ${(p) => p.size};
    }
`;
