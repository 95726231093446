import React, { useState, useMemo, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { TooltipHost, ITooltipHostStyles, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';

import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { GlobalContext } from '../../../App';
import { PerspectiveContext } from '../perspective';

const ToolsPanel = React.memo(() => {
    const { _toggleTool, tool, layersById, calibration_geometry, activeGroup, activeLayer } = useContext(PerspectiveContext);

    const onClick = (id) => (e) => {
        if (id === tool.id) {
            _toggleTool(null);
        } else {
            _toggleTool(id);
        }
    };

    const controlDictionary = {
        scale: {
            name: 'Scale',
            icon: { iconName: 'ScaleVolume', size: '20px' },
            canDraw: false,
        },
        rotate: {
            name: 'Rotate',
            icon: { iconName: 'Rotate90Clockwise', size: '20px' },
            canDraw: false,
        },
    };

    const toolsButtons = useMemo(() => {
        const result = Object.keys(controlDictionary)
            .filter((key) => !controlDictionary[key].canDraw)
            .map((key) => {
                const bgColor = key === tool.id ? '#ccc !important' : 'transparent !important';
                const tooltip = tool.name;

                return (
                    <TooltipHost content={tooltip} key={key} directionalHint={DirectionalHint.rightCenter}>
                        <StIconButton
                            iconProps={controlDictionary[key].icon}
                            disabled={false}
                            bgcolor={bgColor}
                            onClick={onClick(key)}
                            size={controlDictionary[key].icon.size}
                        />
                    </TooltipHost>
                );
            });

        let disabled = false;

        const bgColor = activeGroup === tool.id && !disabled ? '#ccc !important' : 'transparent !important';
        const tooltip = disabled ? 'Please select layer' : tool.name;

        return result;
    }, [tool, activeGroup, activeLayer, calibration_geometry]);

    return <MainWrapper>{toolsButtons}</MainWrapper>;
});

export default ToolsPanel;

const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 5px;
    display: grid;
    grid-gap: 5px;
    grid-template-rows: repeat(10, 40px);
    background-color: rgb(240, 242, 245);
`;

const StIconButton = styled(IconButton)`
    background-color: ${(p) => p.bgcolor};
    :hover {
        background-color: #ccc !important;
    }
    i {
        font-size: ${(p) => p.size};
    }
`;
