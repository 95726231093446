import React, { useMemo, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { getTheme, mergeStyleSets, FontWeights } from 'office-ui-fabric-react';
import { useId, useBoolean } from '@uifabric/react-hooks';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useConstCallback } from '@uifabric/react-hooks';
import { GlobalContext } from '../../App';

//_____________________________
import { fillBackend } from '../../reducers/generalSetup';
import { hideModal } from '../../reducers/modalReducer';
import { onDeleteLayerClick, onRenameLayerClick, onRenameAnchorPointClick } from '../../reducers/calibrationReducer';
import { onAddNewPlanModalClick } from '../../reducers/plansReducer';
import { chooseCorrectionStep, manualEdit } from '../../reducers/correctionReducer';
//_____________________________

import Title from './components/title';
import DeleteLayerModal from './components/delete_layer_modal';
import RenameLayerModal from './components/rename_layer_modal';
import RenameAnchorPointModal from './components/rename_anchor_point_modal';
import ShowPlanImageModal from './components/show_plan_image_modal';
import ShowRawImageModal from './components/show_raw_image_modal';
import ShowCorrectedRawImageModal from './components/show_corrected_raw_image_modal';
import AddNewPlanModal from './components/add_new_plan_modal';
import AddNewLocationModal from './components/add_new_location_modal';
import DeletePlanModal from './components/delete_plan_modal';
import DeleteCameraModal from './components/delete_camera_modal';
import AddNewCameraModal from './components/add_new_camera_modal';
import CopyToClipboardModal from './components/copy_to_clipboard_modal';
import DevOptionsModal from './components/dev_options_modal';
import BigCameraImageModal from './components/big_camera_image_modal';

const ModalPanel = React.memo(({ external, ...props }) => {
    const {
        _deletePlan,
        _deleteCamera,
        _onAddNewRawImageClick,
        _onShowRawImageClick,
        _onAddNewLocationModalClick,
        _savePerspectiveProfile,
    } = useContext(GlobalContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const modalData = useSelector((state) => state.modalReducer);
    const theme = getTheme();

    const [clickStart, set_clickStart] = useState(false);

    const cancelIcon = { iconName: 'Cancel' };
    const titleId = useId('title');

    const _hideModal = () => {
        dispatch(hideModal());
    };

    const _onDeleteLayerClick = (options) => {
        dispatch(onDeleteLayerClick({ ...options }));
        dispatch(hideModal());
    };
    const _onRenameLayerClick = (args) => {
        dispatch(onRenameLayerClick({ ...args }));
        dispatch(hideModal());
    };
    const _onCopyToClipboardClick = (args) => {
        const { text } = args;
        try {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text);
            } else if (window.clipboardData) {
                window.clipboardData.setData('text', text);
            }
        } catch (e) {}
        dispatch(hideModal());
    };
    const _onRenameAnchorPointClick = (args) => {
        dispatch(onRenameAnchorPointClick({ ...args }));
        dispatch(hideModal());
    };
    const _onDeletePlanModalClick = (args) => {
        _deletePlan({ ...args });
        dispatch(hideModal());
    };
    const _onDeleteCameraModalClick = (args) => {
        _deleteCamera({ ...args });
        dispatch(hideModal());
    };
    const _onAddNewPlanModalClick = (args) => {
        dispatch(onAddNewPlanModalClick({ ...args }));
        dispatch(hideModal());
    };
    const onAddNewLocationModalClick = (args) => {
        _onAddNewLocationModalClick({ ...args });
        dispatch(hideModal());
    };
    const onAddNewRawImageClick = (args) => {
        _onAddNewRawImageClick(args);
        dispatch(hideModal());
    };
    const _onDevOptionsModalClick = (args) => {
        dispatch(fillBackend(args));
        dispatch(hideModal());
    };

    const _onApplyChangesClick = (args) => {
        if (args?.additionalOptions?.canEdit === 'correction') {
            dispatch(manualEdit(args));
        } else if (args?.additionalOptions?.canEdit === 'calibration_geometry') {
            console.log(args);
            _savePerspectiveProfile({
                id: args.additionalOptions.id,
                calibration_geometry: JSON.parse(args.text),
                entity: 'PerspectiveProfile',
            });
        }
        dispatch(hideModal());
    };

    const onShowRawImageClick = (args) => {
        _onShowRawImageClick(args);
        dispatch(hideModal());
    };
    let history = useHistory();
    const onShowCorrectedRawImageClick = (args) => {
        history.push('/correction');
        dispatch(chooseCorrectionStep({ ...args }));
        dispatch(hideModal());
    };

    const dimensions = {
        width: modalData.width ? modalData.width : '60%',
        height: modalData.height ? modalData.height : 'auto',
    };

    const iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const content = useMemo(() => {
        switch (modalData.modalType) {
            case 'delete_layer':
                return { title: null, main: <DeleteLayerModal modalData={modalData} _onOKClick={_onDeleteLayerClick} /> };
            case 'rename_layer':
                return {
                    title: t('Rename layer'),
                    main: <RenameLayerModal modalData={modalData} _onOKClick={_onRenameLayerClick} />,
                };
            case 'copy_to_clipboard':
                return {
                    title: t('Copy to clipboard'),
                    main: (
                        <CopyToClipboardModal
                            modalData={modalData}
                            _onOKClick={_onCopyToClipboardClick}
                            _onApplyChangesClick={_onApplyChangesClick}
                        />
                    ),
                };
            case 'rename_anchor_point':
                return {
                    title: t('Rename anchor point'),
                    main: <RenameAnchorPointModal modalData={modalData} _onOKClick={_onRenameAnchorPointClick} />,
                };
            case 'show_plan_image':
                return {
                    title: `${t('Plan image for')} ${modalData.options.planName}`,
                    main: <ShowPlanImageModal modalData={modalData} />,
                };
            case 'add_new_camera':
                return {
                    title: `${t('Add new raw camera image')}`,
                    main: <AddNewCameraModal modalData={modalData} _onOKClick={onAddNewRawImageClick} />,
                };
            case 'big_camera_image':
                return {
                    title: ``,
                    main: <BigCameraImageModal modalData={modalData} />,
                };
            case 'show_raw_image':
                return {
                    title: `${t('Calibration stage 1 for')} ${modalData.options.camera.marker}`,
                    main: <ShowRawImageModal modalData={modalData} _onOKClick={onShowRawImageClick} />,
                };
            case 'show_corrected_raw_image':
                return {
                    title: `${t('Calibration stage 2 for')} ${modalData.options.correctedRawImage.camera.marker}`,
                    main: <ShowCorrectedRawImageModal modalData={modalData} _onOKClick={onShowCorrectedRawImageClick} />,
                };
            case 'add_new_plan':
                return {
                    title: t('Add new plan'),
                    main: <AddNewPlanModal modalData={modalData} _onOKClick={_onAddNewPlanModalClick} />,
                };
            case 'add_new_location':
                return {
                    title: t('Add new location'),
                    main: <AddNewLocationModal modalData={modalData} _onOKClick={onAddNewLocationModalClick} />,
                };
            case 'delete_plan':
                return {
                    title: `${t('Delete plan')} ${modalData.options.name}`,
                    main: <DeletePlanModal modalData={modalData} _onOKClick={_onDeletePlanModalClick} />,
                };
            case 'delete_camera':
                return {
                    title: `${t('Delete camera')} ${modalData.options.name}`,
                    main: <DeleteCameraModal modalData={modalData} _onOKClick={_onDeleteCameraModalClick} />,
                };
            case 'dev_options':
                return {
                    title: `${t('Dev options')}`,
                    main: <DevOptionsModal modalData={modalData} _onOKClick={_onDevOptionsModalClick} />,
                };
            default:
                return { title: null, main: null };
        }
    }, [modalData]);

    const onOverlayMouseDown = (e) => {
        set_clickStart(true);
    };

    const onOverlayMouseUp = (show) => (e) => {
        if (!clickStart) return;
        dispatch(hideModal());
        set_clickStart(false);
    };

    const onContainerClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Overlay onMouseDown={onOverlayMouseDown} onMouseUp={onOverlayMouseUp(false)}>
            <Container
                width={dimensions.width}
                height={dimensions.height}
                maxHeight={'80%'}
                onClick={onContainerClick}
                onMouseDown={onContainerClick}
                borderColor={`${theme.palette.themePrimary}`}
            >
                {content.title && !external ? (
                    <Header>
                        <Title title={content.title} />
                    </Header>
                ) : null}
                <StIconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close modal" onClick={_hideModal} />
                <Main>{external ? props.children : content.main}</Main>
            </Container>
        </Overlay>
    );
});

export default ModalPanel;

const Header = styled.section`
    margin-bottom: 20px;
`;

const StIconButton = styled(IconButton)`
    position: absolute;
    right: 0;
    top: 0;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 300;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Container = styled.div`
    box-sizing: border-box;
    padding: 15px;
    position: relative;
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    max-height: ${(p) => p.maxHeight};
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border-top: ${(p) => '4px solid' + p.borderColor};
    overflow: auto;
`;

const Main = styled.section`
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;
