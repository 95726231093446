import React from 'react';
import MeasuredCoordObjectsLayer from './measured_coord_objects_layer/layer';
import MeasuredCoordObjectsLayer3D from './measured_coord_objects_layer/layer_3d';
import MeasuredCoordObjectsModal from './measured_coord_objects_layer/modal';
import MeasuredCoordObjectsList from './measured_coord_objects_layer/objects_list';
import MeasuredCoordObjectOptions from './measured_coord_objects_layer/object_options';

import PassWaysLayer from './passways_layer/layer';
import PassWaysObjectsList from './passways_layer/objects_list';
import PassWaysObjectOptions from './passways_layer/object_options';
import PassWaysLayer3D from './passways_layer/layer_3d';

const layers = {
    modals: {
        measuredCoordObjectsLayer: MeasuredCoordObjectsModal,
    },
    draw: {
        measuredCoordObjectsLayer: MeasuredCoordObjectsLayer,
        pass_ways_layer: PassWaysLayer,
    },
    draw3d: {
        measuredCoordObjectsLayer: MeasuredCoordObjectsLayer3D,
        pass_ways_layer: PassWaysLayer3D,
    },
    objects: {
        measuredCoordObjectsLayer: MeasuredCoordObjectsList,
        pass_ways_layer: PassWaysObjectsList,
    },
    selectedObjectOptions: {
        measuredCoordObjectsLayer: MeasuredCoordObjectOptions,
        pass_ways_layer: PassWaysObjectOptions,
    },
};

export default layers;
