import * as THREE from 'three';
import { getArrowEndPoint } from './getArrowEndPoint';

export const getArrowTriangle = (point, i, arr, scale, passMargin, stageScale) => {
    const center = [(point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2];
    const normalV = new THREE.Vector3(0, 0, 1);
    const triangleWidth = 0.2;

    const endPoint = new THREE.Vector3(point[0], point[1], 0)
        .sub(new THREE.Vector3(...center, 0))
        .normalize()
        .multiplyScalar(0.4 / Math.pow(stageScale, 0.5))
        .applyAxisAngle(normalV, Math.PI / 2)
        .add(new THREE.Vector3(...center, 0));

    const leftShoulder = new THREE.Vector3(point[0], point[1], 0)
        .sub(new THREE.Vector3(...center, 0))
        .normalize()
        .multiplyScalar(triangleWidth / 2)
        .add(new THREE.Vector3(...center, 0));

    const rightShoulder = new THREE.Vector3(point[0], point[1], 0)
        .sub(new THREE.Vector3(...center, 0))
        .normalize()
        .multiplyScalar(triangleWidth / 2)
        .applyAxisAngle(normalV, Math.PI)
        .add(new THREE.Vector3(...center, 0));

    return [
        [endPoint.x, endPoint.y],
        [leftShoulder.x, leftShoulder.y],
        [rightShoulder.x, rightShoulder.y],
    ];
};
