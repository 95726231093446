import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { useTranslation } from 'react-i18next';
import {
    addAnchor,
    toggleDraw,
    onGroupNameClick,
    onUpdateGeometry,
    onCreateNewElement,
    onSectionEyeClick,
    onDeleteElement,
    onDeleteAnchor,
    cancelChanges,
    inactiveGroupsVisibleOff,
} from '../../reducers/correctionReducer';
import { showModal } from '../../reducers/modalReducer';
import ToolsPanel from './components/tools_panel';
import ControlPanel from './components/control_panel';
import ImagePanel from './components/image_panel';
import GeometryEditor from '../wrappers/geometry_editor_wrapper';

export const CorrectionContext = React.createContext({});

const DistorsionCorrection = React.memo(() => {
    const history = useHistory();
    const { t } = useTranslation();
    const [rawImage, set_rawImageFile] = useState({});
    const [theme] = useState(getTheme());
    const { lang, activePlanId } = useSelector((state) => state.generalSetup);
    const { dist_lines, vert_lines, controlDictionary, tool, activeGroup, correctedRawImage, finishingStage1 } = useSelector(
        (state) => state.correctionReducer
    );

    useEffect(() => {
        dispatch(inactiveGroupsVisibleOff({ activeGroup }));
    }, [activeGroup]);

    const dispatch = useDispatch();

    const _toggleDraw = (id) => {
        dispatch(toggleDraw(id));
    };

    const _onGroupNameClick = (groupId) => {
        dispatch(onGroupNameClick({ groupId }));
    };

    const _onUpdateGeometry = (args) => {
        dispatch(onUpdateGeometry(args));
    };

    const _onCreateNewElement = (args) => {
        dispatch(onCreateNewElement(args));
    };

    const _onSectionEyeClick = (groupId) => {
        dispatch(onSectionEyeClick({ groupId }));
    };

    const _onDeleteElement = (args) => {
        dispatch(onDeleteElement(args));
    };

    const _addAnchor = (args) => {
        dispatch(addAnchor(args));
    };

    const _onClipboardClick = (data) => {
        dispatch(
            showModal({
                modalType: 'copy_to_clipboard',
                width: '80%',
                options: { data, additionalOptions: { canEdit: 'correction', activeGroup } },
            })
        );
    };

    const _onDeleteAnchor = (args) => {
        dispatch(onDeleteAnchor(args));
    };
    const _cancelChanges = () => {
        if (activePlanId) {
            history.push(`/cameras/${activePlanId}`);
        } else {
            history.push(`/`);
        }
        dispatch(cancelChanges());
    };

    const correctionContextValue = {
        _cancelChanges,
        _onClipboardClick,
        _onDeleteAnchor,
        _addAnchor,
        _onDeleteElement,
        _onSectionEyeClick,
        _onCreateNewElement,
        _onUpdateGeometry,
        _toggleDraw,
        _onGroupNameClick,
        dist_lines,
        correctedRawImage,
        vert_lines,
        controlDictionary,
        rawImage,
        tool,
        theme,
        activeGroup,
        finishingStage1,
    };

    return (
        <CorrectionContext.Provider value={correctionContextValue}>
            {activeGroup ? (
                <GeometryEditor>
                    <ToolsPanel />
                    <ImagePanel />
                    <ControlPanel />
                </GeometryEditor>
            ) : null}
        </CorrectionContext.Provider>
    );
});

export default DistorsionCorrection;
