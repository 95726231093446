import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import styled from 'styled-components/macro';

const ObjectOptions = () => {
    const { objectsById = {}, selectedObject, visibleObjects } = useSelector((state) => state.PassWaysLayerReducer);

    const object = objectsById[selectedObject];

    if (!object) return null;

    return selectedObject ? (
        <MainWrapper>
            <StyledTextField label="Marker:" underlined value={object.marker} disabled />
            <StyledTextField label="Name:" underlined value={object.name} disabled />
            <StyledTextField label="Pass margin width:" underlined value={object.passMargin} disabled />
        </MainWrapper>
    ) : null;
};

export default ObjectOptions;

const StyledTextField = styled(TextField)`
    width: 100%;
    margin: 0 10px 0 0;
    * {
        background-color: transparent !important;
    }
`;

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    overflow-y: auto;
`;
